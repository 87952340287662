import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import api from '../../api';
import Bottom from './Bottom';

@inject('commonStore', 'menuStore', 'orderStore')
@observer
class PaymentKovan extends Component {
  formRef = React.createRef();

  componentDidMount() {
    const { orderStore } = this.props;
    // 결제 방법 기본값을 카드로 설정
    if (!orderStore.paymentMethod) {
      orderStore.setPaymentMethod('1');
    }
  }

  // 결제방법 변경
  handlePayMethodChange = event => {
    this.props.orderStore.setPaymentMethod(event.target.value);
  };

  // 상점 아이디 조회
  getMerchantID = () => {
    const { menuStore, orderStore } = this.props;
    if (!menuStore.storeData) return '';
    if (orderStore.paymentMethod === '8') {
      return menuStore.storeData.merchantID2;
    } else {
      return menuStore.storeData.merchantID;
    }
  };

  // 결제 수단 코드 조회
  getPaymentMethodCode = () => {
    switch (this.props.orderStore.paymentMethod) {
      case '1':
      case '8':
        return 'GEP';
      default:
        return 'KKP';
    }
  };

  // 간편결제 코드 조회
  getEasyPaymentCode = () => {
    switch (this.props.orderStore.paymentMethod) {
      case '1':
      case '2':
      case '8':
        return 'CC';
      case '4':
        return 'VO';
      default:
        return '';
    }
  };

  pay = async () => {
    const { commonStore, orderStore } = this.props;

    try {
      // 결제 예약 API 실행
      const response = await api.reserveOrder(orderStore.orderData);

      // 결제 요청 데이터 세팅
      orderStore.setPaymentData(response.data);
    } catch (error) {
      commonStore.setError(error);
      throw error;
    } finally {
      // 결제 버튼 활성화
      orderStore.setDisablePaymentButton(false);
    }

    // 결제 페이지 호출
    this.formRef.submit();
  };

  render() {
    const { t, commonStore, menuStore, orderStore } = this.props;
    return (
      <React.Fragment>
        {/* 결제방법 선택 */}
        <Grid item container direction={'column'} spacing={1} style={{ marginTop: '25px' }}>
          <Grid item>
            <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>{t('MESSAGE_PAYMENT_METHOD')}</span>
          </Grid>
          <Grid item>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={orderStore.paymentMethod}
              onChange={this.handlePayMethodChange}
            >
              {(menuStore.storeData.paymentMethod & 1) === 1 && (
                <FormControlLabel value={1} control={<Radio color="primary" />} label={'카드'} />
              )}
              {(menuStore.storeData.paymentMethod & 8) === 8 && (
                <FormControlLabel value={8} control={<Radio color="primary" />} label={'수기카드'} />
              )}
              {(menuStore.storeData.paymentMethod & 2) === 2 && (
                <FormControlLabel value={2} control={<Radio color="primary" />} label={'카카오페이 카드'} />
              )}
              {(menuStore.storeData.paymentMethod & 4) === 4 && (
                <FormControlLabel value={4} control={<Radio color="primary" />} label={'카카오페이 머니'} />
              )}
            </RadioGroup>
          </Grid>
        </Grid>

        <form
          ref={ref => (this.formRef = ref)}
          name="payForm"
          method="post"
          action={
            process.env.REACT_APP_KOVANPAY_SERVER +
            (isMobile ? '/mobilepage/common/mainFrame.pay' : '/paypage/common/mainFrame.pay')
          }
        >
          {/************************* 공통 필수 *************************/}
          {/* 상점 ID */}
          <input type="hidden" name="mid" value={this.getMerchantID()} />
          {/* Return URL */}
          <input
            type="hidden"
            name="rUrl"
            value={`${process.env.REACT_APP_API_SERVER}/sales/kovan/${commonStore.companyId}/${commonStore.storeCode}`}
          />
          {/* Return Http메소드 */}
          <input type="hidden" name="rMethod" value="POST" />
          {/* 결제 그룹 */}
          <input type="hidden" name="payGroup" value={this.getPaymentMethodCode()} />
          {/* 결제 수단 */}
          <input type="hidden" name="payType" value={this.getEasyPaymentCode()} />
          {/* 상품명 */}
          <input type="hidden" name="buyItemnm" value={orderStore.paymentMenuName} />
          {/* 상품가격 */}
          <input type="hidden" name="buyReqamt" value={orderStore.requestAmount} />
          {/* #상품 코드 */}
          <input type="hidden" name="buyItemcd" value={orderStore.paymentMenuCode} />
          {/* 주문번호 */}
          <input type="hidden" name="orderno" value={orderStore.paymentData.orderId} />
          {/* 주문 일자 (YYYYMMDD) */}
          <input type="hidden" name="orderdt" value={orderStore.paymentData.ediDate} />
          {/* 주문 시간 (HHMMSS) */}
          <input type="hidden" name="ordertm" value={orderStore.paymentData.ediTime} />
          {/* #무결섬 검증 Hash 값 */}
          <input type="hidden" name="checkHash" value={orderStore.paymentData.signData} />

          {/************************* 옵션 *************************/}
          {/* 구매자 ID (카카오페이의 경우 필수) */}
          <input type="hidden" name="buyerid" value={orderStore.phoneNumber} />
          {/* 구매자명 */}
          <input type="hidden" name="buyernm" value="" />
          {/* 구매자e-mail */}
          <input type="hidden" name="buyerEmail" value="" />
          {/* 가맹점 예약 필드1 */}
          <input
            type="hidden"
            name="reserved01"
            value={`${menuStore.storeData.companyId}|${menuStore.storeData.storeCode}`}
          />
          {/* 가맹점 예약 필드2 */}
          <input type="hidden" name="reserved02" value="" />
          {/* 모바일용 APP Scheme Uri */}
          <input type="hidden" name="returnAppUrl" value="" />
        </form>

        <Bottom pay={this.pay} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(PaymentKovan);
