import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button, Divider, Typography, Icon, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Header from '../common/Header';
import CartMenuList from './CartMenuList';
import Bottom from './Bottom';
import Progress from '../common/Progress';
// import ReceiveMethodButtonGroup from './ReceiveMethodButtonGroup';
import CartClearDialog from './CartClearDialog';

@inject('commonStore', 'menuStore', 'cartStore')
@observer
class Cart extends Component {
  componentDidMount() {
    const { commonStore, match, cartStore } = this.props;
    commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);
    // 카트 메뉴리스트 주문가능 수량 서버에서 가져오기
    cartStore.updateMenuStockCnt();
  }

  handleClickAddMore = () => {
    const { commonStore, history } = this.props;
    history.push(`/${commonStore.companyId.toLowerCase()}/${commonStore.storeCode}`);
  };

  render() {
    const { menuStore, cartStore, t } = this.props;
    if (!menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header iconType="CART_CLEAR" title={t('TITLE_CART')} showWaitingTime={true} />

        <div className="contents-full">
          {/* 매장이름 */}
          <div style={{ height: '60px', padding: '10px 16px', backgroundColor: '#E7E7E7', marginTop: 64 }}>
            <Icon edge="start" style={{ color: '#F50057', marginRight: '5px' }}>
              <LocationOnIcon />
            </Icon>
            <Typography component="span" color="textPrimary" variant="h6" style={{ fontWeight: 'bold' }}>
              {menuStore.storeData.storeName}
            </Typography>
          </div>
          <Divider variant={'fullWidth'} />

          {/* 수령 방법 선택 버튼 */}
          {/* <ReceiveMethodButtonGroup /> */}

          {/* 장바구니 리스트 */}
          {cartStore.cartData.menus.length > 0 ? (
            <CartMenuList />
          ) : (
            <Typography align="center" style={{ paddingTop: '20px' }}>
              {t('ALERT_EMPTY_CART')}
            </Typography>
          )}

          {/* 더 담으러 가기 버튼 */}
          <Grid container alignContent={'center'} style={{ paddingTop: '8px', paddingBottom: '16px' }}>
            <Button fullWidth onClick={this.handleClickAddMore} color={'secondary'}>
              <span style={{ fontSize: '13pt' }}>+ {t('BUTTON_ADD_MORE')}</span>
            </Button>
          </Grid>
        </div>

        {/* 주문하기 버튼 */}
        <Bottom text={t('BUTTON_ORDER_4')} />

        {/* 장바구니 비우기 다이얼로그 */}
        <CartClearDialog />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Cart);
