import * as React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import storeMap from '../../static/yokohama/images/store_map.jpg';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

@inject('commonStore')
@observer
class StoreMapYokohama extends Component {
  // 지도 닫기
  handleCloseMap = () => {
    this.props.commonStore.setMapOpen(false);
  };

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.props.commonStore.isMapOpen} fullWidth maxWidth="xl" onClose={this.handleModalClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar variant="dense">
              <IconButton edge="end" color="inherit" onClick={this.handleCloseMap} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <img src={storeMap} style={{ width: '100%' }} alt="" />
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default StoreMapYokohama;
