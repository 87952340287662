import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';
import Header from '../common/Header';
import PaymentNice from './PaymentNice';
import PaymentKovan from './PaymentKovan';
import PaymentSoftBank from './PaymentSoftBank';
import PaymentTypeEnum from '../../enums/PaymentTypeEnum';
import MenuList from './MenuList';
import Point from './Point';
import PaymentHecto from './PaymentHecto';
import LiteMenuList from './LiteMenuList';
// import api from '../../api';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class Payment extends Component {
  async componentDidMount() {
    const {
      match,
      history,
      location,
      t,
      commonStore,
      userStore,
      orderStore,
      cartStore,
      // menuStore
    } = this.props;

    // 매장 데이터 세팅
    await commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);

    // 유효성 검사
    if (location.state?.isLiteOrder) {
      if (!orderStore.liteOrder) {
        history.goBack();
        return;
      }
    } else if (cartStore.cartData.menus.length === 0) {
      alert(t('ALERT_EMPTY_CART'));
      history.push(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}`);
      return;
    }

    // 유저데이터 세팅
    userStore.setUserDataFromLocalStorage();

    if (!userStore.userData.phoneNumber && !location.state?.isLiff) {
      // 라인미니앱이 아닌데 전화번호가 입력되어 있지 않다면 페이지 이동 처리
      if (location.state?.isLiteOrder) {
        // 마장동 주문인 경우 뒤로가기
        history.goBack();
      } else {
        // 일반 주문인 경우 주문정보 입력 페이지로 리다이렉트
        history.replace(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}/order`);
      }
    }
    // 결제 테스트용 임시 소스
    // 회원 포인트 조회
    // BAS_D - BASH_CODE : CF, BASD_CODE : 215, FILED4 : 1
    // if (menuStore.storeData.isPointUseable) {
    //   const response = await api.getUserPointByPhoneNumber(
    //     match.params.companyId.toLowerCase(),
    //     match.params.storeCode,
    //     '01047154304',
    //   );
    //   console.log(response);
    //   if (response.data.message === 'NOT_FOUND') {
    //     userStore.userData.isMember = false;
    //     userStore.saveUserData();

    //     history.push(`/${commonStore.companyId}/${commonStore.storeCode}/join`);
    //     return;
    //   } else if (response.data.message === 'DUPLICATE') {
    //     userStore.userData.isMember = false;
    //     userStore.saveUserData();

    //     commonStore.setSnackbar(true, t('ERROR_USER_DUPLICATE'));
    //     return;
    //   } else if (response.data.message === 'SUCCESS') {
    //     userStore.userData.isMember = true;
    //     userStore.userData.isPointAdded = true;
    //     userStore.saveUserData();

    //     userStore.setUserPoint(response.data.user.Point);
    //     history.push({
    //       pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
    //       state: { isLiteOrder: orderStore.liteOrder ? true : false },
    //     });
    //     return;
    //   }
    // }
  }

  renderMenuList() {
    if (this.props.orderStore.liteOrder) {
      return <LiteMenuList />;
    } else {
      return <MenuList />;
    }
  }

  // 선택한 PG사별 결제 설정 ,,
  renderPaymentSection() {
    const { t, menuStore } = this.props;
    switch (Number(menuStore.storeData.paymentType)) {
      case PaymentTypeEnum.NICEPAY:
        return <PaymentNice />;
      case PaymentTypeEnum.KOVANPAY:
        return <PaymentKovan />;
      case PaymentTypeEnum.SOFTBANKPAY:
        return <PaymentSoftBank />;
      case PaymentTypeEnum.HECTO:
        return <PaymentHecto />;
      default:
        return t('ALERT_NOT_SET_PAYMENT');
    }
  }

  render() {
    const { t, menuStore, cartStore, userStore } = this.props;
    if (!menuStore.storeData || !cartStore.cartData) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header iconType="CART" title={t('TITLE_PAYMENT')} showWaitingTime={true} />

        <div className="contents">
          {menuStore.storeData.isPointUseable && userStore.userData.isMember && userStore.userData.isPointAdded && (
            <Point />
          )}

          {/* 결제 메뉴 리스트 */}
          {this.renderMenuList()}

          {/* 결제 */}
          {this.renderPaymentSection()}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(Payment));
