import StoreMenuModel from './StoreMenuModel';

export default class StoreMenuGroupModel {
  storeCode;
  groupCode;
  name;
  isDefault;
  isUseTime;
  startUseTime;
  endUseTime;
  menus;

  constructor(data) {
    Object.assign(this, data);
    this.menus = data.menus.map(menu => new StoreMenuModel(menu));
  }
}
