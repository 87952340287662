import { action, observable, computed, toJS } from 'mobx';
import i18n from 'i18next';
import liff from '@line/liff';
import ReceiveMethodEnum from '../enums/ReceiveMethodEnum';
import api from '../api';
import PaymentMethodEnum from '../enums/PaymentMethodEnum';

class OrderStore {
  constructor(root) {
    this.root = root;
  }

  // 개인정보 팝업 창 오픈 여부
  @observable isPrivateDialogOpen = false;
  // 중복 결제 방지를 위한 결제 버튼 비활성화 여부
  @observable disablePaymentButton = false;
  // 주문 요청사항
  @observable orderRequests = '';
  // 배달 요청사항 선택
  @observable deliveryRequestsSelect = 'NONE';
  // 배달 요청사항 직접입력
  @observable deliveryRequests = '';
  // 개인정보 수집 및 이용 약관 동의 여부
  @observable agree = false;
  // 나이스페이/코밴페이 결제 요청 데이터
  @observable paymentData = {
    orderId: '',
    ediDate: '',
    signData: '',
  };
  // 소프트뱅크페이 결제 요청 데이터
  @observable paymentSBData;
  // 주문 데이터
  @observable order;
  // 결제 방법
  @observable paymentMethod = '';
  // 주문 상태체크 인터벌
  @observable statusCheckInterval;
  // 입력 포인트
  @observable inputPoint = '';

  @observable liteOrder;

  // 고객 이메일 주소
  @observable email = '';

  // 고객 카드 번호
  @observable userCardNumber = '';

  // 좌석정보 지역
  @observable zone = '';

  // 좌석정보 열
  @observable block = '';

  // 좌석정보 좌석
  @observable seat = '';

  @action
  setInputPoint(value) {
    // 입력한 포인트를 초기값으로 설정
    let inputPoint = Number(value.toString().replace(/[^0-9]/g, ''));

    // 최대 포인트 사용 액수를 주문 금액으로 제한
    if (inputPoint > this.orderAmount) inputPoint = this.orderAmount;

    // 최대 포인트 사용 액수를 보유 포인트로 제한
    const userPoint = this.root.userStore.userPoint;
    if (inputPoint > userPoint) inputPoint = userPoint;

    this.inputPoint = inputPoint.toLocaleString();
  }

  // 사용 포인트
  @computed get usePoint() {
    return Number(this.inputPoint.toString().replace(/[^0-9]/g, ''));
  }

  // 결제 금액
  @computed get orderAmount() {
    let orderAmount = 0;

    if (this.liteOrder) {
      // ORDER 테이블 총 금액
      orderAmount = this.liteOrder.tamt;
    } else {
      // 장바구니 총 금액
      orderAmount = this.root.cartStore.cartData.menus
        .map(menu => {
          return this.root.menuStore.calcTaxMenuPrice(menu, menu.qty);
        })
        .reduce((accumulator, price) => accumulator + price, 0);
    }

    // 배송비 추가
    if (this.root.cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY) {
      orderAmount += Number(this.root.menuStore.storeData.deliveryFee) || 0;
    }

    return orderAmount;
  }

  // 실제 결제 요청금액 (포인트 차감)
  @computed get requestAmount() {
    return this.orderAmount - this.usePoint;
  }

  // 결제용 대표 상품명
  @computed get paymentMenuName() {
    let menuName = '';
    const menus = this.liteOrder ? this.liteOrder.menus : this.root.cartStore.cartData.menus;
    const menuCount = menus.length;

    if (menuCount === 1) {
      menuName = menus[0].name;
    } else if (menuCount > 1) {
      menuName = `${menus[0].name}他 ${menuCount - 1}件`;
    }

    return menuName;
  }

  // 결제용 대표 상품코드 (코밴페이)
  @computed get paymentMenuCode() {
    let menuCode = '';
    const menus = this.liteOrder ? this.liteOrder.menus : this.root.cartStore.cartData.menus;
    if (menus.length > 0) {
      menuCode = menus[0].itemCode;
    }
    return menuCode;
  }

  // 결제 코드로 이름 조회
  @computed get paymentMethodName() {
    if (this.order.payments.length > 1) {
      return i18n.t('MESSAGE_MULTI_PAYMENT');
    } else {
      return PaymentMethodEnum.properties[this.order.payments[0].methodCode];
    }
  }

  @computed get orderData() {
    let data = {
      companyId: this.root.menuStore.storeData.companyId,
      storeCode: this.root.menuStore.storeData.storeCode,
      extStoreCode: this.root.menuStore.storeData.extStoreCode,
      langCode: i18n.languages[0],
      userId: this.root.userStore.userData.phoneNumber ?? '',
      zipCode: this.root.userStore.userData.zipCode,
      sido: this.root.userStore.userData.sido,
      sigungu: this.root.userStore.userData.sigungu,
      dong: this.root.userStore.userData.dong,
      jibunAddress: this.root.userStore.userData.jibunAddress,
      roadAddress: this.root.userStore.userData.roadAddress,
      addressDetail: this.root.userStore.userData.addressDetail,
      latitude: this.root.userStore.userData.latitude,
      longitude: this.root.userStore.userData.longitude,
      requests:
        this.root.cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY
          ? this.deliveryRequests
          : this.orderRequests,
      channelCode: 1,
      receiveMethodCode: this.root.cartStore.cartData.receiveMethodCode,
      point: this.usePoint,
      paymentMethod: this.paymentMethod,
      paymentAmount: this.requestAmount,
      isPointAdded: this.root.userStore.userData.isPointAdded,
      lineChannelAccessToken: liff.isInClient() ? liff.getAccessToken() : '',
      extOrderNo: this.liteOrder ? this.liteOrder.receiptNumber : null,
      email: this.root.userStore.userData.email,
      userCardNumber: this.root.userStore.userData.userCardNumber,
      baydiamondDelivery: this.root.userStore.userData.baydiamondDelivery,
      zone: this.root.userStore.userData.baydiamondDelivery === '0' ? '' : this.root.userStore.userData.zone,
      block: this.root.userStore.userData.baydiamondDelivery === '0' ? '' : this.root.userStore.userData.block,
      seat: this.root.userStore.userData.baydiamondDelivery === '0' ? '' : this.root.userStore.userData.seat,
    };

    if (this.liteOrder) {
      data.menus = this.liteOrder.menus;
    } else {
      data.menus = this.root.cartStore.cartData.menus.map(cartMenu => {
        return {
          storeCode: cartMenu.storeCode,
          groupCode: cartMenu.groupCode,
          itemCode: cartMenu.itemCode,
          qty: cartMenu.qty,
          price: cartMenu.price,
          toppingGroups: toJS(cartMenu.toppingGroups),
          setMenuGroups: toJS(cartMenu.setMenuGroups),
        };
      });
    }
    return data;
  }

  // 개인정보 수집 및 이용 동의 다이얼로그 오픈 여부 상태 값 변경
  @action
  setPrivateDialogOpen(isPrivateDialogOpen) {
    this.isPrivateDialogOpen = isPrivateDialogOpen;
  }

  @action
  setDisablePaymentButton(value) {
    this.disablePaymentButton = value;
  }

  // 결제 요청 데이터 세팅
  @action
  setPaymentData(data) {
    this.paymentData = {
      orderId: data.orderId,
      ediDate: data.ediDate,
      ediTime: data.ediTime,
      signData: data.signData,
    };
  }

  @action
  setPaymentMethod(value) {
    this.paymentMethod = value;
  }

  // 소프트뱅크페이 결제 요청 데이터 세팅
  @action
  setPaymentSBData(data) {
    this.paymentSBData = data;
  }

  /**
   * 주문 조회
   * @param {string} orderId 주문 아이디
   */
  @action
  setOrder(data) {
    this.order = data;
  }

  /**
   * 주문 상태 갱신
   * @param {string} orderId 주문 아이디
   */
  @action
  async setOrderStatus(companyId, storeCode, orderId) {
    if (this.root.commonStore.error === null) {
      const response = await api.getOrderState(companyId, storeCode, orderId);

      // 주문 상태 변경
      this.order.currentStatusCode = Number(response.data);
    }
  }

  @action
  setStatusCheckInterval(interval) {
    this.statusCheckInterval = interval;
  }

  @action
  async setLiteOrder(orderId) {
    try {
      const response = await api.getLiteOrder(
        this.root.commonStore.companyId,
        this.root.commonStore.storeCode,
        orderId,
        i18n.languages[0],
      );
      if (!response.data) throw new Error(i18n.t('ERROR_NOT_FOUND_ORDER'));

      this.liteOrder = response.data;
      this.root.cartStore.setReceiveMethod(ReceiveMethodEnum.TAKEOUT);
    } catch (error) {
      this.root.commonStore.setError(error);
      throw error;
    }
  }
}

export default OrderStore;
