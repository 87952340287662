import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Toolbar, Button } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import FooterYokohama from '../common/FooterYokohama';

@inject('commonStore')
@observer
class Bottom extends Component {
  handleClickConfirm = () => {
    const { commonStore, history } = this.props;
    history.push(`/${commonStore.companyId}/${commonStore.storeCode}`);
  };

  render() {
    const { t } = this.props;
    return (
      // <AppBar position="fixed" color="primary" className="bottom">
      <div className="bottom">
        <Toolbar>
          <Button fullWidth variant="contained" size={'large'} onClick={this.handleClickConfirm}>
            <span className="bottom-button-title">{t('BUTTON_ORDER_5')}</span>
          </Button>
        </Toolbar>

        <FooterYokohama />
      </div>
      // </AppBar>
    );
  }
}

export default withRouter(withTranslation()(Bottom));
