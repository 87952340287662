import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  // AppBar,
  Toolbar,
  Button,
  Badge,
  Grid,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import liff from '@line/liff/dist/lib';
import api from '../../api';
import FooterYokohama from '../common/FooterYokohama';

const styles = theme => ({
  customBadge: {
    backgroundColor: '#FFD307',
    color: '#000',
  },
});

@inject('commonStore', 'cartStore', 'menuStore', 'orderStore', 'userStore')
@observer
class Bottom extends Component {
  handleClickOrder = async () => {
    const { commonStore, menuStore, cartStore, orderStore, userStore, t, history } = this.props;

    if (cartStore.cartData.menus.length === 0) {
      // commonStore.setSnackbar(true, t('ALERT_EMPTY_CART'));
      return;
    }

    // 품절 메뉴 체크
    const checkSoldout = await cartStore.checkOrderAvailable();
    if (checkSoldout !== '') {
      commonStore.setSnackbar(true, `[${checkSoldout}] ${t('ERROR_SOLD_OUT_PRODUCT')}`);
      return;
    }

    if (menuStore.storeData.orderMethod === '1') {
      // QR생성 주문
      const result = await api.addQrOrder(orderStore.orderData);
      history.push(`/qr-order/${result.data}`);
    } else {
      // PG 결제 주문
      if (menuStore.storeData.culture === 'ja-JP') {
        // 일본 매장인 경우 라인미니앱 여부 체크
        if (liff.isInClient()) {
          history.push({
            pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
            state: { isLiff: true },
          });
        } else {
          userStore.userData.phoneNumber = '';
          history.push({
            pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
            state: { isLiff: true },
          });
        }
      } else {
        history.push(`/${commonStore.companyId}/${commonStore.storeCode}/order`);
      }
    }
  };

  render() {
    const { t, classes, cartStore, menuStore, orderStore } = this.props;
    return (
      // <AppBar position="fixed" color="primary" className="bottom">
      <div className="bottom" style={{ color: '#FFF' }}>
        <Toolbar variant="dense" style={{ padding: '0' }}>
          <Button
            fullWidth
            color={'inherit'}
            variant={'text'}
            size={'large'}
            onClick={this.handleClickOrder}
            className="bottom-button"
          >
            {/* <Grid container alignItems={'center'} direction={'row'} spacing={0}> */}
            <Grid bgcolor="primary.main" container alignItems={'center'} direction={'row'} spacing={0}>
              <Grid item xs>
                <Badge badgeContent={cartStore.totalCnt} classes={{ badge: classes.customBadge }}>
                  <span className="bottom-button-title">{!this.props?.text ? t('BUTTON_ORDER') : this.props.text}</span>
                </Badge>
              </Grid>
              <Grid
                item
                style={{
                  width: '120px',
                }}
              >
                <Box bgcolor="secondary.main" className="bottom-button-price" align={'right'}>
                  {menuStore.currency}
                  {orderStore.orderAmount.toLocaleString()}
                </Box>
              </Grid>
            </Grid>
          </Button>
        </Toolbar>

        <FooterYokohama />
      </div>
      // </AppBar>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(Bottom)));
