import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Grid, TextField, MenuItem, Select } from '@mui/material';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';

@inject('cartStore', 'orderStore')
@observer
class OrderRequest extends Component {
  // 요청사항 변경
  handleChangeRequests = e => {
    let value = e.target.value;
    if (value.length > 20) value = value.substr(0, 20);

    this.props.orderStore.orderRequests = value;
  };

  // 배달 요청사항 선택 변경
  handleChangeDeliveryRequestsSelect = e => {
    const { orderStore } = this.props;
    const value = e.target.value;

    orderStore.deliveryRequestsSelect = value;

    if (value === 'INPUT') {
      orderStore.deliveryRequests = '';
    } else {
      orderStore.deliveryRequests = value;
    }
  };

  // 배달 요청사항 변경
  handleChangeDeliveryRequests = e => {
    let value = e.target.value;
    if (value.length > 20) {
      value = value.substr(0, 20);
    }

    this.props.orderStore.deliveryRequests = value;
  };

  render() {
    const { t, cartStore, orderStore } = this.props;
    return (
      <React.Fragment>
        <Grid item container direction={'column'} spacing={1}>
          <Grid item>
            <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>
              {t('MESSAGE_REQUEST')} <span className="price-color">({t('MESSAGE_OPTIONAL')})</span>
            </span>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            {cartStore.cartData.receiveMethodCode !== ReceiveMethodEnum.DELIVERY && (
              <Grid item>
                <TextField
                  value={orderStore.orderRequests}
                  placeholder={t('ALERT_ENTER_REQUEST')}
                  fullWidth
                  variant="outlined"
                  maxLength="20"
                  onChange={this.handleChangeRequests}
                />
              </Grid>
            )}
            {cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY && (
              <Grid item>
                <Select
                  variant="outlined"
                  fullWidth
                  value={orderStore.deliveryRequestsSelect}
                  onChange={this.handleChangeDeliveryRequestsSelect}
                >
                  <MenuItem value="NONE">{t('ALERT_SELECT_REQUEST')}</MenuItem>
                  <MenuItem value={t('MESSAGE_REQUEST_DOOR')} selected={true}>
                    {t('MESSAGE_REQUEST_DOOR')}
                  </MenuItem>
                  <MenuItem value={t('MESSAGE_REQUEST_CALL')}>{t('MESSAGE_REQUEST_CALL')}</MenuItem>
                  <MenuItem value="INPUT">{t('MESSAGE_REQUEST_DIRECT')}</MenuItem>
                </Select>
              </Grid>
            )}
            {cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY &&
              orderStore.deliveryRequestsSelect === 'INPUT' && (
                <Grid item>
                  <TextField
                    value={orderStore.deliveryRequests}
                    placeholder={t('ALERT_ENTER_REQUEST')}
                    fullWidth
                    variant="outlined"
                    maxLength="20"
                    onChange={this.handleChangeDeliveryRequests}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation()(OrderRequest);
