import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';
import { Box, Grid } from '@mui/material';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import NavigateNext from '@mui/icons-material/NavigateNext';

import LOGO from '../../static/yokohama/logo/hamamo_bottom_logo.png';
import LOGO_baydiamond from '../../static/yokohama/logo/hamamo_bottom_logo_baydiamond.png';

@inject('userStore')
@observer
class FooterYokohama extends Component {
  render() {
    const { userStore } = this.props;
    return (
      <React.Fragment>
        <Box component="footer" style={{ color: '#fff' }}>
          <Grid container style={{ backgroundColor: '#333', padding: '24px' }}>
            <Grid container style={{ textAlign: 'center', display: 'inline-block', marginTop: '64px' }}>
              <img src={userStore.userData.baydiamondDelivery === '0' ? LOGO : LOGO_baydiamond} height="72" alt="" />
            </Grid>

            <Grid container style={{ marginTop: '64px' }}>
              <List
                sx={{ width: '100%', maxWidth: 360 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{ fontSize: '16px' }}
              >
                <ListItemButton
                  href="https://www.yokohama-stadium.co.jp/faq"
                  target="_blank"
                  style={{ paddingLeft: '0px' }}
                >
                  <NavigateNext />
                  &nbsp;FAQ
                </ListItemButton>

                <ListItemButton
                  href="https://www.yokohama-stadium.co.jp/contact"
                  target="_blank"
                  style={{ paddingLeft: '0px' }}
                >
                  <NavigateNext />
                  &nbsp;{i18n.t('MESSAGE_INQUIRY')}
                </ListItemButton>

                <ListItemButton
                  href="https://www.yokohama-stadium.co.jp/mobileorder/term"
                  target="_blank"
                  style={{ paddingLeft: '0px' }}
                >
                  <NavigateNext />
                  &nbsp;{i18n.t('MESSAGE_TERMS_OF_SERVICE')}
                </ListItemButton>

                <ListItemButton
                  href="https://www.yokohama-stadium.co.jp/privacy"
                  target="_blank"
                  style={{ paddingLeft: '0px' }}
                >
                  <NavigateNext />
                  &nbsp;{i18n.t('MESSAGE_PRIVACY_POLICY')}
                </ListItemButton>

                <ListItemButton
                  href="https://www.yokohama-stadium.co.jp"
                  target="_blank"
                  style={{ paddingLeft: '0px' }}
                >
                  <NavigateNext />
                  &nbsp;{i18n.t('MESSAGE_YBD_OFFICIAL_WEBSITE_1')}
                </ListItemButton>

                <ListItemButton href="https://sp.baystars.co.jp" target="_blank" style={{ paddingLeft: '0px' }}>
                  <NavigateNext />
                  &nbsp;{i18n.t('MESSAGE_YBD_OFFICIAL_WEBSITE_2')}
                </ListItemButton>
                {userStore.userData.baydiamondDelivery === '0' && (
                  <ListItemButton
                    href="https://www.baystars.co.jp/foods/?topnavi=feature3"
                    target="_blank"
                    style={{ paddingLeft: '0px' }}
                  >
                    <NavigateNext />
                    &nbsp;{i18n.t('MESSAGE_YBD_OFFICIAL_WEBSITE_3')}
                  </ListItemButton>
                )}
              </List>
            </Grid>

            <Grid container style={{ marginTop: '30px' }}>
              <hr style={{ color: '#fff', width: '100%' }} />
            </Grid>

            <Grid
              container
              style={{
                marginTop: '20px',
                marginBottom: '0px',
                display: 'inline-block',
                textAlign: 'center',
              }}
            >
              ©︎YOKOHAMA STADIUM
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
}
export default FooterYokohama;
