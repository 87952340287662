import i18n from 'i18next';

const PaymentMethodEnum = {
  ETC: 0,
  CARD: 1,
  LINEPAY: 2,
  PAYCO: 3,
  KAKAOPAY: 4,
  PayPay: 5,
  AUPAY: 6,
  POINT: 7,
  properties: {
    0: i18n.t('MESSAGE_EASY'),
    1: i18n.t('MESSAGE_CARD'),
    2: 'LINEPAY',
    3: '페이코',
    4: '카카오페이',
    5: i18n.t('MESSAGE_PAYPAY'),
    6: 'au PAY',
    7: i18n.t('MESSAGE_POINTS'),
  },
};

export default PaymentMethodEnum;
