import CommonStore from './CommonStore';
import MenuStore from './MenuStore';
import CartStore from './CartStore';
import OrderStore from './OrderStore';
import UserStore from './UserStore';

class RootStore {
  constructor() {
    this.commonStore = new CommonStore(this);
    this.menuStore = new MenuStore(this);
    this.cartStore = new CartStore(this);
    this.orderStore = new OrderStore(this);
    this.userStore = new UserStore(this);
  }
}

export default RootStore;
