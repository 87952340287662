import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import Progress from '../common/Progress';
import { AppBar, Box, Grid } from '@mui/material';
import logo_only from '../../static/yokohama/logo/hamamo_only_logo.png';
import logo_only_baydiamond from '../../static/yokohama/logo/hamamo_only_logo_baydiamond.png';
import arrow from '../../static/yokohama/images/right_arrow_01.png';

import HeaderYokohama from '../common/HeaderYokohama';
import FooterYokohama from '../common/FooterYokohama';

@inject('menuStore', 'commonStore', 'userStore')
@observer
class Preparing extends Component {
  async componentDidMount() {
    const { commonStore, match } = this.props;
    commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);
  }

  handleClickHome = () => {
    const { history, match } = this.props;
    history.push(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}`);
  };

  render() {
    const { menuStore, userStore } = this.props;
    if (!menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        <AppBar position="fixed" color="header" style={{ boxShadow: 'none' }}>
          <HeaderYokohama />
        </AppBar>

        <Box style={{ marginTop: '63px', backgroundColor: '#F2F2F2', fontFamily: 'Noto Sans JP' }}>
          <Grid container>
            <Grid style={{ padding: '40px' }}>
              <div style={{ textAlign: 'center', display: 'block' }}>
                <img
                  src={userStore.baydiamondDelivery === '0' ? logo_only : logo_only_baydiamond}
                  style={{ width: '120px' }}
                  alt=""
                />
              </div>

              <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px', textAlign: 'center' }}>
                モバイルオーダー休業中
              </div>

              <div style={{ fontSize: '16px', marginTop: '30px' }}>
                ただいま、モバイルオーダーは営業しておりません。他の営業店舗をご利用ください。
              </div>

              <div style={{ fontSize: '16px', marginTop: '30px' }}>
                <img src={arrow} alt="" style={{ marginRight: '3px' }} />
                スケジュールはこちら
              </div>
            </Grid>
          </Grid>
        </Box>

        <FooterYokohama />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Preparing));
