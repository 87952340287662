import * as React from 'react';
import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid } from '@mui/material';
import api from '../../api';

@inject('commonStore')
@observer
class PickupWaitTime extends Component {
  async componentDidMount() {
    const { commonStore } = this.props;
    if (commonStore.root.menuStore.storeData) {
      // 스마트오더 상태 (영업종료, 주문수령 대기 시간)
      const status = await api.getSmartOrderState(commonStore.companyId, commonStore.storeCode);
      commonStore.root.menuStore.storeData.smartOrderStatus = status.data;
    }
  }
  render() {
    return (
      <Grid className="top-order-waitTime-container notice-base">
        {/**대기시간 */}
        ただいまの待ち時間目安は {this.props.commonStore.root.menuStore.storeData?.smartOrderStatus?.WAIT_TIME}分
        となります
      </Grid>
    );
  }
}

export default PickupWaitTime;
