import StoreSetMenuModel from './StoreSetMenuModel';

export default class StoreSetMenuGroupModel {
  groupCode;
  name;
  setMenus;

  constructor(data) {
    Object.assign(this, data);
    this.setMenus = data.setMenus.map(setMenu => new StoreSetMenuModel(setMenu));
  }
}
