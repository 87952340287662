import { observable } from 'mobx';
import StoreToppingGroupModel from './StoreToppingGroupModel';
import StoreSetMenuGroupModel from './StoreSetMenuGroupModel';

export default class StoreMenuModel {
  itemStoreCode;
  itemCode;
  name;
  pictureFileName1;
  pictureFileName2;
  price;
  rdPrice;
  isTax1;
  isTax2;
  memo;
  menuType;
  @observable isSoldOut;
  toppingGroups;
  setMenuGroups;
  @observable qty;

  constructor(data) {
    Object.assign(this, data);
    this.toppingGroups =
      data.toppingGroups === undefined
        ? []
        : data.toppingGroups.map(toppingGroup => new StoreToppingGroupModel(toppingGroup));
    this.setMenuGroups =
      data.setMenuGroups === undefined ? [] : data.setMenuGroups.map(setMenu => new StoreSetMenuGroupModel(setMenu));
    this.qty = 1;
  }
}
