import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  // AppBar,
  Button,
  Toolbar,
  CircularProgress,
  Box,
} from '@mui/material';
import Progress from '../common/Progress';
import api from '../../api';
import FooterYokohama from '../common/FooterYokohama';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class BottomPoint extends Component {
  componentDidMount() {
    // 페이지 접속 후에는 결제 상태 초기화
    this.props.orderStore.setDisablePaymentButton(false);
  }

  // 결제하기 버튼 클릭
  handleClickPay = async () => {
    const { t, history, match, commonStore, menuStore, userStore, orderStore } = this.props;

    // 결제 버튼 비활성화 (중복결제방지)
    orderStore.setDisablePaymentButton(true);

    if (
      menuStore.storeData.isPointUseable &&
      orderStore.usePoint > 0 &&
      userStore.userPoint < menuStore.storeData.useableMinimumPoint
    ) {
      commonStore.setSnackbar(
        true,
        t('ERROR_NOT_ENOUGH_POINTS', { n: Number(menuStore.storeData.useableMinimumPoint).toLocaleString() }),
      );
      orderStore.setDisablePaymentButton(false);
      return;
    }

    // 0원이 아니면 결제 되도록 수정 (2023-03-03 김세준실장님 요청)
    // if (orderStore.requestAmount > 0 && orderStore.requestAmount < 1) {
    if (orderStore.requestAmount > 0 && orderStore.requestAmount < 1) {
      commonStore.setSnackbar(true, t('ERROR_INSUFFICIENT_PAYMENT_AMOUNT'));
      orderStore.setDisablePaymentButton(false);
      return;
    }

    // 결제 금액이 0인 경우
    if (orderStore.requestAmount === 0) {
      try {
        const response = await api.payOrderByPointOnly(orderStore.orderData);
        if (orderStore.liteOrder) {
          // 마장동 주문일땐 화면 제한
          history.replace(`/${match.params.companyId}/${match.params.storeCode}/lite-orders/${response.data}/complete`);
        } else {
          history.replace(`/${match.params.companyId}/${match.params.storeCode}/orders/${response.data}/complete`);
        }
      } catch (error) {
        commonStore.setError(error);
        throw error;
      } finally {
        // 결제 버튼 활성화
        orderStore.setDisablePaymentButton(false);
      }
    } else {
      this.props.pay();
    }
  };

  render() {
    const { t, menuStore, cartStore, orderStore } = this.props;
    if (!menuStore.storeData || !cartStore.cartData) return <Progress />;
    return (
      // <AppBar position="fixed" color="primary" className="bottom">
      <div
        className="bottom"
        style={{ marginLeft: '-12px', marginRight: '-12px', marginBottom: '-100px', color: '#FFF' }}
      >
        <Toolbar variant="dense" style={{ padding: '0' }}>
          <Button
            fullWidth
            color={'inherit'}
            variant={'text'}
            size={'large'}
            onClick={this.handleClickPay}
            disabled={orderStore.disablePaymentButton}
            className="bottom-button"
          >
            {/* <Grid container alignItems={'center'} direction={'row'} spacing={0}> */}
            <Grid bgcolor="primary.main" container alignItems={'center'} direction={'row'} spacing={0}>
              <Grid item xs>
                <span className="bottom-button-title">{t('BUTTON_ORDER')}</span>
                {orderStore.disablePaymentButton && <CircularProgress size={24} />}
              </Grid>
              <Grid
                item
                style={{
                  width: '120px',
                }}
              >
                <Box bgcolor="secondary.main" className="bottom-button-price" align={'right'}>
                  {menuStore.currency}
                  {orderStore.requestAmount.toLocaleString()}
                </Box>
              </Grid>
            </Grid>
          </Button>
        </Toolbar>

        <FooterYokohama />
      </div>
      // </AppBar>
    );
  }
}

export default withRouter(withTranslation()(BottomPoint));
