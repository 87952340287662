import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import MAP_ICON from '../../static/images/map_icon.png';
import HELP_ICON from '../../static/images/help_icon.png';
import LOGO from '../../static/yokohama/logo/hamamo_top_logo.png';
import LOGO_baydiamond from '../../static/yokohama/logo/hamamo_top_logo_baydiamond.png';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';
import { Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import StoreMapYokohama from './StoreMapYokohama';

@inject('commonStore', 'menuStore', 'userStore')
@observer
class HeaderYokohama extends Component {
  // 메뉴 아이콘 클릭
  handleClickMenu = event => {
    const { commonStore } = this.props;
    commonStore.menuAnchorEl = event.currentTarget;
  };

  // 메뉴 창 닫기
  handleCloseMenu = () => {
    const { commonStore } = this.props;
    commonStore.menuAnchorEl = null;
  };

  // 매장위치
  handleClickMap = () => {
    const { commonStore } = this.props;
    // 지도 Modal 오픈
    commonStore.setMapOpen(true);
    commonStore.menuAnchorEl = null;
  };

  // 사용안내
  handleClickUserGuide = () => {
    const { history, commonStore } = this.props;
    commonStore.menuAnchorEl = null;
    history.push(`/${commonStore.companyId}/${commonStore.storeCode}/user-guide`);
  };

  // 주문목록
  handleClickOrderLookup = () => {
    const { match, history, commonStore } = this.props;

    const companyId = !commonStore.companyId ? match.params.companyId : commonStore.companyId;
    const storeCode = !commonStore.storeCode ? match.params.storeCode : commonStore.storeCode;

    commonStore.menuAnchorEl = null;
    history.push(`/${companyId}/${storeCode}/orderLookup`);
  };

  // Top 페이지 이동
  handleOpenNotice = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { commonStore, userStore } = this.props;
    return (
      <React.Fragment>
        <Toolbar
          style={{
            minHeight: '60px',
            borderBottom: '1px solid #DDD',
            borderBottomRightRadius: '16px',
            borderBottomLeftRadius: '16px',
            backgroundColor: '#FFF',
          }}
        >
          <div className="title">
            <img
              src={userStore.userData.baydiamondDelivery === '0' ? LOGO : LOGO_baydiamond}
              style={{ height: '40px', marginTop: '5px' }}
              alt=""
              onClick={this.handleOpenNotice}
            />
          </div>

          {/** 메뉴 아이콘 버튼 */}
          <IconButton edge="end" color="secondary" onClick={this.handleClickMenu} size="large">
            <MenuRoundedIcon fontSize={'large'} />
          </IconButton>
        </Toolbar>

        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={commonStore.menuAnchorEl}
          open={Boolean(commonStore.menuAnchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleClickOrderLookup}>
            <div className="menu-icon">
              <img src={HELP_ICON} alt={i18n.t('TITLE_ORDER_LIST')} />
            </div>
            {i18n.t('TITLE_ORDER_LIST')}
          </MenuItem>
          {userStore.userData.baydiamondDelivery === '0' && (
            <MenuItem onClick={this.handleClickMap}>
              <div className="menu-icon">
                <img src={MAP_ICON} alt={i18n.t('BUTTON_STORE_LOCATION')} />
              </div>
              {i18n.t('BUTTON_STORE_LOCATION')}
            </MenuItem>
          )}
          {/* {추후 사용 (임시주석)} */}
          {/* <MenuItem onClick={this.handleClickUserGuide}>
            <div className="menu-icon">
              <img src={HELP_ICON} alt={i18n.t('BUTTON_USER_GUIDE')} />
            </div>
            {i18n.t('BUTTON_USER_GUIDE')}
          </MenuItem> */}
        </Menu>

        {/* 지도보기 MODAL */}
        {<StoreMapYokohama />}
      </React.Fragment>
    );
  }
}

export default withRouter(HeaderYokohama);
