import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import api from '../../api';
import Bottom from './Bottom';

@inject('commonStore', 'menuStore', 'orderStore')
@observer
class PaymentHecto extends Component {
  componentDidMount() {
    const { orderStore } = this.props;
    const script = document.createElement('script');

    script.src = `${process.env.REACT_APP_HECTO_SERVER}/resources/js/v1/SettlePG.js`;
    script.async = true;

    document.body.appendChild(script);

    // 결제 방법 기본값을 카드로 설정
    if (!orderStore.paymentMethod) {
      orderStore.setPaymentMethod('1');
    }
  }

  // 결제방법 변경
  handlePayMethodChange = event => {
    this.props.orderStore.setPaymentMethod(event.target.value);
  };

  // 결제 수단 코드 조회
  getPaymentMethodCode(paymentMethod) {
    switch (paymentMethod) {
      case '1':
        return 'card';
      default:
        return 'corp';
    }
  }

  // 간편결제 코드 조회
  getEasyPaymentCode(paymentMethod) {
    switch (paymentMethod) {
      case '2':
        return 'PAC';
      case '4':
        return 'KKP';
      case '8':
      case '16':
        return 'NVP';
      default:
        return undefined;
    }
  }

  // 네이버페이 타입 조회
  getNaverPayType(paymentMethod) {
    switch (paymentMethod) {
      case '8':
        return 'CARD';
      case '16':
        return 'POINT';
      default:
        return undefined;
    }
  }

  pay = async () => {
    const { commonStore, menuStore, orderStore } = this.props;

    try {
      // 결제 예약 API 실행
      const response = await api.reserveOrder(orderStore.orderData);

      const baseUrl = `${
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
      }`;

      // PG사에 결제 요청
      /*global SETTLE_PG*/
      SETTLE_PG.pay(
        {
          env: process.env.REACT_APP_HECTO_SERVER,
          mchtId: menuStore.storeData.merchantID,
          method: this.getPaymentMethodCode(orderStore.paymentMethod),
          corpPayCode: this.getEasyPaymentCode(orderStore.paymentMethod),
          corpPayType: this.getNaverPayType(orderStore.paymentMethod),
          trdDt: response.data.ediDate,
          trdTm: response.data.ediTime,
          mchtTrdNo: response.data.orderId,
          mchtName: menuStore.storeData.storeName,
          mchtEName: '1234',
          pmtPrdtNm: orderStore.paymentMenuName,
          trdAmt: response.data.paymentAmount,
          mchtCustId: response.data.userId,
          notiUrl: `${process.env.REACT_APP_API_SERVER}/sales/hecto`,
          nextUrl: `${process.env.REACT_APP_API_SERVER}/sales/hecto/success`,
          cancUrl: `${baseUrl}/${commonStore.companyId}/${commonStore.storeCode}/order`,
          mchtParam: `${commonStore.companyId}|${commonStore.storeCode}`,
          pktHash: response.data.signData,
          ui: {
            type: 'self',
          },
        },
        function (rsp) {
          console.log(rsp);
        },
      );
    } catch (error) {
      commonStore.setError(error);
      throw error;
    } finally {
      // 결제 버튼 활성화
      orderStore.setDisablePaymentButton(false);
    }
  };

  render() {
    const { t, menuStore, orderStore } = this.props;
    return (
      <React.Fragment>
        {/* 결제방법 선택 */}
        <Grid item container direction={'column'} spacing={1} style={{ marginTop: '25px' }}>
          <Grid item>
            <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>{t('MESSAGE_PAYMENT_METHOD')}</span>
          </Grid>
          <Grid item>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={orderStore.paymentMethod}
              onChange={this.handlePayMethodChange}
            >
              {(menuStore.storeData.paymentMethod & 1) === 1 && (
                <FormControlLabel value={1} control={<Radio color="primary" />} label={'카드'} />
              )}
              {(menuStore.storeData.paymentMethod & 2) === 2 && (
                <FormControlLabel value={2} control={<Radio color="primary" />} label={'페이코'} />
              )}
              {(menuStore.storeData.paymentMethod & 4) === 4 && (
                <FormControlLabel value={4} control={<Radio color="primary" />} label={'카카오페이'} />
              )}
              {(menuStore.storeData.paymentMethod & 8) === 8 && (
                <FormControlLabel value={8} control={<Radio color="primary" />} label={'네이버페이 카드'} />
              )}
              {(menuStore.storeData.paymentMethod & 16) === 16 && (
                <FormControlLabel value={16} control={<Radio color="primary" />} label={'네이버페이 포인트'} />
              )}
            </RadioGroup>
          </Grid>
        </Grid>

        <Bottom pay={this.pay} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(PaymentHecto);
