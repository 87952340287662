import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';
import Header from '../common/Header';
import Bottom from './Bottom';
import { Box, Divider, Typography } from '@mui/material';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore')
@observer
class Join extends Component {
  async componentDidMount() {
    const { history, userStore } = this.props;

    if (!userStore.userData.phoneNumber) {
      history.goBack();
    }
  }

  render() {
    const { t, menuStore, cartStore } = this.props;
    if (!menuStore.storeData || !cartStore.cartData) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header iconType="CART" title={t('TITLE_JOIN')} />

        <div className="contents">
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" color="primary" align="center">
              {t('MESSAGE_TERMS')}
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">1. 수집하는 개인정보 항목</Typography>
            <Typography variant="body1" gutterBottom>
              휴대폰번호,주문정보,결제정보, (멤버십 적립시) 멤버십 적립번호(휴대폰 번호 또는 별도 멤버십번호)
            </Typography>
            <Typography variant="subtitle1">2. 수집하는 개인정보 이용목적</Typography>
            <Typography variant="body1">- 상품주문 및 결제, 고객이 주문한 상품의 준비 및 완료안내</Typography>
            <Typography variant="body1" gutterBottom>
              - 고객민원처리
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              3. 고객정보의 보유 및 이용기간 : 주문일로부터 5년간 보존 후 파기
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              4. 고객님은 동의를 거부하실 수 있으나, 서비스 제공에 필요한 최소한의 정보이므로 동의하지 않으실 경우
              서비스 이용이 제한될 수 있습니다.
            </Typography>
            <Typography variant="subtitle1">
              5. 오더서비스는 주문 및 결제처리, 주문정보 발송 등에 대한 업무를 (주)아임유에 위탁하여 처리합니다.
            </Typography>
          </Box>
        </div>

        {/* 푸터 버튼 */}
        <Bottom />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Join));
