import { AppBar, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { ArrowBack } from '@mui/icons-material';

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <AppBar position="fixed" color="primary" style={{ boxShadow: 'none', zIndex: 1 }}>
          <Toolbar style={{ minHeight: '60px' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.props.history.goBack()}
              size="large">
              <ArrowBack />
            </IconButton>
            <div className="title" style={{ flexGrow: 1, textAlign: 'center' }}>
              プライバシーポリシー
            </div>
          </Toolbar>
        </AppBar>
        <div className="contents">
          <h1 className="terms-title">UP SMART ORDERプライバシーポリシー</h1>
          <pre className="terms-contents">
            {`
第1条（適用範囲）
1. このプライバシーポリシー（以下「本プライバシーポリシー」といいます。）は、大江電機株式会社（以下「当社」といいます。）が提供するモバイルオーダーサービスであるUP SMART ORDER（以下「本サービス」といいます。）を利用するお客様（以下「お客様」といいます。）に関する情報の取扱いについて定めたものです。なお、本サービスの利用にはLINEプライバシーポリシーも適用されます。
2. 本サービスのご利用に関しては、本プライバシーポリシーの全文をお読みいただいた上で、同意の上、本サービスをご利用ください。

第2条（法令等の遵守）
当社は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の個人情報保護に関する法令、ガイドライン、本プライバシーポリシーを遵守するとともに、個人情報（個人情報保護法第2条第1項により定義された個人情報をいいます。以下同じ。）を適法かつ適切に取り扱います。

第3条（取得情報及び取得方法）
本サービスにおいて当社が取得するお客様に関する情報は以下のとおりです。
① お客様による入力情報
お客様が本サービスをご利用される際、氏名、電話番号、生年月日、クレジットカード情報その他当社が指定する届出情報の入力をお願いする場合があります。ただし、決済は決済代行会社が行うため、当社はクレジットカード情報を取得いたしません。
② お客様による本サービスの利用情報
お客様が本サービスを通じて行った注文履歴等の本サービス利用に関する情報、及び本サービスの閲覧履歴等の利用状況に関する情報をクッキー等のツールを利用して取得する場合があります。
③ 端末情報
お客様がスマートフォンやタブレット（以下「利用端末」といいます。）上で本サービスを利用する場合、利用端末の端末情報（端末識別子、IPアドレス等）を取得する場合があります。
④ 位置情報
お客様がご自身で携帯電話等の設定で位置情報の送信を許可している場合に限り、お客様の位置情報を使ったサービスや最適なサービスを提供する等の目的で取得する場合があります。
⑤ 第三者が運営するサービスの利用にかかる情報
当社は、本サービスの実施、お客様への最適なサービス提供のために、第三者（LINE株式会社を含みますがこれに限りません。）からお客様に関する情報を受領する場合があります。
⑥ お客様からのお問い合わせの際に取得する情報
お客様からのお問い合わせに対する調査、返答、ご本人確認等のために、お客様からのお問い合わせに際し、氏名、電話番号、住所、メールアドレス等の情報を取得する場合があります。

第4条（取得した情報の利用目的）
当社は、取得したお客様に関する情報を、以下の目的で利用します。
① 本サービスを提供するため
② 本サービスに関する各種事項の連絡や情報提供を行うため
③ 本サービスの利用に際してのお客様への連絡のため
④ 商品代金の決済・請求・支払のため
⑤ 本サービスに関する利用規約やプライバシーポリシー等の変更などを通知するため
⑥ お問い合わせ対応のため
⑦ キャンペーン・懸賞企画、アンケートの実施のため
⑧ 当社の商品又はサービスの広告、宣伝のため
⑨ マーケティング調査、統計、分析のため
⑩ 本サービスの最適化・改善、及び新商品・新サービス・新規事業の開発のため
⑪ 本サービスの利用規約に違反する態様での利用、スパム行為や不正アクセス等を防止するため
⑫ 本サービス運営上のトラブルの解決のため
⑬ 上記の利用目的に付随する目的のため

第5条（取得した情報の利用制限）
当社は、お客様からの同意を得た場合又は法令上要求若しくは許容される場合を除き、利用目的の達成に必要な範囲を超えて取得したお客様の情報を取り扱うことはありません。

第6条（個人情報の適正な取得）
当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。

第7条（個人情報の内容の正確性の確保等及び安全管理措置）
1. 当社は、お客様の個人情報につき、利用目的の達成に必要な範囲内において、正確かつ最新の内容に保つとともに、利用する必要がなくなったときは、当該個人情報を遅滞なく消去するように努めます。
2. 当社は、お客様の個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、必要かつ適切な措置を講じます。
3. 当社は、お客様の個人情報を従業員に取り扱わせるに当たっては、個人情報の安全管理が図られるよう、個人情報の適正な取扱いを周知徹底するとともに、適正な教育を行い、必要かつ適切な監督を行います。

第8条（情報の取扱いの委託）
当社は、利用目的の達成に必要な範囲内において、お客様から取得した情報（個人情報を含みます。以下同じ。）の取扱いを業務委託先に委託する場合があります。その際は、業務委託先としての適格性を十分に審査し、委託先においてお客様の情報が適切に取り扱われるよう、委託先に対する必要かつ適切な監督を行います。

第9条（取得した情報の第三者提供）
当社は、次に掲げる場合を除くほか、あらかじめ本人の同意を得ないで、お客様から取得した情報を第三者に提供しません。
①	本サービスの実施、改善を目的として、本サービスにおいて商品を販売提供する者（出店者）、及び商品を提供する店舗に対して、当該目的達成に必要な範囲でお客様から取得した情報を提供する場合
② 将来的に当社のグループ会社（「財務諸表等の用語、様式及び作成方法に関する規則（昭和38年大蔵省令第59号）」第8条に定める「子会社」及び「関連会社」をいう。以下同じ。）に対して本サービスを移管することを目的として、当社のグループ会社に対して、当該目的達成に必要な範囲でお客様から取得した情報を提供する場合
③ 法令上要求又は許容される場合

第10条（取得した情報の共同利用）
1． 当社は、利用目的の達成に必要な範囲で、お客様から取得した情報を、当社のグループ会社、開発会社と共同利用いたします。
2． 共同利用される利用者情報は、第3条に記載された情報と同じです。
3． 共同利用の目的は、第4条に記載された目的と同じです（ただし、共同利用の利用目的については、「当社」を「当社グループ会社」と、また、「本サービス」を「当社グループ会社の提供するサービス」と読み替えるものとします。）。
4． 共同利用における管理責任者は、当社となります。

第11条（保有個人データの開示・修正・利用停止） 
当社は、お客様から、個人情報保護法の定めに基づき保有個人データの開示、訂正、追加又は削除、利用の停止又は消去（以下「開示等」といいます。）を求められた場合、当社所定の方法によりお客様ご本人（又はその代理人）からの請求であることを確認した上で、遅滞なく開示等を行います。ただし、個人情報保護法その他の法令により、当社が開示等の義務を負わない場合はこの限りではありません。なお、保有個人データの一部又は全部を利用停止又は消去した場合、本サービスの提供ができなくなることがありますので、あらかじめご了承ください。

第12条（お問い合わせ）
お客様の情報の取扱いに関する苦情、その他のお問い合わせは、当社が別途指定しない限り、以下の窓口によるものとします。
UP SMART ORDERお問い合わせ窓口
電話番号：045-271-8090
対応時間：08:30-17:30
休業日：土日および祝日

第13条（改定）
当社は、法令の改正その他必要に応じ、本プライバシーポリシーの内容を随時改定することがあります。重要な変更に当たっては、お客様に対して改定内容をお知らせします。

【個人情報取扱事業者】
大江電機株式会社
横浜市南区前里町1-9
以上

改定
2021年7月13日
`}
          </pre>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Privacy));
