import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, ButtonGroup, Button } from '@mui/material';
import { RemoveSharp, AddSharp } from '@mui/icons-material';
import defaultImage from '../../static/images/defaultImage.png';
import i18n from 'i18next';

@inject('menuStore', 'cartStore', 'commonStore')
@observer
class MenuInfo extends Component {
  handleClickMinus = () => {
    this.props.menuStore.minusQty();
  };
  handleClickPlus = () => {
    const cartMenuCnt = this.props.cartStore.getMenuSumQty(this.props.menu.itemCode);
    if ((cartMenuCnt ?? 0) + this.props.menuStore.qty >= this.props.stockCnt) {
      this.props.commonStore.setSnackbar(true, i18n.t('ALERT_MAX_QTY'));
      return;
    }
    this.props.menuStore.plusQty();
  };

  render() {
    const { menuStore, menu } = this.props;
    return (
      <div>
        {/* 메뉴 사진 */}
        <div style={{ padding: '20px 0', textAlign: 'center' }}>
          <img
            src={menuStore.getImageUrl(menu.itemStoreCode, menu.pictureFileName1, menu.pictureFileName2)}
            alt={menu.name}
            height="140px"
            onError={e => (e.target.src = defaultImage)}
            style={{ maxWidth: '100%' }}
          />
        </div>
        {menuStore.menuDetail.memo && (
          <p>
            {menuStore.menuDetail.memo.split('\n').map((text, i) => {
              return (
                <span key={i}>
                  {text}
                  <br />
                </span>
              );
            })}
          </p>
        )}
        {/* 수량 및 단가 */}
        <div style={{ paddingBottom: '15px' }}>
          <Grid container>
            <Grid item xs>
              <ButtonGroup variant={'outlined'} size={'medium'} style={{ minHeight: '40px' }}>
                <Button style={{ padding: '5px', minWidth: '40px' }} onClick={this.handleClickMinus}>
                  <RemoveSharp />
                </Button>
                <Button style={{ minWidth: '70px' }}>{menuStore.qty}</Button>
                <Button style={{ padding: '5px', minWidth: '40px' }} onClick={this.handleClickPlus}>
                  <AddSharp />
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid
              item
              xs
              style={{
                textAlign: 'right',
                lineHeight: '40px',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              <span className="price-color">
                {menuStore.currency}
                {menuStore.getMenuPrice(menu).toLocaleString()}
              </span>
              {menuStore.storeData.tax1_type === '2' ? (
                <span style={{ color: '#8C8C8C', fontWeight: 'normal' }}>&nbsp;{i18n.t('TITLE_TAX_TYPE')}</span>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default MenuInfo;
