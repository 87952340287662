import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Box, List, Typography } from '@mui/material';
import StoreItem from './StoreItem';

@inject('userStore')
@observer
class StoreList extends Component {
  render() {
    const { userStore, t, ...otherProps } = this.props;
    if (!userStore.orderStores || userStore.orderStores.length === 0) {
      return (
        <Box id="store-list-container">
          <Typography variant="h6" className="no-order-history-alert">
            {t('ALERT_NO_ORDER_HISTORY')}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box id="store-list-container">
          <List className="store-list">
            {userStore.orderStores.map((store, storeIndex) => (
              <StoreItem store={store} key={storeIndex} {...otherProps} />
            ))}
          </List>
        </Box>
      );
    }
  }
}

export default withTranslation()(StoreList);
