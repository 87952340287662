import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

@inject('menuStore')
@observer
class Topping extends Component {
  render() {
    const { menuStore, toppingGroups } = this.props;
    return (
      <Grid item container direction="column">
        {toppingGroups.map(
          (toppingGroup, toppingGroupIndex) =>
            toppingGroup.toppings &&
            toppingGroup.toppings.map((topping, toppingIndex) => (
              <Grid item container direction="row" key={toppingIndex}>
                <Grid item xs>
                  <Typography style={{ fontSize: '12px', color: '#9D9B9B' }}>
                    {`${toppingGroup.name} (${topping.name})`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="right" style={{ fontSize: '12px' }}>
                    + {menuStore.currency}
                    {topping.addPrice}
                  </Typography>
                </Grid>
              </Grid>
            )),
        )}
      </Grid>
    );
  }
}

export default withRouter(Topping);
