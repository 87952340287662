import { observable } from 'mobx';

export default class StoreSetMenuModel {
  itemCode;
  name;
  addPrice;
  dineInAddPrice;
  takeOutAddPrice;
  deliveryAddPrice;
  addRdPrice;
  @observable isChecked;

  constructor(data) {
    Object.assign(this, data);
  }
}
