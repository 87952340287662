import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import Progress from '../common/Progress';
import Header from '../common/Header';
import TermDialog from '../order/TermDialog';
import OrderRequest from '../order/OrderRequest';
import UserInput from '../order/UserInput';
import Bottom from '../order/Bottom';
import ReceiveMethodButtonGroup from './ReceiveMethodButtonGroup';
import { Box } from '@mui/system';

@inject('commonStore', 'orderStore', 'userStore')
@observer
class LiteOrder extends Component {
  async componentDidMount() {
    const { match, location, commonStore, orderStore, userStore } = this.props;

    // 매장 데이터 세팅
    await commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);

    // 주문 데이터 세팅
    const params = queryString.parse(location.search);
    await orderStore.setLiteOrder(params.orderId);

    // 유저데이터 세팅
    userStore.setUserDataFromLocalStorage();
  }

  render() {
    const { t, orderStore } = this.props;
    if (!orderStore.liteOrder) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header title={t('TITLE_ORDER')} disabledBackButton={true} />

        <div className="contents">
          {/* 수령방법 선택 */}
          <Box mb={2}>
            <ReceiveMethodButtonGroup />
          </Box>
          {/* 주문 요청사항 */}
          <OrderRequest />

          {/* 사용자 정보 입력 */}
          <UserInput />
        </div>

        {/* 푸터 버튼 */}
        <Bottom />

        {/* 개인정보 수집 및 이용 동의 다이얼로그 */}
        <TermDialog />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(LiteOrder));
