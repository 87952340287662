import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ListItem, Avatar, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

@inject('commonStore', 'menuStore')
@observer
class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  // 메뉴 클릭시 상세로 이동
  handleMenuItemClick = () => {
    const { commonStore, menuGroupCode, menu, history } = this.props;
    history.push(
      `/${commonStore.companyId}/${commonStore.storeCode}/menu-groups/${menuGroupCode}/menus/${menu.itemCode}`,
    );
  };

  // componentDidMount = () => {
  //   const resizeToFit = () => {
  //     const refStamp = this.divRef.current;

  //     if (refStamp) {
  //       const fontSize = parseFloat(window.getComputedStyle(refStamp).fontSize);
  //       refStamp.style.fontSize = fontSize - 1 + 'px';

  //       // 크롬은 브라우저에서 최소 font-size 가 10px 로 정해져 있음. 그래서 크롬에서 개발자툴로 클라이언트 디바이스 조정한 경우 에러가 발생됨
  //       // 크기가 줄어드는 경우에만 loop 를 돌린다.
  //       if (fontSize !== parseFloat(window.getComputedStyle(refStamp).fontSize) && refStamp.clientWidth === 120) {
  //         resizeToFit();
  //       }
  //     }
  //   };

  //   resizeToFit();
  // };

  render() {
    const { t, menuStore, menu } = this.props;
    return (
      <ListItem
        component="li"
        key={menu.itemCode}
        onClick={this.handleMenuItemClick}
        disableGutters
        button
        dense
        disabled={menu.stockCnt <= 0 || menu.isSoldOut}
        style={{ padding: '10px 16px' }}
      >
        <ListItemAvatar>
          <Avatar
            src={menuStore.getImageUrl(menu.itemStoreCode, menu.pictureFileName1, menu.pictureFileName2)}
            style={{ width: 70, height: 70 }}
          ></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography component="span" variant="subtitle1" color="textPrimary" style={{ fontWeight: 'bold' }}>
                {menu.name}
                {/* 준비중 */}
                {menu.stockCnt === 0 ? <>&nbsp;&nbsp;&nbsp;{t('MESSAGE_ITEM_PREPARING')}</> : ''}
                {/* 품절 */}
                {menu.stockCnt === -999999 || menu.isSoldOut ? (
                  <> &nbsp;&nbsp;&nbsp;※&nbsp;&nbsp;{t('MESSAGE_SOLD_OUT2')}</>
                ) : (
                  ''
                )}
              </Typography>

              {/* 재고 재사용 가능 대기 시간 */}
              {menu.stockCnt === 0 && menu.stockWaitingTime > 0 ? (
                <Typography
                  component="span"
                  color="textPrimary"
                  style={{ fontWeight: 'bold', display: 'block', fontSize: '11px' }}
                >
                  {t('MESSAGE_ITEM_PREPARING_TIME', { n: menu.stockWaitingTime })}
                </Typography>
              ) : (
                ''
              )}
            </React.Fragment>
          }
          secondary={
            <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
              <span className="price-color" style={{ fontWeight: 'bold', fontSize: '15px' }}>
                {menuStore.currency}
                {menuStore.getMenuPrice(menu).toLocaleString()}
              </span>

              {menuStore.storeData.tax1_type === '2' ? (
                <span
                  style={{
                    color: '#8C8C8C',
                    fontWeight: 'normal',
                    fontSize: '11px',
                    paddingLeft: '3px',
                  }}
                >
                  {t('TITLE_TAX_TYPE')}
                </span>
              ) : (
                ''
              )}

              {menu.stamp !== '' ? (
                <span
                  ref={this.divRef}
                  className="stampItem"
                  style={{
                    borderRadius: '9px',
                    padding: '1px 5px 1px 5px',
                    whiteSpace: 'nowrap',
                    float: 'right',
                    color: 'white',
                    width: '101px',
                    textAlign: 'center',

                    fontSize: '11px',
                    backgroundColor: `${
                      stampPalette.filter(x => x.value === menu.stamp).length > 0
                        ? stampPalette.filter(x => x.value === menu.stamp)[0].color
                        : ''
                    }`,
                  }}
                >
                  {t(`MESSAGE_STAMP_${menu.stamp}`)}
                </span>
              ) : (
                ''
              )}
            </span>
          }
          style={{ marginLeft: '16px' }}
        />
      </ListItem>
    );
  }
}

const stampPalette = [
  { value: '01', color: '#EDA184' },
  { value: '02', color: '#E6855E' },
  { value: '03', color: '#E06A3B' },
  { value: '04', color: '#DA5019' },
  { value: '05', color: '#7379AE' },
  { value: '06', color: '#4B75B9' },
  { value: '07', color: '#1F91BE' },
  { value: '08', color: '#1DAF9E' },
];

export default withTranslation()(withRouter(MenuItem));
