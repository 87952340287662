import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Divider, ListItem, Typography, ButtonGroup, Button, IconButton, Avatar, Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { AddSharp, RemoveSharp, HighlightOff } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';

@inject('cartStore', 'menuStore')
@observer
class CartMenuItem extends Component {
  render() {
    const { cartStore, menuStore, cartMenu, index, t } = this.props;
    return (
      <ListItem
        divider
        style={{ paddingLeft: '2px', paddingRight: '2px' }}
        disabled={cartMenu.stockCnt <= 0 || cartMenu.isSoldOut}
      >
        {/* {console.log(this.props.isSoldOut)} */}
        <Box sx={{ flexGrow: 1 }} style={{ margin: '8px 16px' }}>
          <Grid2 container direction={'column'} spacing={2}>
            <Grid2 container direction={'row'}>
              <Grid2>
                <Avatar
                  src={menuStore.getImageUrl(
                    cartMenu.itemStoreCode,
                    cartMenu.pictureFileName1,
                    cartMenu.pictureFileName2,
                  )}
                  style={{ width: 70, height: 70 }}
                ></Avatar>
              </Grid2>
              <Grid2 container xs direction="column">
                <Grid2>
                  <Typography component="span" variant="subtitle1" color="textPrimary" style={{ fontWeight: 'bold' }}>
                    {cartMenu.name}
                    {cartMenu.stockCnt <= 0 || cartMenu.isSoldOut ? ` ${t('MESSAGE_ITEM_PREPARING')}` : ''}
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography color="textSecondary" variant="body2">
                    {cartStore.getToppingNames(index)}
                    {cartStore.getSetMenuNames(index)}
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container direction={'row'} justifyContent="space-between">
              <Grid2>
                <ButtonGroup variant={'outlined'} size={'medium'}>
                  <Button
                    style={{ padding: '5px', minWidth: '40px' }}
                    onClick={() => cartStore.minusItemQtyInCart(index)}
                  >
                    <RemoveSharp />
                  </Button>
                  <Button>{cartStore.cartData.menus[index].qty}</Button>
                  <Button
                    style={{ padding: '5px', minWidth: '40px' }}
                    onClick={() => cartStore.plusItemQtyInCart(index)}
                  >
                    <AddSharp />
                  </Button>
                </ButtonGroup>
              </Grid2>
              <Grid2 container>
                <Grid2 xs>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {menuStore.currency}
                    {menuStore.calcTaxMenuPrice(cartMenu, cartMenu.qty).toLocaleString()}
                    {menuStore.storeData.tax1_type === '2' ? (
                      <span style={{ color: '#8C8C8C', fontWeight: 'normal' }}>&nbsp;{t('TITLE_TAX_TYPE')}</span>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Grid2>
                <Grid2>
                  <IconButton size="small" onClick={() => cartStore.removeItemInCart(index)}>
                    <HighlightOff />
                  </IconButton>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
        <Divider />
      </ListItem>
    );
  }
}

export default withTranslation()(CartMenuItem);
