import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

@inject('menuStore')
@observer
class SetMenu extends Component {
  render() {
    const { menuStore, setMenuGroups } = this.props;
    return (
      <Grid item container direction="column">
        {setMenuGroups.map(
          (setMenuGroup, setMenuGroupIndex) =>
            setMenuGroup.setMenus &&
            setMenuGroup.setMenus.map((setMenu, setMenuIndex) => (
              <Grid item container direction="row" key={setMenuIndex}>
                <Grid item xs>
                  <Typography style={{ fontSize: '8pt', color: '#9D9B9B' }}>
                    {`${setMenuGroup.name} (${setMenu.name})`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="right" style={{ fontSize: '8pt', color: '#9D9B9B' }}>
                    + {menuStore.currency}
                    {setMenu.addPrice.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            )),
        )}
      </Grid>
    );
  }
}

export default withRouter(SetMenu);
