import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Grid, TextField, Select, MenuItem } from '@mui/material';

@inject('cartStore', 'orderStore', 'userStore')
@observer
class MemberInfo extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      emailValidation: false,
      phoneValidation: false,
      userCardNumberValidation: false,
      zoneInfoValidation: false,
      zoneList: [
        { value: 'BAY', name: `BAY SIDE（${t('MESSAGE_ZONE_BAY')}）` },
        { value: 'STAR', name: `STAR SIDE（${t('MESSAGE_ZONE_STAR')}）` },
      ],
      blockList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      seatList: [],
      seatList1: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      seatList2: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    };
  }

  async componentDidMount() {
    this.props.userStore.setUserDataFromLocalStorage();

    this.setState({ ...this.state, seatList: this.state.seatList1 });
  }

  emailValidationCheck = e => {
    const regex = /^[a-zA-Z0-9/\-/+/./_]+@(?:[a-zA-Z0-9\-/]+\.)+[A-Za-z]+$/;
    this.setState(() => {
      return {
        emailValidation:
          this.props.userStore.userData.email === ''
            ? false
            : !(this.props.userStore.userData.email && regex.test(this.props.userStore.userData.email)),
      };
    });
  };

  ChangePhoneNum = e => {
    let val = this.forceInteger(e.target.value);
    let validation = false;

    if (val === '' || val.length < 10) validation = true;
    this.props.userStore.userData.phoneNumber = val;

    this.setState(() => {
      return { phoneValidation: validation };
    });
  };

  changeUserCardNumber = e => {
    let val = this.forceInteger(e.target.value);
    let validation = false;
    if (val.length !== 10) validation = true;
    if (val.length === 0) validation = false;

    this.props.userStore.userData.userCardNumber = val;

    this.setState(() => {
      return { userCardNumberValidation: validation };
    });
  };

  forceInteger = arg => {
    return arg.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
  };

  handleZoneInfo = e => {
    const { value, name } = e.target;
    let validation = true;

    this.props.userStore.userData = { ...this.props.userStore.userData, [name]: value };

    if (this.props.userStore.userData.zone === 'BAY' && this.props.userStore.userData.block >= '8') {
      this.setState({ ...this.state, seatList: this.state.seatList2 });

      if (this.props.userStore.userData.seat < '6') this.props.userStore.userData.seat = '';
    } else {
      if (this.state.seatList.length !== 30) this.setState({ ...this.state, seatList: this.state.seatList1 });
    }

    if (
      this.props.userStore.userData.zone === '' ||
      this.props.userStore.userData.block === '' ||
      this.props.userStore.userData.seat === ''
    ) {
      validation = false;
    }

    this.setState(() => {
      return { zoneInfoValidation: validation };
    });
  };

  render() {
    const { emailValidation, phoneValidation, userCardNumberValidation } = this.state;
    const { t, userStore } = this.props;
    return (
      <React.Fragment>
        <Grid item container direction={'column'} spacing={3}>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                {t('MESSAGE_CUSTOMER_INFO')} <span className="price-color">({t('MESSAGE_REQUIRED')})</span>
              </div>
              <div style={{ fontSize: '14px' }}>
                メールアドレスに誤りがある場合、通知が届きません。誤りがないか、十分ご確認いただき、入力ください。また、mobileorder.yokohama-stadium.co.jpドメインのアドレスからメールが受信できるようにしてください。
              </div>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <TextField
                  value={userStore?.userData.email ?? ''}
                  fullWidth
                  variant="outlined"
                  maxLength="20"
                  onChange={e => {
                    this.props.userStore.userData.email = e.target.value;
                  }}
                  onBlur={this.emailValidationCheck}
                  error={emailValidation}
                  label={t('MESSAGE_CUSTOMER_EMAIL')}
                  helperText={emailValidation ? t('ERROR_EMAIL_VALIDATION') : ''}
                  inputProps={{ maxLength: 256 }}
                  required={true}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={userStore?.userData.phoneNumber ?? ''}
                  label={t('MESSAGE_CUSTOMER_PHONE')}
                  fullWidth
                  variant="outlined"
                  error={phoneValidation}
                  required={true}
                  helperText={phoneValidation ? t('ERROR_CUSTOMER_PHONE_VALIDATION') : ''}
                  onChange={this.ChangePhoneNum}
                  inputProps={{ maxLength: 11, pattern: '[0-9]*', inputMode: 'numeric' }}
                />
              </Grid>

              {userStore.userData.baydiamondDelivery === '1' && (
                <>
                  <Grid item>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '40px' }}>
                      {t('MESSAGE_SEAT_INFO')} <span className="price-color">({t('MESSAGE_REQUIRED')})</span>
                    </div>
                    <div style={{ fontSize: '14px' }}>
                      お客様がお座りいただいている座席情報を下記から選択してください。QRコードからアクセスいただいた方は、自動で入力されておりますので、間違いがないか確認をお願いいたします。誤りがある場合は、下記より修正してください。
                      <br />
                      <font color="red">※</font>{' '}
                      商品をお届けする座席情報となります。必ず間違いがないよう、確認ください。
                      <br />
                      <font color="red">※</font>{' '}
                      本サービスの対象の座席しか選択できないようになっております。選択できないシート番号からのご注文はできません。
                    </div>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      <Select
                        fullWidth
                        value={userStore?.userData.zone ?? ''}
                        onChange={this.handleZoneInfo}
                        name="zone"
                      >
                        {this.state.zoneList.map((item, idx) => (
                          <MenuItem value={item.value} key={idx}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        fullWidth
                        value={userStore?.userData.block ?? ''}
                        onChange={this.handleZoneInfo}
                        name="block"
                      >
                        {this.state.blockList.map((item, idx) => (
                          <MenuItem value={item} key={idx}>
                            {item}段
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        fullWidth
                        value={userStore?.userData.seat ?? ''}
                        onChange={this.handleZoneInfo}
                        name="seat"
                      >
                        {this.state.seatList.map((item, idx) => (
                          <MenuItem value={item} key={idx}>
                            {item}番
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item>
                <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '40px' }}>
                  BlueMates{t('MESSAGE_CUSTOMER_INFO2')} <span className="price-color">({t('MESSAGE_CHOICE')})</span>
                </div>
                <div style={{ fontSize: '14px', paddingTop: '5px' }}>
                  <div>
                    当年度BlueMates会員の方は、BAYSTARS ID(10桁)を入力いただくことで、購入金額により
                    STARを獲得することができます。
                    <br />
                    BlueMatesロイヤリティプログラムの対象試合は、原則、クライマックスシリーズ・日本シリーズを除く横浜スタジアム主催セ・リーグ試合となります。その他の対象については、横浜DeNAベイスターズ公式HPのファンクラブ関連ニュースでご確認ください（ロイヤリティプログラムについては
                    <a
                      href="https://www.baystars.co.jp/news/2024/01/0131_03.php"
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: 'bold' }}
                    >
                      コチラ
                    </a>
                    からご確認ください）
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    <div style={{ paddingBottom: '5px' }}>
                      <font className="price-color">※ </font>
                      注文後のBAYSTARS IDの後付けや、変更は受け付けておりません。間違いがないようにご注意ください
                    </div>

                    <div style={{ paddingBottom: '5px' }}>
                      <font className="price-color">※ </font>
                      STARは注文された翌日に付与されます。マイページ上でポイント付与をご確認ください
                    </div>

                    <div style={{ paddingBottom: '5px' }}>
                      <font className="price-color">※ </font>BlueMatesに加入前の注文についてはSTARは付与されません
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={userStore?.userData.userCardNumber ?? ''}
                  label={t('MESSAGE_CUSTOMER_CODE')}
                  variant="outlined"
                  error={userCardNumberValidation}
                  required={false}
                  helperText={userCardNumberValidation ? t('ERROR_CUSTOMER_CARDNUM_VALIDATION') : ''}
                  inputProps={{ maxLength: 10, pattern: '[0-9]*', inputMode: 'numeric' }}
                  onChange={this.changeUserCardNumber}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation()(MemberInfo);
