import { action, observable } from 'mobx';
import history from '../History';

class CommonStore {
  constructor(root) {
    this.root = root;
  }

  @observable companyId = ''; // 회사 아이디
  @observable storeCode = ''; // 매장 코드
  @observable isOpenSnackbar = false; // 스낵바 오픈 여부
  @observable snackbarText = ''; // 스낵바 내용
  @observable noticeText = ''; // 공지다이어로그 내용
  @observable noticeUnavailable = false; // 사용 불가 공지 다이어로그
  @observable error = null; // 에러
  @observable menuAnchorEl = null; // 메뉴 팝오버 오픈 여부
  @observable isMapOpen = false; // 지도 오픈 여부
  @observable map = {}; // 선택한 매장 데이터
  @observable consentToPrivacy = false; // 개인정보 동의 여부
  @observable baydiamondDelivery = ''; // 요코하마 bay / star 배달 0: take-out (사용안함), 1: delivery (사용함)

  // 매장 세팅
  @action
  async setData(companyId, storeCode) {
    this.companyId = companyId;
    this.storeCode = storeCode;

    // 매장 데이터 조회
    await this.root.menuStore.setStoreData();

    // baydiamondDelivery 변경
    this.baydiamondDelivery = this.root.menuStore.storeData.baydiamondDelivery;

    const _consentToPrivacy = sessionStorage.getItem('consentToPrivacy') === 'true' ? true : false;
    // Up스마트오더 서비스 이용 가능 여부 조회
    if (!this.root.menuStore.storeData.useable) {
      history.replace('/service-unavailable');
    }
    // 주문상세 페이지는 어떤 조건에서도 보여져야 함. (ex: 메일에 링크가 포함될 경우 다이렉트로 해당 url 로 들어옴)
    else if (
      window.location.href.toLowerCase().includes('orders') ||
      window.location.href.toLowerCase().includes('service-wait')
    ) {
    }
    // 매장 오픈 여부 체크
    else if (this.root.menuStore.storeData && !this.root.menuStore.isOpenStore) {
      history.replace(`/${this.companyId}/${this.storeCode}/errors/preparing`);
    }
    // 매장 준비중 체크
    else if (
      (this.root.menuStore.storeData.smartOrderStatus.CLOSE_TYPE === '2' ||
        this.root.menuStore.storeData.smartOrderStatus.CLOSE_TYPE === '3') &&
      !window.location.href.toLowerCase().includes('orderlookup')
    ) {
      history.replace(`/${this.companyId}/${this.storeCode}/service-wait`);
    }
    // 개인 정보 제공 체크 확인
    else if (!_consentToPrivacy && !window.location.href.toLowerCase().includes('orderlookup')) {
      history.replace('/');
    }

    // 장바구니 데이터 조회
    this.root.cartStore.setCartDataFromSessionStorage();

    // 사용자 동의 체크 및 공지사항
    this.setNotice(this.root.menuStore.storeData.notice);
  }

  // 스낵바 세팅
  @action
  setSnackbar(isOpen, text = '') {
    this.isOpenSnackbar = isOpen;
    this.snackbarText = text;
  }

  // 다이어로그 세팅
  @action
  setNotice(noticeText) {
    // if (sessionStorage.getItem('isOpenNoticeDialog') && this.consentToPrivacy) return;
    this.noticeText = noticeText === undefined || noticeText === '' ? '' : noticeText;
  }

  // 에러 세팅
  @action
  setError(error) {
    this.error = error;
  }

  // 지도 오픈 여부 설정
  @action
  setMapOpen(isOpen) {
    this.isMapOpen = isOpen;
  }

  // 매장 선택
  @action
  setMap(storeName, storeAddress1, storeAddress2, storePhone) {
    this.map = {
      storeName: storeName,
      storeAddress1: storeAddress1,
      storeAddress2: storeAddress2,
      storePhone: storePhone,
    };
  }
}

export default CommonStore;
