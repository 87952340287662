import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Paper, Tabs, Tab } from '@mui/material';

@inject('menuStore')
@observer
class Category extends Component {
  componentDidMount() {
    const { menuStore } = this.props;
    // 시간대별 메뉴 그룹 필터링
    menuStore.filterMenuGroups();

    // 페이지 로드 후 설정된 기본 메뉴 그룹으로 세팅. 기본 메뉴 그룹이 사용 시간대가 아니라면 해당 그룹을 제외한 첫번쨰 메뉴 그룹 선택.
    let defaultMenuGroup =
      menuStore.menuGroupIndex > 0
        ? menuStore.filterdMenuGroups[menuStore.menuGroupIndex]
        : menuStore.storeData.menuGroups.find(menuGroup => menuGroup.isDefault === '1') ||
          menuStore.storeData.menuGroups[0];
    // 사용 시간대가 아닌 메뉴그룹을 제외한 리스트에서 index값을 구하여 세팅
    menuStore.setMenuGroupIndex(menuStore.filterdMenuGroups.indexOf(defaultMenuGroup));
  }

  // 탭 변경
  handleMenuGroupChange = (event, value) => {
    const { menuStore } = this.props;
    menuStore.setMenuGroupIndex(value);
  };

  render() {
    const { menuStore } = this.props;
    return (
      <Paper square>
        {menuStore.filterdMenuGroups && menuStore.filterdMenuGroups.length > 0 && (
          <Tabs
            value={menuStore.menuGroupIndex}
            onChange={this.handleMenuGroupChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            TabIndicatorProps={{ style: { height: '4px' } }}
          >
            {menuStore.filterdMenuGroups.map((menuGroup, index) => {
              return <Tab key={index} label={menuGroup.name} />;
            })}
          </Tabs>
        )}
      </Paper>
    );
  }
}

export default Category;
