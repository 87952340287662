import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { List, ListItem, ListItemIcon, ListItemText, Grid, Checkbox } from '@mui/material';
import { withTranslation } from 'react-i18next';

@inject('menuStore')
@observer
class Topping extends Component {
  handleClickTopping = (groupCode, toppingCode, isChecked, isSoldOut) => {
    this.props.menuStore.setTopping(groupCode, toppingCode, isChecked, isSoldOut);
  };

  render() {
    const { menuStore, toppingGroups, t } = this.props;
    return (
      <List className="option-group-list">
        {toppingGroups.map((toppingGroup, index) => (
          <ListItem disableGutters dense key={index} className="option-group">
            <Grid container direction="column">
              <Grid item className="option-group-title">
                {toppingGroup.name}{' '}
                {toppingGroup.minCount > 0 && toppingGroup.maxCount > 0 && (
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    ({t('MESSAGE_OPTION_MIN')}
                    {toppingGroup.minCount}, {t('MESSAGE_OPTION_MAX')}
                    {toppingGroup.maxCount})
                  </span>
                )}
                {toppingGroup.minCount === 0 && toppingGroup.maxCount > 0 && (
                  <span style={{ fontSize: '12px' }}>
                    ({t('MESSAGE_OPTION_MAX')}
                    {toppingGroup.maxCount})
                  </span>
                )}
              </Grid>
              <Grid item className="option-list">
                <List>
                  {toppingGroup.toppings.map((topping, toppingIndex) => (
                    <ListItem
                      disableGutters
                      dense
                      button
                      disabled={topping.isSoldOut}
                      key={toppingIndex}
                      onClick={() =>
                        this.handleClickTopping(
                          toppingGroup.groupCode,
                          topping.toppingCode,
                          topping.isChecked,
                          topping.isSoldOut,
                        )
                      }
                    >
                      <ListItemIcon>
                        <Checkbox edge="start" checked={topping.isChecked} disabled={topping.isSoldOut} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        //primary={<span style={{ fontSize: '12pt' }}>{topping.name}</span>}
                        primary={
                          <Grid container>
                            <Grid item xs>
                              <span style={{ fontSize: '12pt' }}>
                                {topping.name}
                                {topping.isSoldOut ? ` (${t('MESSAGE_SOLD_OUT')})` : ''}
                              </span>
                            </Grid>
                            <Grid item style={{ width: '80px', textAlign: 'right' }}>
                              <span style={{ fontSize: '11pt' }}>
                                + {menuStore.currency}
                                {menuStore.getToppingPrice(topping).toLocaleString()}
                              </span>
                            </Grid>
                          </Grid>
                        }
                        style={{ marginLeft: '-30px' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withTranslation()(Topping);
