import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';
import { AppBar, Box, Grid, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import MAP_ICON from '../../static/images/map_icon.png';
import HELP_ICON from '../../static/images/help_icon.png';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LOGO from '../../static/yokohama/logo/hamamo_top_logo.png';
import logo_only from '../../static/yokohama/logo/hamamo_only_logo.png';
import logo_only_baydiamond from '../../static/yokohama/logo/hamamo_only_logo_baydiamond.png';
import arrow from '../../static/yokohama/images/right_arrow_01.png';

import FooterYokohama from '../common/FooterYokohama';

@inject('commonStore', 'orderStore', 'userStore')
@observer
class ErrorBoundary extends Component {
  componentDidCatch(error) {
    this.props.commonStore.setError(error);
  }

  render() {
    const { commonStore, userStore } = this.props;
    if (commonStore.error) {
      return (
        <div className="error-container">
          <AppBar position="fixed" color="header" style={{ boxShadow: 'none' }}>
            <Toolbar
              style={{
                minHeight: '60px',
                borderBottom: '1px solid #DDD',
                borderBottomRightRadius: '16px',
                borderBottomLeftRadius: '16px',
                backgroundColor: '#FFF',
              }}
            >
              <div className="title" style={{ textAlign: 'left' }}>
                <img src={LOGO} style={{ height: '40px', marginTop: '5px' }} alt="" />
              </div>

              {/** 메뉴 아이콘 버튼 */}
              <IconButton edge="end" color="secondary" onClick={this.handleClickMenu} size="large">
                <MenuRoundedIcon fontSize={'large'} />
              </IconButton>
            </Toolbar>

            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={commonStore.menuAnchorEl}
              open={Boolean(commonStore.menuAnchorEl)}
              onClose={this.handleCloseMenu}
            >
              <MenuItem onClick={this.handleClickOrderLookup}>
                <div className="menu-icon">
                  <img src={HELP_ICON} alt={i18n.t('TITLE_ORDER_LIST')} />
                </div>
                {i18n.t('TITLE_ORDER_LIST')}
              </MenuItem>
              <MenuItem onClick={this.handleClickMap}>
                <div className="menu-icon">
                  <img src={MAP_ICON} alt={i18n.t('BUTTON_STORE_LOCATION')} />
                </div>
                {i18n.t('BUTTON_STORE_LOCATION')}
              </MenuItem>
              <MenuItem onClick={this.handleClickUserGuide}>
                <div className="menu-icon">
                  <img src={HELP_ICON} alt={i18n.t('BUTTON_USER_GUIDE')} />
                </div>
                {i18n.t('BUTTON_USER_GUIDE')}
              </MenuItem>
            </Menu>
          </AppBar>

          <Box style={{ marginTop: '63px', backgroundColor: '#F2F2F2', fontFamily: 'Noto Sans JP' }}>
            <Grid container>
              <Grid style={{ padding: '40px', textAlign: 'left' }}>
                <div style={{ textAlign: 'center', display: 'block' }}>
                  <img
                    src={userStore.userData.baydiamondDelivery === '0' ? logo_only : logo_only_baydiamond}
                    style={{ width: '120px' }}
                    alt=""
                  />
                </div>

                <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px' }}>
                  ただいま、サービスにアクセスできません
                </div>

                <div style={{ fontSize: '16px', marginTop: '30px' }}>
                  <div style={{ color: 'red' }}>ただいま、サービスにアクセスできません。</div>

                  <div style={{ marginTop: '30px' }}>
                    申し訳ございませんが、しばらくお待ちいただいてから、再度ページにアクセスください。
                  </div>
                </div>

                <div style={{ fontSize: '16px', marginTop: '30px' }}>
                  <img src={arrow} alt="" style={{ marginRight: '3px' }} />
                  モバイルオーダーについて
                </div>
              </Grid>
            </Grid>
          </Box>

          <FooterYokohama />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
