import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

@inject('orderStore')
@observer
class TermDialog extends Component {
  // 약관 다이얼로그 닫기
  handleCloseDialog = () => {
    this.props.orderStore.setPrivateDialogOpen(false);
  };

  render() {
    const { t, orderStore } = this.props;
    return (
      <Dialog
        open={orderStore.isPrivateDialogOpen}
        onClose={this.handleCloseDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth={'lg'}
      >
        <DialogTitle id="scroll-dialog-title" style={{ margin: 0 }}>
          <span style={{ fontSize: '11pt' }}>{t('MESSAGE_TERMS')}</span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={0} direction={'column'}>
            <Grid item style={{ fontSize: '10pt', margin: 0, padding: 0 }}>
              1. 수집하는 개인정보 항목
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              휴대폰번호,주문정보,결제정보, (멤버십 적립시) 멤버십 적립번호(휴대폰 번호 또는 별도 멤버십번호)
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              2. 수집하는 개인정보 이용목적
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              - 상품주문 및 결제, 고객이 주문한 상품의 준비 및 완료안내
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              - 고객민원처리
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              3. 고객정보의 보유 및 이용기간 : 주문일로부터 5년간 보존 후 파기
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              4. 고객님은 동의를 거부하실 수 있으나, 서비스 제공에 필요한 최소한의 정보이므로 동의하지 않으실 경우
              서비스 이용이 제한될 수 있습니다.
            </Grid>
            <Grid item style={{ fontSize: '10pt' }}>
              5. 오더서비스는 주문 및 결제처리, 주문정보 발송 등에 대한 업무를 (주)아임유에 위탁하여 처리합니다.
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            {t('BUTTON_CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(TermDialog);
