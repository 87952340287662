import React from 'react';
import { observer, inject } from 'mobx-react';
import SwipeableViews from 'react-swipeable-views';
import { List } from '@mui/material';
import MenuItem from './MenuItem';

@inject('menuStore')
@observer
class MenuList extends React.Component {
  // 스와이프로 탭 변경 시 groupIndex 값 변경
  handleChangeIndex = index => {
    const { menuStore } = this.props;
    menuStore.setMenuGroupIndex(index);
  };

  render() {
    const { menuStore } = this.props;
    return (
      <SwipeableViews
        axis="x"
        index={menuStore.menuGroupIndex}
        onChangeIndex={this.handleChangeIndex}
        animateHeight={true}
      >
        {menuStore.filterdMenuGroups.map((menuGroup, menuGroupIndex) => (
          <List className="menu-list" key={menuGroupIndex}>
            {menuGroup.menus.map((menu, menuIndex) => (
              <MenuItem menuGroupCode={menuGroup.groupCode} menu={menu} key={menuIndex} />
            ))}
          </List>
        ))}
      </SwipeableViews>
    );
  }
}

export default MenuList;
