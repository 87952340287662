import i18n from 'i18next';

const ReceiveMethodEnum = {
  EATIN: 0,
  TAKEOUT: 1,
  DELIVERY: 2,
  properties: {
    0: { name: i18n.t('MESSAGE_EATIN'), code: 'BS104' },
    1: { name: i18n.t('MESSAGE_TAKEOUT'), code: 'BS103' },
    2: { name: i18n.t('MESSAGE_DELIVERY'), code: 'BS105' },
  },
};

export default ReceiveMethodEnum;
