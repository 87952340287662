import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { AppBar, Toolbar } from '@mui/material';
import StoreList from './StoreList';
import StoreMap from '../common/StoreMap';

@inject('userStore')
@observer
class UserOrderStores extends Component {
  async componentDidMount() {
    const { userStore, match } = this.props;

    // 구매 매장 리스트 조회
    userStore.getUserOrderStores(match.params.encryptedId);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <AppBar position="fixed" color="primary" style={{ boxShadow: 'none' }}>
          <Toolbar style={{ minHeight: '60px' }}>
            {/** 타이틀 */}
            <div className="title" style={{ flexGrow: 1, textAlign: 'center' }}>
              {t('TITLE_ORDER_STORE_LIST')}
            </div>
          </Toolbar>
        </AppBar>

        {/* 구매 매장 리스트 */}
        <StoreList />

        {/* 매장 지도 Modal */}
        <StoreMap />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(UserOrderStores));
