import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import HttpRedirect from 'react-https-redirect';
import App from './App';
import RootStore from './stores';

const rootStore = new RootStore();

ReactDOM.render(
  <Provider {...rootStore}>
    <HttpRedirect disabled={process.env.REACT_APP_NODE_ENV !== 'production'}>
      <App />
    </HttpRedirect>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
