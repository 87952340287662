import StoreMenuGroupModel from './StoreMenuGroupModel';
import StoreBusinessTimeModel from './StoreBusinessTimeModel';
import { observable } from 'mobx';

export default class StoreModel {
  companyId;
  storeCode;
  extStoreCode;
  culture;
  storeName;
  storeCEO;
  storePhone;
  storeAddress1;
  storeAddress2;
  businessNumber;
  tax1_type;
  tax1_percent;
  tax2_type;
  tax2_percent;
  takeoutTaxType;
  eatinTaxType;
  deliveryTaxType;
  useable;
  orderType;
  deliveryFee;
  paymentType;
  isPointUseable;
  useableMinimumPoint;
  merchantID2;
  merchantID;
  extraKey;
  paymentMethod;
  orderMethod;
  baydiamondDelivery;
  businessTimes;
  menuGroups;
  notice;
  @observable smartOrderStatus;
  @observable storeMessage;
  constructor(data) {
    // this.companyId = data.companyId;
    // this.storeCode = data.storeCode;
    // this.culture = data.culture;
    // this.storeName = data.storeName;
    // this.storeCEO = data.storeCEO;
    // this.storePhone = data.storePhone;
    // this.storeAddress1 = data.storeAddress1;
    // this.storeAddress2 = data.storeAddress2;
    // this.businessNumber = data.businessNumber;
    // this.tax1_type = data.tax1_type;
    // this.tax1_percent = data.tax1_percent;
    // this.tax2_type = data.tax2_type;
    // this.tax2_percent = data.tax2_percent;
    // this.takeoutTaxType = data.takeoutTaxType;
    // this.eatinTaxType = data.eatinTaxType;
    // this.deliveryTaxType = data.deliveryTaxType;
    // this.useable = data.useable;
    // this.isPointUseable = data.isPointUseable;
    // this.useableMinimumPoint = data.useableMinimumPoint;
    // this.deliveryFee = data.deliveryFee;
    // this.paymentType = data.paymentType;
    // this.orderType = data.orderType;
    // this.merchantID = data.merchantID;
    // this.serviceID = data.serviceID;
    // this.paymentMethod = data.paymentMethod;
    Object.assign(this, data);
    this.businessTimes =
      data.businessTimes === undefined || data.businessTimes.length === 0
        ? []
        : data.businessTimes.map(businessTime => new StoreBusinessTimeModel(businessTime));
    this.menuGroups =
      data.menuGroups === undefined ? [] : data.menuGroups.map(menuGroup => new StoreMenuGroupModel(menuGroup));
  }
}
