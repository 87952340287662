import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Grid, Button, Checkbox, TextField } from '@mui/material';

@inject('menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class UserInput extends Component {
  // 약관 자세히 보기 버튼 클릭
  handleClickViewDetails = () => {
    this.props.orderStore.setPrivateDialogOpen(true);
  };

  // 전화번호 변경
  handleChangePhoneNumber = event => {
    this.props.userStore.userData.phoneNumber = event.target.value.replace(/\D/g, '');
  };

  // 약관 동의 체크박스 변경
  handleChangeAgree = event => {
    this.props.orderStore.agree = event.target.checked;
  };

  // 주소검색 버튼 클릭
  handleClickFindAddress = () => {
    const { userStore } = this.props;

    /*global daum kakao*/
    new daum.Postcode({
      oncomplete: function (data) {
        userStore.userData.zipCode = data.zonecode;
        userStore.userData.sido = data.sido;
        userStore.userData.sigungu = data.sigungu;
        userStore.userData.dong = data.bname1 !== '' ? data.bname1 : data.bname;
        userStore.userData.jibunAddress = data.jibunAddress;
        userStore.userData.roadAddress = data.roadAddress;

        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.addressSearch(data.roadAddress, function (result, status) {
          if (status === kakao.maps.services.Status.OK) {
            userStore.userData.latitude = Number(result[0].y);
            userStore.userData.longitude = Number(result[0].x);
          }
        });
      },
    }).open();
  };

  // 상세주소 변경
  handleChangeAddressDetail = event => {
    this.props.userStore.userData.addressDetail = event.target.value;
  };

  render() {
    const { t, cartStore, userStore, orderStore } = this.props;
    return (
      <React.Fragment>
        <Grid container direction={'column'} style={{ paddingTop: '20px' }}>
          <Grid item container direction={'column'} spacing={1}>
            <Grid item>
              <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>
                {t('MESSAGE_PHONE_NUMBER')} <span className="price-color">({t('MESSAGE_REQUIRED')})</span>
              </span>
            </Grid>
            <Grid item>
              <TextField
                name="phoneNumber"
                fullWidth
                value={userStore.userData.phoneNumber || '010'}
                type="tel"
                onChange={this.handleChangePhoneNumber}
                variant="outlined"
                helperText={t('MESSAGE_PHONE_NOTI')}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* 주소 */}
        {cartStore.cartData.receiveMethodCode === 2 && (
          <Grid container direction={'column'} spacing={1} style={{ marginTop: '25px' }}>
            <Grid item>
              <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>
                {t('MESSAGE_ADDRESS')} <span className="price-color">({t('MESSAGE_REQUIRED')})</span>
              </span>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  color="secondary"
                  name="zipCode"
                  value={userStore.userData.zipCode}
                  label={t('MESSAGE_ZIP_CODE')}
                  size="small"
                  variant="filled"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  size="large"
                  style={{ height: '100%' }}
                  onClick={this.handleClickFindAddress}
                >
                  {t('BUTTON_SEARCH')}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                color="secondary"
                name="address"
                label={t('MESSAGE_ADDRESS')}
                value={userStore.userData.roadAddress}
                size="small"
                fullWidth
                variant="filled"
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                name="addressDetail"
                label={t('MESSAGE_ADDRESS_DETAIL')}
                value={userStore.userData.addressDetail}
                size="medium"
                fullWidth
                variant="outlined"
                onChange={this.handleChangeAddressDetail}
              />
            </Grid>
          </Grid>
        )}

        {/* 약관 동의 체크박스 */}
        <Grid
          container
          justifyContent={'space-between'}
          direction={'row'}
          style={{ marginTop: '16px' }}
          alignItems={'center'}
        >
          <Grid item>
            <Checkbox
              id="agree"
              edge="start"
              color="primary"
              onChange={this.handleChangeAgree}
              checked={orderStore.agree}
              disableRipple
            />
            <label htmlFor="agree" style={{ fontSize: '11pt' }}>
              {t('MESSAGE_TERMS')} <span className="price-color">({t('MESSAGE_REQUIRED')})</span>
            </label>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={{
                fontSize: '8pt',
                padding: '2px',
                backgroundColor: '#EBEAEA',
                color: '#838383',
              }}
              onClick={this.handleClickViewDetails}
            >
              {t('BUTTON_VIEW_DETAILS')}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation()(UserInput);
