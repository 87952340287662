import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Grid,
  // AppBar,
  Button,
  Toolbar,
  Box,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';

import FooterYokohama from '../common/FooterYokohama';

@inject('menuStore')
@observer
class Bottom extends Component {
  render() {
    const { t, menuStore, handleAddCartMenu } = this.props;
    if (!menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        {/* <AppBar position="fixed" color="primary" className="bottom"> */}
        <div className="bottom" style={{ color: '#FFF' }}>
          <Toolbar variant="dense" style={{ padding: '0' }}>
            <Button
              fullWidth
              color={'inherit'}
              variant={'text'}
              size={'large'}
              onClick={handleAddCartMenu}
              className="bottom-button"
            >
              {/* <Grid container alignItems={'center'} direction={'row'} spacing={0}> */}
              <Grid bgcolor="primary.main" container alignItems={'center'} direction={'row'} spacing={0}>
                <Grid item xs>
                  <span className="bottom-button-title">{t('BUTTON_ADD_CART', { n: menuStore.qty })}</span>
                </Grid>
                <Grid
                  item
                  style={{
                    width: '120px',
                  }}
                >
                  <Box
                    bgcolor="secondary.main"
                    className="bottom-button-price"
                    align={'right'}
                    style={{ lineHeight: '28px' }}
                  >
                    {menuStore.currency}
                    {menuStore.menuTotalPrice.toLocaleString()}
                    {menuStore.storeData.tax1_type === '2' ? (
                      <div style={{ color: '#8C8C8C', fontWeight: 'normal' }}>&nbsp; {t('TITLE_TAX_TYPE')}</div>
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Button>
          </Toolbar>

          <FooterYokohama />
        </div>
        {/* </AppBar> */}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Bottom);
