import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Grid, AppBar, Button, Toolbar } from '@mui/material';
import Progress from '../common/Progress';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class Bottom extends Component {
  // 버튼 클릭
  confirm = async isAgreed => {
    const { history, commonStore, userStore, orderStore } = this.props;

    userStore.userData.isPointAdded = isAgreed;
    userStore.saveUserData();

    // 결제 페이지로 이동
    history.push({
      pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
      state: { isLiteOrder: orderStore.liteOrder ? true : false },
    });
  };

  render() {
    const { t, menuStore, cartStore } = this.props;
    if (!menuStore.storeData || !cartStore.cartData) return <Progress />;
    return (
      <React.Fragment>
        <AppBar position="fixed" color="primary" className="bottom">
          <Toolbar variant="dense" style={{ padding: '0' }}>
            <Grid container direction={'row'}>
              <Grid item xs>
                <Button
                  fullWidth
                  color={'inherit'}
                  variant={'text'}
                  size={'large'}
                  onClick={() => this.confirm(true)}
                  className="bottom-button bottom-button-title"
                >
                  {t('BUTTON_AGREE')}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  color="secondary"
                  variant={'contained'}
                  size={'large'}
                  onClick={() => this.confirm(false)}
                  className="bottom-button bottom-button-title"
                >
                  {t('BUTTON_DISAGREE')}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Bottom));
