import { action, computed, observable, toJS } from 'mobx';
import i18n from 'i18next';

class CartStore {
  constructor(root) {
    this.root = root;
  }

  @observable cartData = {
    companyId: '',
    storeCode: '',
    receiveMethodCode: undefined,
    menus: [],
  }; // 장바구니 데이터
  @observable isDialogOpen = false; // 장바구니 비우기 다이얼로그 오픈 여부

  // 장바구니 메뉴 총 개수
  @computed
  get totalCnt() {
    return this.cartData.menus.map(menu => menu.qty).reduce((accumulator, qty) => accumulator + qty, 0);
  }

  /**
   * 세션 스토리지에서 장바구니 데이터 조회하여 세팅
   */
  @action
  setCartDataFromSessionStorage() {
    if (this.cartData.menus.length === 0) {
      // 세션 스토리지에서 장바구니 데이터 조회
      const sessionCartData = JSON.parse(sessionStorage.getItem('cartData'));

      if (sessionCartData && sessionCartData.menus && sessionCartData.menus.length > 0) {
        const menu = sessionCartData.menus.find(menu => menu.topingGroups);
        if (menu) {
          sessionCartData.menus = [];
          sessionStorage.setItem('cartData', JSON.stringify(sessionCartData));
        }
      }

      // 현재 매장의 장바구니 데이터인 경우에만 세팅
      if (
        sessionCartData &&
        sessionCartData.companyId === this.root.commonStore.companyId &&
        sessionCartData.storeCode === this.root.commonStore.storeCode
      ) {
        this.cartData = sessionCartData;
        if (this.cartData) this.checkCartMenuCnt(this.cartData.menus);
      } else {
        this.cartData.companyId = this.root.commonStore.companyId;
        this.cartData.storeCode = this.root.commonStore.storeCode;
      }
    }
  }

  /**
   * 장바구니에 메뉴 추가
   */
  @action
  async addCartMenu() {
    // 옵션 유효성 검사
    let inValidOptionNames;
    if (this.root.menuStore.menuDetail.toppingGroups && this.root.menuStore.menuDetail.toppingGroups.length > 0) {
      // 미선택 필수 토핑 그룹 이름 조회
      inValidOptionNames = this.root.menuStore.menuDetail.toppingGroups
        .filter(
          toppingGroup =>
            toppingGroup.minCount > 0 &&
            toppingGroup.minCount > toppingGroup.toppings.filter(topping => topping.isChecked).length,
        )
        .map(toppingGroup => toppingGroup.name)
        .reduce(
          (accumulator, currentValue) => (accumulator === '' ? currentValue : accumulator + ', ' + currentValue),
          '',
        );
    }

    if (this.root.menuStore.menuDetail.menuType === 2) {
      // 세트메뉴 그룹 중 메뉴가 1개인 세트메뉴는 자동 선택
      // this.root.menuStore.menuDetail.setMenuGroups
      //   .filter(setMenuGroup => setMenuGroup.setMenus.length === 1)
      //   .forEach(setMenuGroup => {
      //     setMenuGroup.setMenus.forEach(setMenu => (setMenu.isChecked = true));
      //   });

      // 미선택 세트메뉴 그룹 이름 조회
      inValidOptionNames = this.root.menuStore.menuDetail.setMenuGroups
        .filter(setMenuGroup => setMenuGroup.setMenus.filter(setMenu => setMenu.isChecked).length === 0)
        .map(setMenuGroup => setMenuGroup.name)
        .reduce(
          (accumulator, currentValue) => (accumulator === '' ? currentValue : accumulator + ', ' + currentValue),
          '',
        );
    }

    if (inValidOptionNames) {
      this.root.commonStore.setSnackbar(true, `${i18n.t('ALERT_SELECT_OPTION')} (${inValidOptionNames})`);
      return;
    }

    // 메뉴 데이터 복사
    const cartMenu = toJS(this.root.menuStore.menuDetail, { recurseEverything: true });

    // 수량 세팅
    cartMenu.qty = this.root.menuStore.qty;

    // 메뉴 데이터에서 선택한 토핑만 필터링
    cartMenu.toppingGroups = cartMenu.toppingGroups
      .filter(toppingGroup => toppingGroup.toppings.filter(topping => topping.isChecked).length > 0)
      .map(toppingGroup => {
        toppingGroup.toppings = toppingGroup.toppings.filter(topping => topping.isChecked);
        return toppingGroup;
      });

    // 메뉴 데이터에서 선택한 세트메뉴만 필터링
    cartMenu.setMenuGroups = cartMenu.setMenuGroups
      .filter(setMenuGroup => setMenuGroup.setMenus.filter(setMenu => setMenu.isChecked).length > 0)
      .map(setMenuGroup => {
        setMenuGroup.setMenus = setMenuGroup.setMenus.filter(setMenu => setMenu.isChecked);
        return setMenuGroup;
      });

    // 메뉴 한정 수량 체크
    const stockCnt = await this.root.menuStore.getItemLeftCnt(cartMenu.itemCode);
    if (stockCnt && stockCnt.length > 0) cartMenu.stockCnt = stockCnt[0].stockCnt;

    // 필터링 된 메뉴 데이터 장바구니에 추가
    this.cartData.menus.push(cartMenu);

    // 메뉴개수 체크 및 품절 처리
    this.checkCartMenuCnt(this.cartData.menus);

    // 장바구니 저장
    this.setSessionCartData();

    // 장바구니 추가 완료 메시지
    this.root.commonStore.setSnackbar(true, i18n.t('ALERT_ADDED_CART'));
    return true;
  }

  /**
   * 카트 동일메뉴 개수 전체 합계
   */
  @action
  getMenuSumQty(itemCode) {
    if (this.cartData && this.cartData.menus.length > 0) {
      var result = [];
      this.cartData.menus.reduce(function (res, value) {
        if (value.itemCode === itemCode) {
          if (!res[value.itemCode]) {
            res[itemCode] = { itemCode: value.itemCode, qty: 0 };
            result.push(res[value.itemCode]);
          }
          res[itemCode].qty += value.qty;
        }
        return res;
      }, {});
      return result[0]?.qty;
    }
  }

  /**
   * 메뉴 stockCnt 업데이트
   */
  @action
  async updateMenuStockCnt() {
    const itemCodes = this.cartData.menus.map(item => item.itemCode);
    if (itemCodes.length <= 0) return;
    const codes = itemCodes.filter((v, i) => itemCodes.indexOf(v) === i).join(',');
    const orderAvailableCheck = await this.root.menuStore.getItemLeftCnt(codes);

    if (orderAvailableCheck && orderAvailableCheck.length > 0) {
      orderAvailableCheck.forEach(x => {
        let menu = this.cartData.menus.find(y => y.itemCode === x.itemCode);
        if (menu) menu.stockCnt = x.stockCnt;
        if (menu.stockCnt < 0) {
          menu.isSoldOut = true;
        }
      });
    }
  }

  /**
   * 카트 주문시 메뉴개수 체크 및 품절 처리
   */
  @action
  checkCartMenuCnt(menuData, deleteCartMenu, deleteAll) {
    if (!this.root.menuStore?.storeData?.menuGroups) return;

    if (menuData && menuData.length > 0) {
      var result = [];
      menuData.reduce(function (res, value) {
        if (!res[value.itemCode]) {
          res[value.itemCode] = { itemCode: value.itemCode, qty: 0, stockCnt: value.stockCnt };
          result.push(res[value.itemCode]);
        }
        res[value.itemCode].qty += value.qty;
        return res;
      }, {});

      result.forEach(cartMenus => {
        if (deleteCartMenu) {
          if (deleteAll || cartMenus.stockCnt > cartMenus.qty) {
            this.root.menuStore.storeData.menuGroups.forEach(menus => {
              var soldoutMenu = menus.menus.find(menu => menu.itemCode === cartMenus.itemCode);
              if (soldoutMenu && soldoutMenu.isSoldOut) {
                soldoutMenu.isSoldOut = false;
              }
            });
          }
        } else {
          if (cartMenus.stockCnt < cartMenus.qty) {
            this.root.menuStore.storeData.menuGroups.forEach(menus => {
              var soldoutMenu = menus.menus.find(menu => menu.itemCode === cartMenus.itemCode);
              if (soldoutMenu) {
                soldoutMenu.isSoldOut = true;
              }
            });
          }
        }
      });
    } else if (menuData) {
      if (deleteCartMenu) {
        this.root.menuStore.storeData.menuGroups.forEach(menus => {
          var soldoutMenu = menus.menus.find(menu => menu.itemCode === menuData.itemCode);
          if (soldoutMenu && soldoutMenu.isSoldOut) {
            soldoutMenu.isSoldOut = false;
          }
        });
      }
    }
  }

  async checkOrderAvailable() {
    const itemCodes = this.cartData.menus.map(item => item.itemCode).join(',');
    if (!itemCodes) return;
    const orderAvailableCheck = await this.root.menuStore.getItemLeftCnt(itemCodes);
    let itemNames = '';
    if (orderAvailableCheck && orderAvailableCheck.length > 0) {
      orderAvailableCheck.forEach(x => {
        if (this.getMenuSumQty(x.itemCode) > x.stockCnt) {
          itemNames += `,${this.cartData.menus.filter(y => y.itemCode === x.itemCode)[0].name}`;
        }
      });
      return itemNames.substring(1, itemNames.length);
    }
    return '';
  }
  /**
   * 장바구니 비우기 다이얼로그 오픈 여부 설정
   * @param {boolean} isOpen 장바구니 비우기 다이얼로그 오픈 여부
   */
  @action
  setDialogOpen(isOpen) {
    this.isDialogOpen = isOpen;
  }

  /**
   * 장바구니 비우기
   */
  @action
  clearCart() {
    this.checkCartMenuCnt(this.cartData.menus, true, true);
    this.cartData.menus = [];
    console.log(this.cartData.menus);
    //this.setSessionCartData();
    sessionStorage.removeItem('cartData');
  }

  /**
   * 장바구니에서 특정 메뉴 삭제
   * @param {number} idx 메뉴 인덱스
   */
  @action
  removeItemInCart(idx) {
    this.checkCartMenuCnt(this.cartData.menus[idx], true);
    this.cartData.menus.splice(idx, 1);
    this.setSessionCartData();
  }

  /**
   * 장바구니에서 특정 메뉴 수량 증가
   * @param {number} idx 메뉴 인덱스
   */
  @action
  plusItemQtyInCart(idx) {
    this.cartData.menus[idx].qty++;
    const availableCnt = this.cartData.menus[idx]?.stockCnt;
    const menuSumCnt = this.getMenuSumQty(this.cartData.menus[idx].itemCode);
    if (availableCnt === menuSumCnt) this.checkCartMenuCnt(this.cartData.menus);
    if (availableCnt < menuSumCnt) {
      this.root.commonStore.setSnackbar(true, i18n.t('ALERT_MAX_QTY'));
      this.cartData.menus[idx].qty--;
    }
    this.setSessionCartData();
  }

  /**
   * 장바구니에서 특정 메뉴 수량 감소
   * @param {number} idx 메뉴 인덱스
   */
  @action
  minusItemQtyInCart(idx) {
    if (this.cartData.menus[idx].qty > 1) {
      this.cartData.menus[idx]['qty'] = this.cartData.menus[idx].qty - 1;
      this.setSessionCartData();
      if (this.cartData.menus[idx]?.stockCnt > this.getMenuSumQty(this.cartData.menus[idx].itemCode)) {
        this.checkCartMenuCnt(this.cartData.menus, true);
      }
    }
  }

  /**
   * 수령방법 코드 설정
   * @param {number} code 수령방법 ENUM 코드
   */
  @action
  setReceiveMethod(code) {
    this.cartData.receiveMethodCode = code;
    this.setSessionCartData();
  }

  /**
   * 장바구니 데이터 세션 스토리지에 저장
   */
  setSessionCartData() {
    sessionStorage.setItem('cartData', JSON.stringify(this.cartData));
  }

  /**
   * 특정 메뉴의 토핑 이름 '/'로 구분하여 조회
   * @param {number}} idx 메뉴 인덱스
   */
  getToppingNames(idx) {
    if (this.cartData.menus[idx] && this.cartData.menus[idx].toppingGroups) {
      return this.cartData.menus[idx].toppingGroups
        .flatMap(toppingGroup => toppingGroup.toppings)
        .map(topping => topping.name)
        .join(' / ');
    } else {
      return '';
    }
  }

  /**
   * 특정 메뉴의 세트메뉴 이름 '/'로 구분하여 조회
   * @param {number}} idx 메뉴 인덱스
   */
  getSetMenuNames(idx) {
    if (this.cartData.menus[idx] && this.cartData.menus[idx].setMenuGroups) {
      return this.cartData.menus[idx].setMenuGroups
        .flatMap(setMenuGroup => setMenuGroup.setMenus)
        .map(setMenu => setMenu.name)
        .join(' / ');
    } else {
      return '';
    }
  }
}

export default CartStore;
