import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';
import * as React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Dialog, IconButton, Button, AppBar, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../api';

import HeaderYokohama from '../common/HeaderYokohama';
import FooterYokohama from '../common/FooterYokohama';
import PickupWaitTime from '../common/PickupWaitTime';

import notice_content_1 from '../../static/yokohama/images/notice_content_1.png';
import hamamo_top_logo from '../../static/yokohama/logo/hamamo_top_logo.png';
import notice_down_arrow from '../../static/yokohama/images/notice_down_arrow.png';
import notice_step1_img from '../../static/yokohama/images/notice_step1_img.png';
import notice_step2_img from '../../static/yokohama/images/notice_step2_img.png';
import notice_step3_img from '../../static/yokohama/images/notice_step3_img.png';
import Hamamo_Notice_bottom from '../../static/yokohama/images/HaMaMo_Notice_bottom.png';

@inject('commonStore', 'userStore')
@observer
class Top extends Component {
  state = {
    topPageImageUrl: '',
    dialogIsOpen: false,
  };

  async componentDidMount() {
    const _consentToPrivacy = sessionStorage.getItem('consentToPrivacy');
    if (_consentToPrivacy) this.props.commonStore.consentToPrivacy = _consentToPrivacy === 'true' ? true : false;

    // CMS 에서 설정한 이미지
    const imageName = await api.getTopPageImage(this.props.userStore.userData.baydiamondDelivery);
    this.setState({
      topPageImageUrl:
        imageName.data === '' ? '' : `${process.env.REACT_APP_UPSOLUTION_SERVER}/CMSImage/${imageName.data}`,
      dialogIsOpen: imageName.data === '' ? false : true,
    });
  }

  handleOnChecked = e => {
    // 개인정보 제공 동의
    sessionStorage.setItem('consentToPrivacy', e.target.checked);
    this.props.commonStore.consentToPrivacy = e.target.checked;
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  goToMain = () => {
    const { commonStore, history } = this.props;
    // 개인정보 제공에 동의 해야만 다음으로 진행 가능
    if (commonStore.consentToPrivacy) {
      history.push(`/${commonStore.companyId}/${commonStore.storeCode}`);
    } else {
      commonStore.setSnackbar(true, i18n.t('ERROR_PRIVARY_AGREEMENT'));
    }
  };

  render() {
    const { commonStore } = this.props;
    return (
      <React.Fragment>
        <AppBar position="fixed" color="header" style={{ boxShadow: 'none', zIndex: 1 }}>
          <HeaderYokohama />
        </AppBar>
        <Box className="notice-base" style={{ marginTop: '64px', backgroundColor: '#F2F2F2' }}>
          <PickupWaitTime />

          <Grid className="notice-base" style={{ padding: '20px', paddingTop: '50px' }}>
            {/** 모바일오더 안내 */}
            <Grid style={{ backgroundColor: '#FFF', borderRadius: '16px', padding: '40px', textAlign: 'center' }}>
              <div style={{ textAlign: 'center', display: 'inline-block' }}>
                <div
                  className="notice-step-font"
                  style={{
                    borderBottom: 'solid 2px #167BF1',
                    width: '120px',
                    paddingBottom: '7px',
                  }}
                >
                  About
                </div>
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '20px', paddingTop: '10px' }}>
                ハマスタ
                <br />
                モバイルオーダーってなに？
              </div>

              <div style={{ marginTop: '60px', textAlign: 'center' }}>
                <img src={hamamo_top_logo} style={{ width: '230px' }} alt="" />
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '20px', paddingTop: '20px' }}>
                スマホで注文。
                <br />
                スムーズに受け取り！
              </div>
              <div style={{ fontSize: '16px', paddingTop: '15px' }}>
                HaMaMo!（ハマスタモバイルオーダー）は、ハマスタオリジナルフードを、スマホでオーダーして、完成通知を受信後に、受け取りに行くだけの便利なオーダーシステムです。
              </div>
              <div style={{ fontSize: '16px', paddingTop: '15px' }}>
                通知用のメールアドレスと電話番号を用意するだけで、すぐにご利用いただけます。
              </div>
              <div style={{ paddingTop: '20px' }}>
                <img src={notice_content_1} alt="" />
              </div>
            </Grid>
          </Grid>

          <Grid
            className="notice-base"
            style={{ textAlign: 'center', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}
          >
            <div>
              <FormControlLabel
                onChange={this.handleOnChecked}
                control={<Checkbox checked={this.props.commonStore.consentToPrivacy} />}
                label={
                  <div>
                    <a href="https://www.yokohama-stadium.co.jp/mobileorder/term" target="_blank" rel="noreferrer">
                      サービス利用規約
                    </a>
                    <span>および</span>
                    <a href="https://www.yokohama-stadium.co.jp/privacy" target="_blank" rel="noreferrer">
                      プライバシーポリシー
                    </a>
                    に同意して
                  </div>
                }
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button
                onClick={this.goToMain}
                variant="contained"
                style={{
                  width: '295px',
                  borderRadius: '24px',
                  height: '48px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  backgroundColor: '#0066FF',
                }}
              >
                {i18n.t('BUTTON_ORDER_2')}
              </Button>
            </div>
          </Grid>

          {/** 사용방법 */}
          <Grid
            className="notice-base"
            style={{
              textAlign: 'center',
              padding: '20px',
              paddingBottom: '40px',
              marginTop: '40px',

              background: '#E5F0FF',
              flex: 'none',
              order: 0,
              flexGrow: 0,
            }}
          >
            <div style={{ textAlign: 'center', display: 'inline-block' }}>
              <div
                className="notice-step-font"
                style={{
                  borderBottom: 'solid 2px #167BF1',
                  width: '120px',
                  paddingBottom: '7px',
                }}
              >
                How to use
              </div>
            </div>
            <div style={{ fontSize: '20px', paddingTop: '10px', paddingBottom: '40px', fontWeight: 'bold' }}>
              HaMaMo!（ハマスタモバイルオーダー）の使い方
            </div>

            <Grid style={{ backgroundColor: '#FFF', borderRadius: '16px', padding: '40px', textAlign: 'center' }}>
              <div className="notice-step-font" style={{ textAlign: 'left' }}>
                STEP 1
              </div>
              <div style={{ padding: '10px', fontSize: '18px', fontWeight: 'bold' }}>商品を選択する</div>
              <div style={{ padding: '5px' }}>
                <img src={notice_step1_img} alt="" />
              </div>
              <div style={{ fontSize: '16px', color: '#000', textAlign: 'left' }}>商品を選択して、カートに入れます</div>
            </Grid>

            <div style={{ padding: '20px' }}>
              <img src={notice_down_arrow} width="44" alt="" />
            </div>

            <Grid style={{ backgroundColor: '#FFF', borderRadius: '16px', padding: '40px', textAlign: 'center' }}>
              <div className="notice-step-font" style={{ textAlign: 'left' }}>
                STEP 2
              </div>
              <div style={{ padding: '10px', fontSize: '18px', fontWeight: 'bold' }}>
                メールアドレス・電話番号を
                <br />
                登録して、決済！
              </div>
              <div style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <img src={notice_step2_img} width="250" alt="" />
              </div>
              <div style={{ fontSize: '16px', textAlign: 'left' }}>
                通知用メールアドレスと電話番号を登録し、決済方法を選んで購入します
              </div>
            </Grid>

            <div style={{ padding: '20px' }}>
              <img src={notice_down_arrow} width="44" alt="" />
            </div>

            <Grid style={{ backgroundColor: '#FFF', borderRadius: '16px', padding: '40px', textAlign: 'center' }}>
              <div className="notice-step-font" style={{ textAlign: 'left' }}>
                STEP 3
              </div>
              <div style={{ padding: '10px', fontSize: '18px', fontWeight: 'bold' }}>
                完成通知を受信したらお店で
                <br />
                商品を受け取りましょう！
              </div>
              <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <img src={notice_step3_img} alt="" />
              </div>
              <div style={{ fontSize: '16px', textAlign: 'left' }}>
                完成通知が来たら、店頭で番号を見せて商品を受け取りましょう！
              </div>
            </Grid>
          </Grid>

          <Grid className="notice-base" style={{ textAlign: 'center', paddingTop: '40px' }}>
            <div style={{ padding: '20px' }}>
              <FormControlLabel
                onChange={this.handleOnChecked}
                control={<Checkbox checked={this.props.commonStore.consentToPrivacy} />}
                label={
                  <div>
                    <a href="https://www.yokohama-stadium.co.jp/mobileorder/term" target="_blank" rel="noreferrer">
                      サービス利用規約
                    </a>
                    <span>および</span>
                    <a href="https://www.yokohama-stadium.co.jp/privacy" target="_blank" rel="noreferrer">
                      プライバシーポリシー
                    </a>
                    に同意して
                  </div>
                }
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button
                onClick={this.goToMain}
                variant="contained"
                style={{
                  width: '295px',
                  borderRadius: '24px',
                  height: '48px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  backgroundColor: '#0066FF',
                }}
              >
                {i18n.t('BUTTON_ORDER_2')}
              </Button>
            </div>
          </Grid>

          {/** 결제종류 안내 */}
          <Grid style={{ marginTop: '64px', backgroundColor: '#FFF', padding: '40px' }}>
            <div style={{ textAlign: 'center', display: 'inline-block' }}>
              <div
                className="notice-step-font"
                style={{
                  borderBottom: 'solid 2px #167BF1',
                  width: '120px',
                  paddingBottom: '7px',
                }}
              >
                Settlement
              </div>
            </div>
            <div
              style={{
                fontSize: '20px',
                paddingTop: '10px',
                paddingBottom: '40px',
                fontWeight: 'bold',
              }}
            >
              決済方法
            </div>

            <div style={{ fontSize: '16px', color: '#000', textAlign: 'left' }}>
              HaMaMo!（ハマスタモバイルオーダー）では、キャッシュレスでの決済になります。以下の方法で決済が可能です。
            </div>

            <div style={{ paddingTop: '40px' }}>
              <img src={notice_step2_img} width="250" alt="" />
            </div>

            <div style={{ paddingTop: '70px' }}>
              <a
                href={process.env.REACT_APP_SURVEY_URL}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                HaMaMo!利用者アンケート
                <br />
                ご回答にご協力ください
              </a>
            </div>
          </Grid>

          {/**주의사항 */}
          <Grid style={{ padding: '40px', textAlign: 'left' }}>
            <Grid style={{ paddingTop: '30px' }}>【{i18n.t('MESSAGE_NOTES')}】</Grid>
            <Grid>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${commonStore.noticeText.NOTICE}`,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              marginTop: '40px',
              paddingLeft: '20.5px',
              paddingRight: '20.5px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={Hamamo_Notice_bottom} style={{ margin: '0px', padding: '0px' }} alt="" />
          </Grid>

          <FooterYokohama />
        </Box>

        <Dialog
          maxWidth="xl"
          fullWidth={true}
          open={this.state.dialogIsOpen}
          onClose={x => this.setState({ ...x, dialogIsOpen: false })}
        >
          <div style={{ position: 'absolute', right: '0px' }}>
            <IconButton
              aria-label="close"
              onClick={x => this.setState({ ...this.state, dialogIsOpen: !this.state.dialogIsOpen })}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <img
            src={this.state.topPageImageUrl}
            alt=""
            style={{ width: '100%', padding: '0px', margin: '0px', border: '0' }}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}
export default Top;
