import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

@inject('commonStore')
@observer
class StoreMap extends Component {
  async componentDidMount() {}

  componentDidUpdate() {
    /* global kakao */
    const { commonStore } = this.props;
    if (commonStore.isMapOpen) {
      const mapContainer = document.getElementById('map'); // 지도를 표시할 div
      const mapOption = {
        center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
        level: 3, // 지도의 확대 레벨
      };

      // 지도를 생성합니다
      const map = new kakao.maps.Map(mapContainer, mapOption);

      const geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(commonStore.map.storeAddress1, function (result, status) {
        // 정상적으로 검색이 완료됐으면
        if (status === kakao.maps.services.Status.OK) {
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

          // 결과값으로 받은 위치를 마커로 표시합니다
          var marker = new kakao.maps.Marker({
            map: map,
            position: coords,
          });

          // 인포윈도우로 장소에 대한 설명을 표시합니다
          var infowindow = new kakao.maps.InfoWindow({
            content: `<div style="width:150px;text-align:center;padding:6px 0;">${commonStore.map.storeName}</div>`,
          });
          infowindow.open(map, marker);

          // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
          map.setCenter(coords);
        }
      });
    }
  }

  // 지도 닫기
  handleCloseMap = () => {
    const { commonStore } = this.props;
    commonStore.setMapOpen(false);
  };

  render() {
    const { commonStore, t } = this.props;
    return commonStore.isMapOpen ? (
      <div id="modal">
        <div className="modal-container">
          <div className="modal-title">
            {commonStore.map.storeName}
            {/** 메뉴 아이콘 버튼 */}
            <IconButton
              edge="end"
              color="inherit"
              onClick={this.handleCloseMap}
              style={{ position: 'absolute', right: '16px', padding: '0' }}
              size="large">
              <CloseIcon fontSize={'large'} />
            </IconButton>
          </div>
          <ul className="modal-contents">
            <li>
              <div className="label">{t('MESSAGE_STORE_TEL')}</div>
              <div className="content">{commonStore.map.storePhone}</div>
            </li>
            <li>
              <div className="label">{t('MESSAGE_ADDRESS')}</div>
              <div className="content">
                {commonStore.map.storeAddress1} {commonStore.map.storeAddress2}
              </div>
            </li>
          </ul>
          <div className="modal-map">
            <div id="map"></div>
          </div>
        </div>
        <div className="dim" onClick={this.handleCloseMap}></div>
      </div>
    ) : null;
  }
}

export default withTranslation()(StoreMap);
