import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Container, Grid, Typography, Link, List, ListItem } from '@mui/material';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';
import Header from '../common/Header';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';
import OrderStep from './OrderStep';
import Topping from './Topping';
import SetMenu from './SetMenu';
import Bottom from './Bottom';
import moment from 'moment-timezone';
import api from '../../api';
import i18next from 'i18next';
import QRCode from 'qrcode.react';
import PaymentMethodEnum from '../../enums/PaymentMethodEnum';

@inject('commonStore', 'orderStore', 'menuStore', 'userStore')
@observer
class OrderDetail extends Component {
  state = {
    isLiteOrder: false,
  };

  async componentDidMount() {
    const { commonStore, orderStore, menuStore, userStore, match } = this.props;

    const companyId = match.params.companyId;
    const storeCode = match.params.storeCode;
    const orderId = match.params.orderId;

    // 주문 조회
    // if (!orderStore.order) {
    try {
      const response = await api.getSale(companyId, storeCode, orderId, i18next.languages[0]);

      orderStore.setOrder(response.data);

      // 결제 완료 주문리스트 로컬스토리지 저장
      if (!userStore.userData.email) await userStore.setUserDataFromLocalStorage();
      if (!userStore.userData.orderList.some(x => x.orderId === response.data.orderId)) {
        response.data.orderDiscrimination = match.params.orderId;
        userStore.userData.orderList.push(response.data);
        userStore.saveUserData();
      }
    } catch (error) {
      commonStore.setError(error);
      throw error;
    }
    // }

    // 현재 조회 된 상점 데이터가 없거나 (카톡 링크로 접속한 경우나 세션스토리지 데이터가 삭제된 경우)
    // 조회한 주문의 상점과 현재 조회된 상점이 다른 경우
    // 매장 데이터 다시 조회
    if (
      !menuStore.storeData ||
      orderStore.order.companyId !== menuStore.storeData.companyId ||
      orderStore.order.storeCode !== menuStore.storeData.storeCode
    ) {
      commonStore.setData(orderStore.order.companyId, orderStore.order.storeCode);
    }

    // 주문 상태 갱신 (5초)
    const interval = setInterval(async () => await orderStore.setOrderStatus(companyId, storeCode, orderId), 5000);
    orderStore.setStatusCheckInterval(interval);

    this.setState(() => ({ isLiteOrder: !this.props.orderStore.order.extOrderNo }));
  }

  componentWillUnmount() {
    clearInterval(this.props.orderStore.statusCheckInterval);
  }

  // 매장위치
  handleClickMap = () => {
    const { commonStore } = this.props;
    // 지도 Modal 오픈
    commonStore.setMapOpen(true);
  };

  render() {
    const { userStore, orderStore, menuStore, t } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    if (!orderStore.order || !menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        {/* 상세페이지 링크토 들어온 경우 헤더에 타이틀만 사용 */}
        {query.get('link') === 'kakao' ? (
          <AppBar position="fixed" color="primary" style={{ boxShadow: 'none' }}>
            <Toolbar style={{ minHeight: '60px' }}>
              {/** 타이틀 */}
              <Typography align="center" style={{ flexGrow: 1 }}>
                {t('TITLE_ORDER_DETAILS')}
              </Typography>
            </Toolbar>
          </AppBar>
        ) : (
          <Header iconType="CART" title={t('TITLE_ORDER_DETAILS')} />
        )}

        <div className="contents-full" style={{ marginTop: 62 }}>
          <div
            className={`order-detail-top clear-fix ${
              userStore.userData.baydiamondDelivery === '1' ? 'order-detail-top-delivery' : ''
            }`}
          >
            <div className="wait-number">
              {t('MESSAGE_ORDER_NUMBER_2')} {orderStore.order.waitNum}
            </div>
            <div className="order-date">
              {moment(orderStore.order.regDate).tz('Asia/tokyo').format('YYYY-MM-DD HH:mm:ss')}
            </div>
          </div>

          {/* 현재 주문 단계 표시 */}
          <OrderStep />

          <div style={{ color: 'red', textAlign: 'center', fontSize: '20px', fontWeight: 'bold', paddingTop: '10px' }}>
            注文状況が自動で切り替わります
          </div>

          <div
            style={{
              textAlign: 'center',
              fontSize: '34px',
              fontWeight: 'bold',
              paddingTop: '10px',
              textDecoration: 'underline',
            }}
          >
            {orderStore.order.currentStatusCode === 2 && '調理中'}
            {orderStore.order.currentStatusCode === 3 && userStore.userData.baydiamondDelivery === '0' && '店舗へGO！'}
            {orderStore.order.currentStatusCode === 3 &&
              userStore.userData.baydiamondDelivery === '1' &&
              'お席でお待ちください。'}
            {orderStore.order.currentStatusCode === 4 && 'THANK YOU !!'}
            {orderStore.order.currentStatusCode === 5 && '取り消し済み'}
          </div>

          {orderStore.order.currentStatusCode === 4 && (
            <div style={{ textAlign: 'center', fontSize: '20px', paddingBottom: '25px' }}>
              <a
                href={process.env.REACT_APP_SURVEY_URL}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none', fontSize: '20px' }}
              >
                HaMaMo!利用者アンケート
                <br />
                ご回答にご協力ください
              </a>
            </div>
          )}

          <Container style={{ padding: '30px', paddingBottom: '0px' }}>
            <Grid container spacing={2}>
              <Grid item container direction="row">
                <Grid item>
                  <b>{t('MESSAGE_ORDER_NUMBER_2')}</b>
                </Grid>
                <Grid item xs style={{ fontSize: '40px', textAlign: 'right' }}>
                  <b>{orderStore.order.waitNum}</b>
                </Grid>
              </Grid>

              <Grid item container direction="row" style={{ paddingTop: '0px' }}>
                <Grid item>
                  <b>{t('MESSAGE_ORDER_TIME_2')}</b>
                </Grid>
                <Grid item xs style={{ textAlign: 'right' }}>
                  {moment(orderStore.order.regDate).tz('Asia/tokyo').format('YYYY-MM-DD HH:mm:ss')}
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container>
            <div className="order-detail-box">
              <div className="box-title">{t('MESSAGE_ORDER_INFO')}</div>
              <div className="box-contents">
                <Grid container spacing={2}>
                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_RECEIPT_NUMBER')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">{orderStore.order.receiptNum}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_STORE_NAME_2')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">{orderStore.order.storeName}</Typography>
                    </Grid>
                  </Grid>

                  {userStore.userData.baydiamondDelivery === '0' && (
                    <Grid item container direction="row" style={{ padding: '5px', margin: '0px' }}>
                      <Grid item xs style={{ padding: '0px', margin: '0px' }}>
                        <Typography align="right" style={{ fontSize: '12px' }}>
                          <Link onClick={this.handleClickMap} underline="none" color="primary">
                            {t('BUTTON_STORE_MAP')}
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_HOW_PICKUP')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">
                        {ReceiveMethodEnum.properties[orderStore.order.receiveMethodCode].name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_PAYMENT_AMOUNT_2')}</b>
                    </Grid>
                    <Grid item xs container direction="column">
                      {orderStore.order.payments.map((payment, index) => (
                        <Grid item container direction="row" key={index}>
                          <Grid item xs align="right">
                            {PaymentMethodEnum.properties[payment.methodCode]}
                          </Grid>
                          <Grid item xs>
                            <Typography align="right">
                              {menuStore.currency}
                              {Number(payment.amt).toLocaleString()}
                              {menuStore.storeData.tax1_type === '2' ? (
                                <span style={{ color: '#8C8C8C', fontWeight: 'normal', fontSize: '12px' }}>
                                  &nbsp;{t('TITLE_TAX_TYPE')}
                                </span>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {userStore.userData.baydiamondDelivery === '1' && (
                    <Grid item container direction="row">
                      <Grid item>
                        <b>{t('MESSAGE_SEAT_INFO')}</b>
                      </Grid>
                      <Grid item xs>
                        <Typography align="right" style={{ fontSize: '14px' }}>{`${orderStore.order.zone} SIDE （${
                          orderStore.order.zone === 'BAY' ? t('MESSAGE_ZONE_BAY') : t('MESSAGE_ZONE_STAR')
                        }） ${orderStore.order.block}段 ${orderStore.order.seat}番`}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>

            {orderStore.order.userCardNumber.length > 0 && (
              <div className="order-detail-box">
                <div className="box-title">BlueMates{t('MESSAGE_CUSTOMER_INFO2')}</div>
                <div className="box-contents">
                  <Grid container>
                    <Grid item container direction="row">
                      <Grid item>
                        <b>BAYSTARS ID</b>
                      </Grid>
                      <Grid item xs>
                        <Typography align="right">{orderStore.order.userCardNumber}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" style={{ fontSize: '14px' }}>
                      <div style={{ marginTop: '8px' }}>
                        <font className="price-color">※ </font>
                        STARは注文された翌日に付与されます。マイページ上でポイント付与をご確認ください
                      </div>

                      <div style={{ marginTop: '8px' }}>
                        <font className="price-color">※ </font>
                        BlueMatesロイヤリティプログラムの対象試合は、原則、クライマックスシリーズ・日本シリーズを除く横浜スタジアム主催セ・リーグ試合となります。その他の対象については、横浜DeNA公式HPのファンクラブ関連ニュースでご確認ください（ロイヤリティプログラムについては
                        <a
                          href="https://www.baystars.co.jp/news/2024/01/0131_03.php"
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontWeight: 'bold' }}
                        >
                          コチラ
                        </a>
                        からご確認ください）
                      </div>

                      <div style={{ marginTop: '8px' }}>
                        <font className="price-color">※ </font>
                        本ページが購入明細となります。必要に応じて、スクリーンショット等で大切に保管ください
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            <div className="order-detail-box">
              <div className="box-title">{t('MESSAGE_PRODUCT_INFO')}</div>
              <div className="box-contents" style={{ paddingTop: 0 }}>
                <List className="menu-list">
                  {orderStore.order.menus.map((menu, index) => (
                    <ListItem className="menu-detail" key={index}>
                      {/* 메뉴 */}
                      <Grid container>
                        <Grid item container direction="row">
                          <Grid item xs style={{ paddingRight: '5px' }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
                              {menu.itemCode === '099983' ? t('MESSAGE_DELIVERY_FEE') : menu.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={1}>
                            <Typography align="center">
                              <b>x{menu.qty}</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography align="right">
                              {menuStore.currency}
                              {(menu.supplyPrice + menu.tax + menu.tax1).toLocaleString()}
                              {menuStore.storeData.tax1_type === '2' ? (
                                <span style={{ color: '#8C8C8C', fontWeight: 'normal', fontSize: '12px' }}>
                                  &nbsp;{t('TITLE_TAX_TYPE')}
                                </span>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* 토핑 */}
                        {menu.toppingGroups && menu.toppingGroups.length > 0 && (
                          <Topping toppingGroups={menu.toppingGroups} />
                        )}

                        {/* 세트 메뉴 */}
                        {menu.setMenuGroups && menu.setMenuGroups.length > 0 && (
                          <SetMenu setMenuGroups={menu.setMenuGroups} />
                        )}
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                <Grid container direction="row" style={{ paddingTop: '16px' }}>
                  <Grid item>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('MESSAGE_TOTAL_PAYMENT_AMOUNT')}</span>
                  </Grid>
                  <Grid item xs>
                    <div className="price-color" style={{ fontSize: '16pt', textAlign: 'right' }}>
                      <b>
                        {menuStore.currency}
                        {Number(orderStore.order.price).toLocaleString()}
                        {menuStore.storeData.tax1_type === '2' ? (
                          <span style={{ color: '#8C8C8C', fontWeight: 'normal', fontSize: '14px' }}>
                            &nbsp;{t('TITLE_TAX_TYPE')}
                          </span>
                        ) : (
                          ''
                        )}
                      </b>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

            {orderStore.order.lockerQR && (
              <div className="order-detail-box">
                <div className="box-title" style={{ textAlign: 'center' }}>
                  Smart Locker QR
                </div>
                <div className="box-contents" style={{ padding: '7px 0', textAlign: 'center' }}>
                  <QRCode
                    value={orderStore.order.lockerQR}
                    renderAs="svg"
                    includeMargin="true"
                    style={{ width: '80%', height: '80%' }}
                  />
                </div>
              </div>
            )}
          </Container>
        </div>

        {/* 확인 버튼 (마장동 주문일떈 버튼 숨김)*/}
        {this.state.isLiteOrder && <Bottom mainLink={`/${orderStore.order.companyId}/${orderStore.order.storeCode}`} />}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(OrderDetail));
