import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Progress from '../common/Progress';
import Header from '../common/Header';
import MenuInfo from './MenuInfo';
import Topping from './Topping';
import SetMenu from './SetMenu';
import Bottom from './Bottom';
import i18n from 'i18next';

@inject('commonStore', 'menuStore', 'cartStore')
@observer
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemCntCheck: false,
      stockCnt: 0,
    };
  }

  async getMenuStockCnt() {
    const { menuStore, match } = this.props;
    return await menuStore.getItemLeftCnt(match.params.itemCode);
  }

  async componentDidMount() {
    const { menuStore, commonStore, match } = this.props;

    commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);
    menuStore.setMenuDetail(match.params.groupCode, match.params.itemCode);
    const stockCnt = await this.getMenuStockCnt();

    if (stockCnt && stockCnt.length > 0)
      this.setState(() => {
        return {
          stockCnt: stockCnt[0].stockCnt,
        };
      });
  }

  handleAddCartMenu = async () => {
    const { commonStore, cartStore, history, match, menuStore } = this.props;
    const stockCnt = await this.getMenuStockCnt();
    const cartMenuCnt = cartStore.getMenuSumQty(match.params.itemCode);
    if (stockCnt[0].stockCnt - (cartMenuCnt ?? 0) < menuStore.qty) {
      if (stockCnt[0].stockCnt - (cartMenuCnt ?? 0) <= 0) {
        this.props.commonStore.setSnackbar(true, i18n.t('MESSAGE_ITEM_PREPARING'));
        return;
      }
      this.props.commonStore.setSnackbar(
        true,
        i18n.t('ALERT_EXCEED_OPTION', { n: stockCnt[0].stockCnt - (cartMenuCnt ?? 0) }),
      );
      return;
    }
    const isSuccess = await cartStore.addCartMenu();
    if (isSuccess) history.push(`/${commonStore.companyId}/${commonStore.storeCode}`);
  };

  render() {
    const { menuStore } = this.props;
    if (!menuStore.storeData || !menuStore.menuGroupCode || !menuStore.menuItemCode) return <Progress />;
    return (
      <React.Fragment>
        <Header page="CART" title={menuStore.menuDetail.name} showWaitingTime={true} />

        <div className="contents">
          {/* 메뉴 기본 정보 */}
          <MenuInfo menu={menuStore.menuDetail} stockCnt={this.state.stockCnt} />

          {/* 토핑 */}
          {menuStore.menuDetail.toppingGroups && menuStore.menuDetail.toppingGroups.length > 0 && (
            <Topping toppingGroups={menuStore.menuDetail.toppingGroups} />
          )}

          {/* 세트메뉴 */}
          {menuStore.menuDetail.setMenuGroups && menuStore.menuDetail.setMenuGroups.length > 0 && (
            <SetMenu setMenuGroups={menuStore.menuDetail.setMenuGroups} />
          )}
        </div>

        {/* 장바구니 버튼 */}
        <Bottom
          handleAddCartMenu={this.handleAddCartMenu}
          disabled={this.state.stockCnt <= 0 || menuStore.qty > this.state.stockCnt}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Menu);
