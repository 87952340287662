import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { withTranslation } from 'react-i18next';
import liff from '@line/liff/dist/lib';
import Bottom from './Bottom';
import api from '../../api';
// import OrderRequest from '../order/OrderRequest';
import MemberInfo from '../order/MemberInfo';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import paypay from '../../static/yokohama/images/howtopaypay.png';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class PaymentSoftBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPayPayPopup: false,
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    const { orderStore } = this.props;

    // 결제 방법 기본값을 카드로 설정
    if (!orderStore.paymentMethod) {
      orderStore.setPaymentMethod('credit');
    }
  }

  checkEmail = () => {
    const { userStore, t } = this.props;
    const regex = /^[a-zA-Z0-9/\-/+/./_]+@(?:[a-zA-Z0-9\-/]+\.)+[A-Za-z]+$/;
    if (userStore.userData.email && regex.test(userStore.userData.email)) {
      return true;
    }
    this.props.commonStore.setSnackbar(true, t('ERROR_EMAIL_INPUT'));
    return false;
  };

  checkPhoneNum = () => {
    const { userStore, t } = this.props;
    if (userStore.userData.phoneNumber.length >= 10) return true;

    this.props.commonStore.setSnackbar(true, t('ERROR_PHONE_INPUT'));
    return false;
  };

  checkStockCnt = async () => {
    const { cartStore, commonStore, t } = this.props;
    // 품절 메뉴 체크
    const checkSoldout = await cartStore.checkOrderAvailable();
    if (checkSoldout !== '') {
      commonStore.setSnackbar(true, `[${checkSoldout}] ${t('ERROR_SOLD_OUT_PRODUCT')}`);
      return false;
    }
    return true;
  };

  checkUserCardNumber = () => {
    const { userStore, t } = this.props;
    if (userStore.userData.userCardNumber.length === 0 || userStore.userData.userCardNumber.length === 10) return true;

    this.props.commonStore.setSnackbar(true, t('ERROR_CUSTOMER_CARDNUM_VALIDATION'));

    return false;
  };

  checkZoneInfo = () => {
    const { userStore, commonStore, t } = this.props;

    if (userStore.userData.baydiamondDelivery === '1') {
      if (
        !userStore.userData.zone ||
        !userStore.userData.block ||
        !userStore.userData.seat ||
        userStore.userData.zone.length === 0 ||
        userStore.userData.block.length === 0 ||
        userStore.userData.seat.length === 0
      ) {
        commonStore.setSnackbar(true, t('MESSAGE_ZONE_INFO_REQUIRED'));
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  // 결제방법 변경
  handlePayMethodChange = event => {
    this.props.orderStore.setPaymentMethod(event.target.value);
  };

  pay = async () => {
    const { commonStore, menuStore, cartStore, userStore, orderStore } = this.props;

    try {
      if (!this.checkEmail()) return;
      if (!this.checkPhoneNum()) return;
      if (!(await this.checkStockCnt())) return;
      if (!this.checkUserCardNumber()) return;
      if (!this.checkZoneInfo()) return;
      userStore.saveUserData();

      if (liff.isInClient()) {
        const lineProfile = await liff.getProfile();
        userStore.userData.phoneNumber = lineProfile.userId;
      }

      // Yokohama 의 경우 take out 만 사용함.
      cartStore.setReceiveMethod(ReceiveMethodEnum.TAKEOUT);

      // 주문 임시저장 및 주문번호 생성
      const response = await api.reserveOrder(orderStore.orderData);

      // 결제 데이터 세팅
      const baseUrl = `${
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
      }`;

      //--------------------- 해시코드 생성 시 모든 결제 데이터를 사용하기 때문에 결제 데이터 추가/변경 시 서버도 같이 변경이 필요함 ----------------------------//
      // cust_code: `${menuStore.storeData.merchantID}_${menuStore.storeData.extraKey}_${userStore.userData.phoneNumber}`,
      orderStore.setPaymentSBData({
        pay_method: orderStore.paymentMethod,
        merchant_id: menuStore.storeData.merchantID,
        service_id: menuStore.storeData.extraKey,
        cust_code: `${menuStore.storeData.merchantID}_${menuStore.storeData.extraKey}_${userStore.userData.phoneNumber}`,
        order_id: response.data.orderId,
        item_id: cartStore.cartData.menus[0].itemCode,
        amount: orderStore.requestAmount,
        pay_type: '0',
        service_type: '0',
        // success_url: `${
        //   process.env.REACT_APP_API_SERVER
        // }/sales/softbank/success?companyId=${commonStore.companyId.toLowerCase()}&storeCode=${commonStore.storeCode}`,
        success_url: `${baseUrl}/api/sales/softbank/success?companyId=${commonStore.companyId.toLowerCase()}&storeCode=${
          commonStore.storeCode
        }`,
        cancel_url: `${baseUrl}/${commonStore.companyId}/${commonStore.storeCode}/payment`,
        error_url: `${baseUrl}/errors/payment`,
        // pagecon_url: `${process.env.REACT_APP_API_SERVER}/pay/softbank`,
        pagecon_url: `${baseUrl}/api/pay/softbank`,
        free1: commonStore.companyId,
        free2: commonStore.storeCode,
        request_date: response.data.ediDate + response.data.ediTime,
        hashCode: response.data.signData,
      });
      // console.log(orderStore.paymentSBData);
      this.formRef.submit();
    } catch (error) {
      commonStore.setError(error);
      throw error;
    } finally {
      // 결제 버튼 활성화
      orderStore.setDisablePaymentButton(false);
    }

    return true;
  };

  render() {
    const { t, menuStore, orderStore } = this.props;
    return (
      <React.Fragment>
        {/* <div className="contents" style={{ paddingTop: '52px' }}> */}
        <div style={{ paddingTop: '52px' }}>
          {/* 이메일 / 회원번호 */}
          <MemberInfo />

          <Grid item container direction={'column'} spacing={1} style={{ marginTop: '25px' }}>
            {/* 요청사항 */}
            {/* <OrderRequest /> */}
          </Grid>
          {/* 결제방법 선택 */}
          <Grid item container direction={'column'} spacing={1} style={{ marginTop: '25px', marginBottom: '25px' }}>
            <Grid item>
              <span style={{ fontSize: '13pt', fontWeight: 'bold' }}>{t('MESSAGE_PAYMENT_METHOD')}</span>
            </Grid>
            <Grid item>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={orderStore.paymentMethod}
                onChange={this.handlePayMethodChange}
              >
                {(menuStore.storeData.paymentMethod & 1) === 1 && (
                  <FormControlLabel value="credit" control={<Radio color="primary" />} label={t('MESSAGE_CARD')} />
                )}
                {(menuStore.storeData.paymentMethod & 2) === 2 && (
                  <FormControlLabel value="paypay" control={<Radio color="primary" />} label={t('MESSAGE_PAYPAY')} />
                )}
                {(menuStore.storeData.paymentMethod & 4) === 4 && (
                  <FormControlLabel value="aupay" control={<Radio color="primary" />} label={'au PAY'} />
                )}
              </RadioGroup>
            </Grid>

            <Grid item>
              ※ PayPayでの決済方法・注意事項について、
              <a
                href="#!"
                rel="noreferrer"
                onClick={() => {
                  this.setState({ isShowPayPayPopup: true });
                }}
              >
                コチラ
              </a>
              からご確認ください。
            </Grid>
          </Grid>
        </div>
        {orderStore.paymentSBData && (
          <form
            ref={ref => (this.formRef = ref)}
            name="payForm"
            method="post"
            action={`${process.env.REACT_APP_SOFTBANK_SERVER}/f01/FepBuyInfoReceive.do`}
            acceptCharset="Shift-JIS"
          >
            {/* (옵션) 결제방법 (콤마 구분자로 복수 지정 가능. 생략하는 경우 결제 방법 선택 화면) */}
            <input type="hidden" name="pay_method" value={orderStore.paymentSBData.pay_method} />
            {/* (필수) 상점ID */}
            <input type="hidden" name="merchant_id" value={orderStore.paymentSBData.merchant_id} />
            {/* (필수) 서비스ID */}
            <input type="hidden" name="service_id" value={orderStore.paymentSBData.service_id} />
            {/* (필수) 고객ID */}
            <input type="hidden" name="cust_code" value={orderStore.paymentSBData.cust_code} />
            {/* (필수) 주문ID */}
            <input type="hidden" name="order_id" value={orderStore.paymentSBData.order_id} />
            {/* (필수) 상품ID */}
            <input type="hidden" name="item_id" value={orderStore.paymentSBData.item_id} />
            {/* (옵션) 상품명 */}
            {/* <input type="hidden" name="item_name" value={orderStore.paymentSBData.item_name} /> */}
            {/* (옵션) 세금 */}
            {/* <input type="hidden" name="tax" value={orderStore.pay_method} /> */}
            {/* (필수) 구매 금액 (세금포함) */}
            <input type="hidden" name="amount" value={orderStore.paymentSBData.amount} />
            {/* (필수) 구입타입 */}
            <input type="hidden" name="pay_type" value={orderStore.paymentSBData.pay_type} />
            {/* (필수) 서비스타입 */}
            <input type="hidden" name="service_type" value={orderStore.paymentSBData.service_type} />
            {/* (필수) 결제완료 URL */}
            <input type="hidden" name="success_url" value={orderStore.paymentSBData.success_url} />
            {/* (필수) 결제취소 URL */}
            <input type="hidden" name="cancel_url" value={orderStore.paymentSBData.cancel_url} />
            {/* (필수) 결제오류 URL */}
            <input type="hidden" name="error_url" value={orderStore.paymentSBData.error_url} />
            {/* (필수) 등록통지처CGI. SSL필수 (결제성공 시 결제완료 처리할 URL?) */}
            <input type="hidden" name="pagecon_url" value={orderStore.paymentSBData.pagecon_url} />
            <input type="hidden" name="free1" value={orderStore.paymentSBData.free1} />
            <input type="hidden" name="free2" value={orderStore.paymentSBData.free2} />
            {/* (필수) 요청일시(YYYYMMDDHHMISS) */}
            <input type="hidden" name="request_date" value={orderStore.paymentSBData.request_date} />
            {/* (옵션) 요청허용시간 */}
            {/* <input type="hidden" name="limit_second" value={orderStore.paymentSBData.limit_second} /> */}
            {/* (필수) 체크섬 */}
            <input type="hidden" name="sps_hashcode" value={orderStore.paymentSBData.hashCode} />
          </form>
        )}

        <Bottom pay={this.pay} />

        <Dialog
          open={this.state.isShowPayPayPopup}
          fullWidth
          maxWidth="xl"
          onClose={() => {
            this.setState({ isShowPayPayPopup: false });
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar variant="dense">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.setState({ isShowPayPayPopup: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <img src={paypay} style={{ width: '100%' }} alt="" />
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PaymentSoftBank);
