import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ListItem, Typography, Grid } from '@mui/material';
import noImage from '../../static/images/No_Image.png';
import mapIcon from '../../static/images/map_icon.png';
import callIcon from '../../static/images/call_icon.png';
import preparingIcon from '../../static/images/preparing_icon.png';

@inject('commonStore', 'userStore')
@observer
class StoreItem extends Component {
  // 주문하기
  handleOrder = async (companyId, storeCode) => {
    document.location.href = `/${companyId}/${storeCode}`;
  };

  // 전화하기
  handleClickCall = storePhone => {
    document.location.href = `tel:${storePhone}`;
  };

  // 지도 보기
  handleClickMap = store => {
    const { commonStore } = this.props;

    // 매장 선택
    commonStore.setMap(store.storeName, store.storeAddress1, store.storeAddress2, store.storePhone);

    // 지도 Modal 오픈
    commonStore.setMapOpen(true);
  };

  render() {
    const { store, t } = this.props;
    const storeImageStyle = {
      backgroundImage: `url(${process.env.REACT_APP_UPSOLUTION_SERVER}/PosImage/${store.companyId}/${store.storeCode}/Settings/${store.logoFileName}), url(${noImage})`,
    };

    return (
      <ListItem disableGutters>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            className="store-info"
            onClick={() => this.handleOrder(store.companyId, store.storeCode)}
          >
            <Grid item className="store-logo" style={storeImageStyle}>
              {!store.isOpen && (
                <React.Fragment>
                  <div className="store-preparing">
                    <img src={preparingIcon} alt={t('MESSAGE_PREPARING_BUSINESS')} />
                    <br />
                    {t('MESSAGE_PREPARING_BUSINESS')}
                  </div>
                  <div className="dim"></div>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs className="store-detail">
              <Typography variant="subtitle1" className="store-name">
                {store.storeName}
              </Typography>
              <Typography variant="body2" className="store-address">
                {store.storeAddress1} {store.storeAddress2}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" className="store-button-container">
            <Grid item xs className="store-button" onClick={() => this.handleClickCall(store.storePhone)}>
              <img src={callIcon} alt={t('BUTTON_TELEPHONE')} /> {t('BUTTON_TELEPHONE')}
            </Grid>
            <Grid item xs className="store-button" onClick={() => this.handleClickMap(store)}>
              <img src={mapIcon} alt={t('BUTTON_STORE_LOCATION')} /> {t('BUTTON_STORE_LOCATION')}
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  }
}

export default withTranslation()(StoreItem);
