import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Divider, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FooterYokohama from '../common/FooterYokohama';
import Header from '../common/Header';
import OrderCompleteList from './OrderCompleteList';

@inject('commonStore', 'userStore')
@observer
class OrderLookup extends Component {
  state = {
    orderList: [],
  };

  async componentDidMount() {
    const { commonStore, match, userStore } = this.props;
    commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);

    if (!userStore.userData.email) await userStore.setUserDataFromLocalStorage();
    // 결제 완료 주문리스트 로컬스토리지 저장
    userStore.removeExpirationOrderList();
    userStore.saveUserData();

    this.setState(() => {
      return { orderList: userStore.userData.orderList };
    });
  }

  handleGoToMenuList = () => {
    const { match, history } = this.props;
    history.push(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}`);
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Header page="orderLookup" title={t('TITLE_ORDER_LIST')} />
        <div className="contents-full" style={{ paddingTop: '120px', paddingBottom: '30px' }}>
          <Divider variant={'fullWidth'} />
          {this.state.orderList.length > 0 ? (
            <OrderCompleteList orderList={this.state.orderList} />
          ) : (
            <Typography align="center" style={{ paddingTop: '20px' }}>
              {t('ALERT_NO_ORDER_HISTORY')}
            </Typography>
          )}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button variant="contained" size="large" fullWidth onClick={this.handleGoToMenuList}>
            {t('BUTTON_GOTO_MENULIST')}
          </Button>
        </div>
        <FooterYokohama />
      </React.Fragment>
    );
  }
}
export default withTranslation()(withRouter(OrderLookup));
