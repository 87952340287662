import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, List, ListItem } from '@mui/material';
import { withTranslation } from 'react-i18next';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';

@inject('menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class LiteMenuList extends Component {
  render() {
    const { t, menuStore, cartStore, userStore, orderStore } = this.props;
    return (
      <React.Fragment>
        <div className="order-detail-box">
          <div className="box-title">{t('MESSAGE_PRODUCT_INFO')}</div>
          <div className="box-contents" style={{ paddingTop: 0 }}>
            <List className="menu-list">
              {orderStore.liteOrder.menus.map((menu, index) => (
                <ListItem className="menu-detail" key={index}>
                  {/* 메뉴 */}
                  <Grid container>
                    <Grid item container direction="row">
                      <Grid item xs style={{ paddingRight: '5px' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{menu.name}</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          <b>x{menu.qty}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography align="right">
                          {menuStore.currency}
                          {menu.amt.toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* 토핑 */}
                    {menu.toppingGroups && menu.toppingGroups.length > 0 && (
                      <Grid item container direction="column">
                        {menu.toppingGroups.map(
                          (toppingGroup, toppingGroupIndex) =>
                            toppingGroup.toppings &&
                            toppingGroup.toppings.map((topping, toppingIndex) => (
                              <Grid item container direction="row" key={toppingIndex}>
                                <Grid item xs>
                                  <Typography style={{ fontSize: '12px', color: '#9D9B9B' }}>
                                    {`${toppingGroup.name} (${topping.name})`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="right" style={{ fontSize: '12px' }}>
                                    + {menuStore.currency}
                                    {topping.addPrice}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )),
                        )}
                      </Grid>
                    )}

                    {/* 세트 메뉴 */}
                    {menu.setMenuGroups && menu.setMenuGroups.length > 0 && (
                      <Grid item container direction="column">
                        {menu.setMenuGroups.map(
                          (setMenuGroup, setMenuGroupIndex) =>
                            setMenuGroup.setMenus &&
                            setMenuGroup.setMenus.map((setMenu, setMenuIndex) => (
                              <Grid item container direction="row" key={setMenuIndex}>
                                <Grid item xs>
                                  <Typography style={{ fontSize: '8pt', color: '#9D9B9B' }}>
                                    {`${setMenuGroup.name} (${setMenu.name})`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="right" style={{ fontSize: '8pt', color: '#9D9B9B' }}>
                                    + {menuStore.currency}
                                    {setMenu.addPrice.toLocaleString()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )),
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
              {cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY && (
                <ListItem className="menu-detail">
                  {/* 메뉴 */}
                  <Grid container>
                    <Grid item container direction="row">
                      <Grid item xs style={{ paddingRight: '5px' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          {t('MESSAGE_DELIVERY_FEE')}
                        </Typography>
                      </Grid>

                      {/* <Grid item xs={1}>
                          <Typography align="center">
                            <b>x{menu.qty}</b>
                          </Typography>
                        </Grid> */}
                      <Grid item xs={4}>
                        <Typography align="right">
                          {menuStore.currency}
                          {Number(menuStore.storeData.deliveryFee).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              )}
              {orderStore.usePoint > 0 && userStore.userPoint >= menuStore.storeData.useableMinimumPoint && (
                <ListItem className="menu-detail">
                  {/* 메뉴 */}
                  <Grid container>
                    <Grid item container direction="row">
                      <Grid item xs style={{ paddingRight: '5px' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('MESSAGE_POINTS')}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography align="right">
                          - {menuStore.currency}
                          {Number(orderStore.usePoint).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </List>
            <Grid container direction="row" style={{ paddingTop: '16px' }}>
              <Grid item>
                <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('MESSAGE_TOTAL_PAYMENT_AMOUNT')}</span>
              </Grid>
              <Grid item xs>
                <div className="price-color" style={{ fontSize: '16pt', textAlign: 'right' }}>
                  <b>
                    {menuStore.currency}
                    {orderStore.requestAmount.toLocaleString()}
                  </b>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(LiteMenuList));
