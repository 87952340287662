import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { CssBaseline, Fab, Badge, Box, AppBar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';
import MenuList from './MenuList';
import Category from './Category';
import PickupWaitTime from '../common/PickupWaitTime';

import HeaderYokohama from '../common/HeaderYokohama';
import FooterYokohama from '../common/FooterYokohama';

const styles = theme => ({
  customBadge: {
    backgroundColor: '#FFD307',
    color: '#000',
  },
});

@inject('commonStore', 'menuStore', 'cartStore', 'userStore')
@observer
class Main extends Component {
  async componentDidMount() {
    const { commonStore, match, history } = this.props;

    if (history.length === 0) {
      history.push(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}`);
    }

    await commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);
  }

  // 장바구니
  handleClickCart = () => {
    const { commonStore, cartStore, t } = this.props;

    if (!cartStore.cartData.menus.length === 0) {
      commonStore.setSnackbar(true, t('ALERT_EMPTY_CART'));
      return;
    }

    this.props.history.push(`/${commonStore.companyId}/${commonStore.storeCode}/cart`);
  };

  render() {
    const { menuStore, cartStore, classes, t } = this.props;
    if (!menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" color="header" style={{ boxShadow: 'none', zIndex: 1 }}>
          {/** 요코하마용 헤더 */}
          <HeaderYokohama />

          {/** 상품수령 대기시간 */}
          <PickupWaitTime />

          {/** 메뉴 카테고리 */}
          <Category />
        </AppBar>

        <Box className="contents-main">
          <Box className="menu-container">
            {/* 메뉴 리스트 */}
            {!menuStore.filterdMenuGroups || menuStore.filterdMenuGroups.length === 0 ? (
              <Typography align="center" style={{ paddingTop: '20px' }}>
                {t('ALERT_EMPTY_MENU')}
              </Typography>
            ) : (
              <MenuList />
            )}

            {/* 장바구니 버튼 */}
            {cartStore.totalCnt > 0 && (
              <Fab color="primary" variant="extended" onClick={this.handleClickCart} className="button-order">
                <Badge badgeContent={cartStore.totalCnt} classes={{ badge: classes.customBadge }}>
                  <span className="bottom-button-title">{t('BUTTON_ORDER_2')}</span>
                </Badge>
              </Fab>
            )}
          </Box>

          <FooterYokohama />
        </Box>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(withStyles(styles)(Main)));
