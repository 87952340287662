import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Badge } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import cart from '../../static/images/cart.png';
import del from '../../static/images/del.png';
import PickupWaitTime from './PickupWaitTime';

import HeaderYokohama from './HeaderYokohama';

const styles = () => ({
  customBadge: {
    backgroundColor: '#FFD307',
    color: '#000',
    top: '25px !important',
  },
});

@inject('commonStore', 'cartStore', 'orderStore', 'userStore')
@observer
class Header extends Component {
  handleClickBack = () => {
    const { history, isGoHome, commonStore } = this.props;
    const referrer = document.referrer;

    // 리퍼러가 UP스마트오더 사이트가 아닌 경우 (PG사이트 이동 등) 홈페이지로 이동
    if (
      referrer &&
      referrer.indexOf('so.upsolution.co.kr') === -1 &&
      referrer.indexOf('localhost') === -1 &&
      commonStore.companyId &&
      commonStore.storeCode
    ) {
      window.location.href = `/${commonStore.companyId}/${commonStore.storeCode}`;
    } else if (isGoHome) {
      window.location.href = `/${commonStore.companyId}/${commonStore.storeCode}`;
    } else {
      history.goBack();
    }
  };

  handleClickCart = () => {
    const { commonStore, history } = this.props;
    history.push(`/${commonStore.companyId}/${commonStore.storeCode}/cart`);
  };

  handleClickCartClear = () => {
    this.props.cartStore.setDialogOpen(true);
  };

  // 우측 상단 아이콘
  renderIcon = () => {
    const { iconType, cartStore, orderStore, classes } = this.props;
    if (orderStore?.order?.extOrderNo) return undefined;

    switch (iconType) {
      case 'CART_CLEAR':
        return (
          <IconButton edge="end" color="inherit" onClick={this.handleClickCartClear} size="large">
            <img src={del} height="28" alt="장바구니 전체 비우기" />
          </IconButton>
        );
      case 'CART':
        return (
          <IconButton edge="end" color="inherit" onClick={this.handleClickCart} size="large">
            <Badge badgeContent={cartStore.totalCnt} classes={{ badge: classes.customBadge }}>
              <img src={cart} height="28" alt="장바구니" />
            </Badge>
          </IconButton>
        );
      default:
        return undefined;
    }
  };

  render() {
    const { title, userStore } = this.props;
    // 마장동 주문일땐 홈 화면 접근 불가
    return (
      <AppBar
        position="fixed"
        color="primary"
        className={
          userStore.userData.baydiamondDelivery === '0'
            ? 'yokohama-header-background'
            : 'yokohama-header-background-delivery'
        }
        style={{ boxShadow: 'none' }}
      >
        <HeaderYokohama />

        {/** 상품수령 대기시간 */}
        {this.props.showWaitingTime && <PickupWaitTime />}

        <Toolbar style={{ minHeight: '60px' }}>
          {/** 타이틀 */}
          <div className="title" style={{ flexGrow: 1, textAlign: 'center' }}>
            {title}
          </div>

          {/** 장바구니 페이지인 경우 전체 삭제 버튼 아닌경우 장바구니 아이콘 */}
          {/* {this.renderIcon()} */}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(Header));
