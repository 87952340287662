import { AppBar, CssBaseline, IconButton, Toolbar } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Carousel } from 'react-responsive-carousel';
import CloseIcon from '@mui/icons-material/Close';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import STEP1 from '../../static/images/guide_step1.png';
import STEP2 from '../../static/images/guide_step2.png';
import STEP3 from '../../static/images/guide_step3.png';
import STEP4 from '../../static/images/guide_step4.png';
import STEP5 from '../../static/images/guide_step5.png';
import STEP6 from '../../static/images/guide_step6.png';
import STEP7 from '../../static/images/guide_step7.png';
import STEP8 from '../../static/images/guide_step8.png';

class UserGuide extends Component {
  handleClickClose = () => {
    this.props.history.goBack();
  };

  handleSwipe = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" color="primary" style={{ boxShadow: 'none', zIndex: 1 }}>
          <Toolbar style={{ minHeight: '60px' }}>
            {/* 매장명 */}
            <div className="title" style={{ flexGrow: 1, textAlign: 'center' }}>
              {t('TITLE_USER_GUIDE')}
            </div>

            {/** 메뉴 아이콘 버튼 */}
            <IconButton
              edge="end"
              color="inherit"
              onClick={this.handleClickClose}
              style={{ position: 'absolute', right: '10px' }}
              size="large">
              <CloseIcon fontSize={'large'} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div id="user-guide">
          <Carousel showArrows={true} showThumbs={false} dynamicHeight={true} onChange={this.handleSwipe}>
            <div>
              <img src={STEP1} alt={'step1'} />
            </div>
            <div>
              <img src={STEP2} alt={'step2'} />
            </div>
            <div>
              <img src={STEP3} alt={'step3'} />
            </div>
            <div>
              <img src={STEP4} alt={'step4'} />
            </div>
            <div>
              <img src={STEP5} alt={'step5'} />
            </div>
            <div>
              <img src={STEP6} alt={'step6'} />
            </div>
            <div>
              <img src={STEP7} alt={'step7'} />
            </div>
            <div>
              <img src={STEP8} alt={'step8'} />
            </div>
          </Carousel>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(UserGuide));
