import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@mui/material';

@inject('cartStore')
@observer
class ReceiveMethodButtonGroup extends Component {
  render() {
    const { cartStore, t } = this.props;
    return (
      <ButtonGroup
        className="custom-botton-group"
        color="primary"
        fullWidth={true}
        orientation="horizontal"
        variant="outlined"
        style={{ padding: '16px 10px 0px' }}
      >
        <Button
          onClick={() => cartStore.setReceiveMethod(1)}
          variant={cartStore.cartData.receiveMethodCode === 1 ? 'contained' : 'outlined'}
        >
          {t('MESSAGE_TAKEOUT')}
        </Button>
        <Button
          onClick={() => cartStore.setReceiveMethod(2)}
          variant={cartStore.cartData.receiveMethodCode === 2 ? 'contained' : 'outlined'}
        >
          {t('MESSAGE_DELIVERY')}
        </Button>
      </ButtonGroup>
    );
  }
}

export default withTranslation()(ReceiveMethodButtonGroup);
