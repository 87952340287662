export default class UserOrderStoreModel {
  companyId;
  storeCode;
  storeName;
  storePhone;
  storeAddress1;
  storeAddress2;
  logoFileName;
  isOpen;

  constructor(data) {
    Object.assign(this, data);
  }
}
