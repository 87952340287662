import StoreToppingModel from './StoreToppingModel';

export default class StoreToppingGroupModel {
  groupCode;
  name;
  minCount;
  maxCount;
  toppings;

  constructor(data) {
    Object.assign(this, data);
    this.toppings = data.toppings.map(topping => new StoreToppingModel(topping));
  }
}
