import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import {
  Main,
  Menu,
  Cart,
  Order,
  LiteOrder,
  Payment,
  OrderComplete,
  OrderDetail,
  UserOrderStores,
  UserGuide,
  Terms,
  Privacy,
  ServiceUnavailable,
  Join,
  Top,
  TopDelivery,
} from './components';
import i18n from 'i18next';
import history from './History';
import Snackbar from './components/common/CustomSnackbar';
import liff from '@line/liff/dist/lib';
import ScrollToTop from './ScrollToTop';
import PaymentError from './components/errors/PaymentError';
import Preparing from './components/errors/Preparing';
import QrOrder from './components/qr-order/QrOrder';
import Waiting from './components/errors/Waiting';
import OrderLookup from './components/orderComplete/OrderLookup';
import ErrorBoundary from './components/errors/ErrorBoundary';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore')
@observer
class App extends Component {
  async componentDidMount() {
    /**
     * localhost
     * dev.upsolution.co.kr
     * dev.mobileorder.yokohama-stadium.co.jp
     * mobileorder.yokohama-stadium.co.jp
     * dev.baydiamond.mobileorder.yokohama-stadium.co.jp
     * baydiamond.mobileorder.yokohama-stadium.co.jp
     */
    const hostname = window.location.hostname;
    let companyId = '';
    let storeCode = '';

    switch (hostname) {
      case 'localhost':
        // take out 매장
        companyId = 'yokohama01';
        storeCode = '1002'; // 일반매장
        // storeCode = '1003'; // 배달매장
        break;
      case 'dev.upsolution.co.kr':
        companyId = 'yokohama01';
        storeCode = '1002'; // 일반매장
        // storeCode = '1003'; // 배달매장
        break;
      case 'dev.mobileorder.yokohama-stadium.co.jp':
        companyId = 'ys1013';
        storeCode = '1001';
        break;
      case 'mobileorder.yokohama-stadium.co.jp':
        companyId = 'ys0100';
        storeCode = '1021';
        break;
      case 'dev.baydiamond.mobileorder.yokohama-stadium.co.jp': // 아직 모름
        companyId = 'ys3000';
        storeCode = '1001';
        break;
      case 'baydiamond.mobileorder.yokohama-stadium.co.jp': // 아직 모름
        companyId = 'ys0300';
        storeCode = '1007';
        break;
      default:
        break;
    }

    // 매장이 각 유형별로 하나인 것을 전제로 매장코드 없이 들어오면 무조건 매장코드를 설정한다.
    await this.props.commonStore.setData(companyId, storeCode);

    /** 로컬스토리지에 매장 유형 저장 */
    await this.props.userStore.setUserDataFromLocalStorage();
    this.props.userStore.userData.baydiamondDelivery = this.props.commonStore.baydiamondDelivery;
    this.props.userStore.saveUserData();

    // fovicon / 모바일기기 바로가기 만들때의 명칭 정의
    if (this.props.userStore.userData.baydiamondDelivery === '1') {
      let icon1 = document.querySelector("link[rel~='apple-touch-icon']");
      let icon2 = document.querySelector("link[rel~='icon']");
      let description = document.querySelector("meta[name~='description']");
      let title = document.querySelector('title');

      document.head.removeChild(icon1);
      document.head.removeChild(icon2);
      document.head.removeChild(description);
      document.head.removeChild(title);

      icon1 = document.createElement('link');
      icon1.rel = 'apple-touch-icon';
      icon1.href = '/logo_160.png';
      document.getElementsByTagName('head')[0].appendChild(icon1);

      icon2 = document.createElement('link');
      icon2.rel = 'shortcut icon';
      icon2.href = '/logo_160.png';
      document.getElementsByTagName('head')[0].appendChild(icon2);

      description = document.createElement('meta');
      description.name = 'description';
      description.content = 'ハマスタモバイルオーダー HaMaMo!';
      document.getElementsByTagName('head')[0].appendChild(description);

      title = document.createElement('title');
      title.text = 'ハマスタモバイルオーダー HaMaMo!';
      document.getElementsByTagName('head')[0].appendChild(title);
    }

    if (liff.isInClient()) {
      liff.init({ liffId: process.env.REACT_APP_LINE_LIFF_ID });
    }
  }

  render() {
    const theme = createTheme({
      //shadows: Array(25).fill('none'),
      typography: {
        fontSize: 15,
        fontFamily: [
          i18n.languages[0] === 'ja'
            ? 'Verdana, "Meiryo UI", "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif'
            : 'Verdana, "Nanum Gothic", Dotum, sans-serif',
        ].join(','),
        subtitle1: { fontWeight: 'bold' },
        button: { textTransform: 'none' },
      },
      palette: {
        primary: {
          main: this.props.userStore.userData.baydiamondDelivery === '0' ? '#0066FF' : '#c4bc7c',
          contrastText: '#fff',
        },
        secondary: {
          main: '#3d3f4c',
          contrastText: '#fff',
        },
        text: {
          main: '#000',
          secondary: '#949494',
        },
        indicator: {
          height: 4,
        },
        header: {
          main: '#F2F2F2',
          contrastText: '#333',
        },
      },
    });

    // if (this.props.commonStore.error) {
    //   throw new Error(this.props.commonStore.error);
    // }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <CssBaseline />
            <Router history={history}>
              <ScrollToTop>
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={
                      this.props.userStore.userData.baydiamondDelivery === ''
                        ? undefined
                        : this.props.userStore.userData.baydiamondDelivery === '0'
                        ? Top
                        : TopDelivery
                    }
                  />
                  <Route exact path="/Top/:companyId/:storeCode/:zone/:block/:seat" component={TopDelivery} />

                  <Route exact path="/:companyId/:storeCode/menu-groups/:groupCode/menus/:itemCode" component={Menu} />
                  <Route exact path="/:companyId/:storeCode/cart" component={Cart} />
                  <Route exact path="/:companyId/:storeCode/order" component={Order} />
                  <Route exact path="/:companyId/:storeCode/lite-order" component={LiteOrder} />
                  <Route exact path="/:companyId/:storeCode/lite-orders/:orderId/complete" component={OrderComplete} />
                  <Route exact path="/:companyId/:storeCode/lite-orders/:orderId" component={OrderDetail} />
                  <Route exact path="/:companyId/:storeCode/join" component={Join} />
                  <Route exact path="/:companyId/:storeCode/payment" component={Payment} />
                  <Route exact path="/:companyId/:storeCode/orderLookup" component={OrderLookup} />
                  {/* 나이스페이 주문완료 */}
                  <Route exact path="/:companyId/:storeCode/orders/:orderId/complete" component={OrderComplete} />
                  {/* 소프트뱅크페이 주문완료 */}
                  <Route exact path="/:companyId/:storeCode/order-complete" component={OrderComplete} />
                  <Route exact path="/:companyId/:storeCode/orders/:orderId" component={OrderDetail} />
                  <Route exact path="/:companyId/:storeCode/errors/payment" component={PaymentError} />
                  <Route exact path="/errors/payment" component={PaymentError} />
                  <Route exact path="/:companyId/:storeCode/errors/preparing" component={Preparing} />
                  <Route exact path="/:companyId/:storeCode/user-guide" component={UserGuide} />
                  <Route exact path="/users/:encryptedId/order-stores" component={UserOrderStores} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/service-unavailable" component={ServiceUnavailable} />
                  <Route exact path="/:companyId/:storeCode/service-wait" component={Waiting} />
                  <Route exact path="/qr-order/:id" component={QrOrder} />
                  <Route exact path="/:companyId/:storeCode" component={Main} />

                  <Route
                    render={() => {
                      throw new Error(i18n.t('ERROR_NOT_FOUND_PAGE'));
                    }}
                  />
                </Switch>
                {/** 공통 컴포넌트 */}
                <Snackbar />
              </ScrollToTop>
            </Router>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
