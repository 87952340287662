import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import ServiceImage from '../../static/images/ServiceUnavailable.png';

class ServiceUnavailable extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="service-container">
        <div className="item">
          <img src={ServiceImage} alt="서비스를 이용할 수 없습니다." />
          <p className="title">{t('ERROR_SERVICE_UNAVAILABLE')}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ServiceUnavailable));
