import { action, computed, observable } from 'mobx';
import StoreModel from '../models/StoreModel';
import defaultImage from '../static/images/defaultImage.png';
import i18n from 'i18next';
import ReceiveMethodEnum from '../enums/ReceiveMethodEnum';
import moment from 'moment-timezone';
import api from '../api';

class MenuStore {
  constructor(root) {
    this.root = root;
  }

  @observable storeData; // 상점 및 메뉴 데이터
  @observable menuGroupIndex = 0; // 메뉴 그룹 탭의 현재 인덱스
  @observable menuGroupCode = ''; // 선택한 메뉴의 메뉴 그룹 코드
  @observable menuItemCode = ''; // 선택한 메뉴의 메뉴 아이템 코드
  @observable qty = 1; // 메뉴 수량
  @observable filterdMenuGroups; //그룹 사용 시간 필터 데이터

  // 메뉴 데이터에서 선택한 메뉴의 상세 데이터
  @computed
  get menuDetail() {
    let menu = {};

    if (this.storeData) {
      // 메뉴 그룹 조회
      const menuGroup = this.storeData.menuGroups.find(menuGroup => menuGroup.groupCode === this.menuGroupCode);
      if (!menuGroup) throw new Error(i18n.t('ERROR_NOT_FOUND_MENU'));

      // 메뉴 조회
      menu = menuGroup.menus.find(menu => menu.itemCode === this.menuItemCode);
      if (!menu) throw new Error(i18n.t('ERROR_NOT_FOUND_MENU'));

      // 메뉴가 속한 매장코드와 메뉴 그룹코드 세팅
      menu.storeCode = menuGroup.storeCode;
      menu.groupCode = menuGroup.groupCode;

      // 이전 선택한 토핑이 있다면 초기화
      if (menu.toppingGroups && menu.toppingGroups.length > 0) {
        menu.toppingGroups
          .flatMap(toppingGroup => toppingGroup.toppings)
          .forEach(topping => (topping.isChecked = topping.isSelect && !topping.isSoldOut));
      }

      // 이전 선택한 세트메뉴가 있다면 초기화
      if (menu.setMenuGroups && menu.setMenuGroups.length > 0) {
        menu.setMenuGroups
          .flatMap(setMenuGroup => setMenuGroup.setMenus)
          .forEach(setMenu => (setMenu.isChecked = false));
      }
    }
    return menu;
  }

  // 선택된 메뉴의 전체 가격
  @computed
  get menuTotalPrice() {
    return this.calcTaxMenuPrice(this.menuDetail, this.qty);
  }

  // 상점 문화권별 금액 기호
  @computed
  get currency() {
    if (this.storeData && this.storeData.culture) {
      switch (this.storeData.culture) {
        case 'ko-KR':
          return '₩';
        case 'en-US':
          return '$';
        case 'ja-JP':
          return '¥';
        case 'zh-CN':
          return '¥';
        case 'th-TH':
          return '฿';
        default:
          return '₩';
      }
    } else {
      return '₩';
    }
  }

  // 매장 오픈 여부
  @computed
  get isOpenStore() {
    let isOpen = false;
    if (this.storeData) {
      // 시간과 요일 조회
      const currentTime = Number(moment().tz('Asia/tokyo').format('HHmm'));
      const currentWeek = Number(moment().tz('Asia/tokyo').format('e')); // 0~6, 일~토
      const yesterdayWeek = Number(moment().tz('Asia/tokyo').subtract(1, 'days').format('e'));

      // 오늘과 어제 운영시간 조회
      const today = this.storeData.businessTimes[currentWeek];
      const yesterday = this.storeData.businessTimes[yesterdayWeek];

      let yesterdayEndTime = Number(yesterday.endTime);
      let todayStartTime = Number(today.startTime);
      let todayEndTime = Number(today.endTime);

      // 어제가 휴무이거나 당일 종료인 경우 어제 종료시간을 0으로 세팅해서 현재시간보다 무조건 작거나 같게 만듬
      if (yesterday.isClosed === '1' || yesterday.endTime > yesterday.startTime) yesterdayEndTime = 0;

      if (today.isClosed === '1') {
        // 오늘이 휴무인 경우 오늘 종료시간을 0으로 세팅해서 현재시간보다 무조건 작거나 같게 만듬
        todayEndTime = 0;
      } else if (today.endTime <= today.startTime) {
        // 오늘 영업시간이 익일 종료인 경우 종료시간을 2400으로 세팅해서 현재시간보다 무조건 크게 만듬
        todayEndTime = 2400;
      }

      if (currentTime < yesterdayEndTime || (currentTime >= todayStartTime && currentTime < todayEndTime)) {
        isOpen = true;
      }
    }

    return isOpen;
  }

  // 현재 선택한 수령방법별 경감세금 사용 여부
  @computed
  get isReduceTax() {
    if (
      this.storeData.culture === 'ja-JP' &&
      ((this.root.cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.EATIN &&
        this.storeData.eatinTaxType === '1') ||
        (this.root.cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.TAKEOUT &&
          this.storeData.takeoutTaxType === '1') ||
        (this.root.cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY &&
          this.storeData.deliveryTaxType === '1'))
    ) {
      return true;
    }
    return false;
  }

  // 데이터 조회 및 세팅
  @action
  async setStoreData() {
    // 상점 및 메뉴 데이터 조회
    if (!this.storeData) {
      try {
        if (!this.root.commonStore.companyId || !this.root.commonStore.storeCode) {
          throw new Error(i18n.t('ERROR_NOT_FOUND_STORE'));
        }

        const result = await api.getStore(
          this.root.commonStore.companyId,
          this.root.commonStore.storeCode,
          i18n.languages[0],
        );

        // 매장 존재 여부 검사
        if (result.status && result.status === 404) {
          throw new Error(i18n.t('ERROR_NOT_FOUND_STORE'));
        }

        // 매장 조회 데이터 파싱
        this.storeData = new StoreModel(result.data);

        if (this.storeData) {
          // 스마트오더 상태 (영업종료, 주문수령 대기 시간)
          const status = await api.getSmartOrderState(this.root.commonStore.companyId, this.root.commonStore.storeCode);
          this.storeData.smartOrderStatus = status.data;
        }

        // 수령방법을 첫 번쨰로 자동 선택
        if ((this.storeData.orderType & 1) === 1) {
          this.root.cartStore.cartData.receiveMethodCode = ReceiveMethodEnum.EATIN;
        } else if ((this.storeData.orderType & 2) === 2) {
          this.root.cartStore.cartData.receiveMethodCode = ReceiveMethodEnum.TAKEOUT;
        } else if ((this.storeData.orderType & 4) === 4) {
          this.root.cartStore.cartData.receiveMethodCode = ReceiveMethodEnum.DELIVERY;
        }

        // Manifest 파일 업데이트
        this.updateManifest(this.root.commonStore.companyId, this.root.commonStore.storeCode);
      } catch (error) {
        this.root.commonStore.setError(error);
        throw error;
      }
    }
  }

  // 카테고리 인덱스 변경
  @action
  setMenuGroupIndex(index) {
    this.menuGroupIndex = index;
  }

  // 메뉴 그룹 코드 변경
  @action
  setMenuGroupCode(groupCode) {
    this.menuGroupCode = groupCode;
  }

  // 메뉴 상세 페이지 시작할 때 값 세팅
  @action
  setMenuDetail(groupCode, itemCode) {
    this.menuGroupCode = groupCode;
    this.menuItemCode = itemCode;
    this.qty = 1;
  }

  // 토핑이 클릭될 경우 호출된다.
  @action
  setTopping(toppingGroupCode, toppingCode, isChecked, isSoldOut) {
    // 토핑 그룹 조회
    const toppingGroup = this.menuDetail.toppingGroups.find(
      toppingGroup => toppingGroup.groupCode === toppingGroupCode,
    );

    if (isChecked) {
      // 토핑 선택 해제
      toppingGroup.toppings.find(topping => topping.toppingCode === toppingCode).isChecked = false;
    } else {
      // 토핑 선택 하는 경우 토핑 개수 체크
      const selectedCount = toppingGroup.toppings.filter(topping => topping.isChecked === true).length;
      if ((toppingGroup.maxCount > 0 && toppingGroup.maxCount > selectedCount) || toppingGroup.maxCount === 0) {
        toppingGroup.toppings.find(topping => topping.toppingCode === toppingCode).isChecked = true;
      } else {
        this.root.commonStore.setSnackbar(true, i18n.t('ALERT_EXCEED_OPTION', { n: toppingGroup.maxCount }));
      }
    }
  }

  // 세트메뉴가 클릭될 경우 호출된다.
  @action
  setSetMenu(setMenuGroupCode, setMenuCode) {
    // 해당하는 세트메뉴 그룹 조회
    const setMenuGroup = this.menuDetail.setMenuGroups.find(
      setMenuGroup => setMenuGroup.groupCode === setMenuGroupCode,
    );

    // 세트메뉴 그룹 내에 선택한 모든 세트메뉴 해제
    setMenuGroup.setMenus.forEach(setMenu => {
      setMenu.isChecked = false;
    });

    // 세트메뉴 선택
    setMenuGroup.setMenus.find(setMenu => setMenu.itemCode === setMenuCode).isChecked = true;
  }

  // 선태된 아이템의 갯수를 증가할때 호출
  @action
  plusQty() {
    this.qty++;
  }

  // 선택된 아이템의 갯수를 감소할때 호출
  @action
  minusQty() {
    if (this.qty > 1) {
      this.qty--;
    }
  }

  // 메뉴 그룹 시간 설정 체크
  @action
  filterMenuGroups() {
    this.filterdMenuGroups = this.storeData.menuGroups.filter(menuGroup => {
      const currentTime = moment().tz('Asia/tokyo').format('HHmm');
      if (menuGroup && menuGroup.isUseTime !== '1') return true;
      if (
        menuGroup &&
        menuGroup.isUseTime === '1' &&
        menuGroup.startUseTime <= currentTime &&
        menuGroup.endUseTime >= currentTime
      ) {
        return true;
      }
      return false;
    });
  }

  // manifest.json 파일 업데이트
  updateManifest(companyId, storeCode) {
    if (companyId && storeCode) {
      var myDynamicManifest = {
        // name: `Up스마트오더 - ${this.storeData.storeName}`,
        // short_name: `${this.storeData.storeName}`,
        name: 'ハマスタモバイルオーダー HaMaMo!',
        short_name: 'ハマスタモバイルオーダー HaMaMo!',
        start_url: `${
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '')
        }/${companyId}/${storeCode}`,
        display: 'standalone',
        background_color: '#000000',
        theme_color: '#ffffff',
        icons: [
          {
            src: 'logo_180.png',
            sizes: '180x180',
            type: 'image/png',
          },
        ],
      };
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
    }
  }

  // 메뉴 이미지 URL 조회
  getImageUrl(itemStoreCode, pictureFileName1, pictureFileName2) {
    let imageUrl = defaultImage;
    if (pictureFileName1) {
      imageUrl = `${process.env.REACT_APP_UPSOLUTION_SERVER}/PosImage/${this.storeData.companyId}/${itemStoreCode}/BasicSetting/MenuKiosk/${pictureFileName1}`;
    } else if (pictureFileName2) {
      imageUrl = `${process.env.REACT_APP_UPSOLUTION_SERVER}/PosImage/${this.storeData.companyId}/${itemStoreCode}/BasicSetting/Menu/${pictureFileName2}`;
    }
    return imageUrl;
  }

  // 메뉴 금액 조회
  getMenuPrice(menu) {
    // 매장이 경감세금으로 설정되어 있고 메뉴에서 경감세금 사용 중이며 메뉴에 경감세금 단가가 설정되어 있는경우 경감세금 단가 사용
    if (this.isReduceTax && menu.isTax2 === '1' && menu.rdPrice > 0) {
      return menu.rdPrice;
    }

    return menu.price;
  }

  // 토핑 금액 조회
  getToppingPrice(topping) {
    // 매장이 경감세금으로 설정되어 있고 토핑이 경감세금 추가 단가가 설정되어 있으면 경감세금 추가 단가 사용
    if (this.isReduceTax && topping.addRdPrice > 0) {
      return topping.addRdPrice;
    }

    return topping.addPrice;
  }

  // 세트메뉴 추가 금액 계산 (키오스크의 경우 세트메뉴의 포장하기 추가 금액만 사용하기 때문에 동일하게 설정)
  calcSetMenuAddPrice(setMenu) {
    // 일본 문화권인 경우 수령방법별 추가금액을 사용하지 않고 경감세금 단가를 사용한다.
    if (this.storeData.culture === 'ja-JP') {
      // 일본 문화권, 포장하기에 경감세금으로 설정, 경감세금 추가 단가가 설정되어 있는 경우 경감세금 추가 단가 사용
      if (this.storeData.takeoutTaxType === '1' && setMenu.addRdPrice > 0) {
        return setMenu.addRdPrice;
      }
    } else if (setMenu.takeOutAddPrice > 0) {
      // 일본 매장이 아닌 경우 포장하기 추가 단가 사용
      return setMenu.takeOutAddPrice;
    }

    return setMenu.addPrice;
  }

  // 메뉴 총 가격 계산 (세금합산)
  calcTaxMenuPrice(menu, qty) {
    if (!this.storeData || !menu) return 0;

    const menuPrice = this.getMenuPrice(menu); // 메뉴 단가

    // 세트메뉴 추가 가격
    const setMenuAddPrice = menu.setMenuGroups
      .flatMap(setMenuGroup => setMenuGroup.setMenus)
      .filter(setMenu => setMenu.isChecked === true)
      .map(setMenu => this.calcSetMenuAddPrice(setMenu))
      .reduce((accumulator, addPrice) => accumulator + addPrice, 0);

    // 메뉴 총 가격
    let menuTotalPrice = (menuPrice + setMenuAddPrice) * qty;

    // 세금 계산 변수
    let taxType = this.storeData.tax1_type;
    let taxPercent = this.storeData.tax1_percent;
    let isTax = menu.isTax1;

    // 경감 세금인 경우 변수 값 변경
    if (this.isReduceTax && menu.isTax2 === '1') {
      taxType = this.storeData.tax2_type;
      taxPercent = this.storeData.tax2_percent;
      isTax = menu.isTax2;
    }

    // 세금 별도인 경우에만 메뉴 총 금액에 세금 합산
    let taxAmount = 0;
    if (taxType === '1' && isTax === '1') {
      taxAmount = menuTotalPrice - Math.ceil(menuTotalPrice / ((parseFloat(taxPercent.toString()) + 100) / 100.0));
    }

    return menuTotalPrice + taxAmount;
  }

  async getItemLeftCnt(itemCode) {
    if (!this.storeData) await this.setStoreData();

    const salesQty = await api.getSalesItemQty(
      this.root.commonStore.companyId,
      this.root.commonStore.storeCode,
      itemCode,
    );

    let itemCntData;
    if (salesQty && salesQty.data.length > 0) {
      itemCntData = salesQty.data;
      itemCntData.forEach(item => {
        this.storeData.menuGroups.forEach(menuGroup => {
          let menu = menuGroup.menus.find(menu => menu.itemCode === item.itemCode);
          if (menu) {
            menu.stockCnt = item.stockCnt;
            menu.stockWaitingTime = item.stockWaitingTime;
            menu.stamp = item.stamp;
          }
        });
      });
    }
    return itemCntData;
  }
}

export default MenuStore;
