import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';

@inject('commonStore')
@observer
class PaymentError extends Component {
  constructor(props) {
    const { location, match, commonStore, t } = props;
    const params = queryString.parse(location.search);
    if (match.params.companyId) {
      commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);
    }

    if (params.code) {
      switch (params.code) {
        case '1':
          throw new Error(t('ERROR_EXPRIRED_PAYMENT_TIME'));
        case '2':
          throw new Error(t('ERROR_NOT_MATCH_AMOUNT'));
        case '3':
          throw new Error(t('ERROR_DISCONTINUED_PRODUCT'));
        case '4':
          throw new Error(t('ERROR_SOLD_OUT_PRODUCT'));
        case '5':
          throw new Error(t('ERROR_PREPARING_BUSINESS'));
        case '6':
          throw new Error(t('ERROR_CANCEL_PAYMENT_USER'));
        default:
          throw new Error(t('ERROR_FAILED_PAYMENT'));
      }
    } else if (params.message) {
      throw new Error(decodeURIComponent(params.message));
    }
  }
}

export default withTranslation()(PaymentError);
