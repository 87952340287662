import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { Container, Box, Grid, Typography, Divider, Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { withTranslation } from 'react-i18next';
import Header from '../common/Header';
import Progress from '../common/Progress';
import api from '../../api';
import i18next from 'i18next';
import PaymentMethodEnum from '../../enums/PaymentMethodEnum';
import FooterYokohama from '../common/FooterYokohama';

@inject('commonStore', 'cartStore', 'userStore', 'orderStore', 'menuStore')
@observer
class OrderComplete extends Component {
  state = {
    isLiteOrder: false,
  };

  async componentDidMount() {
    const { commonStore, cartStore, userStore, orderStore, menuStore, match } = this.props;
    // 장바구니 비우기
    cartStore.clearCart();

    // 주문 조회 및 세팅
    const response = await api.getSale(
      match.params.companyId,
      match.params.storeCode,
      match.params.orderId,
      i18next.languages[0],
    );
    orderStore.setOrder(response.data);

    // 결제 완료 주문리스트 로컬스토리지 저장
    if (!userStore.userData.email) await userStore.setUserDataFromLocalStorage();
    if (!userStore.userData.orderList.some(x => x.receiptNum === response.data.receiptNum)) {
      response.data.orderDiscrimination = match.params.orderId;
      userStore.userData.orderList.push(response.data);
      userStore.saveUserData();
    }

    // 결제 완료 메일 전송
    // await api.successOrderInform(match.params.companyId, match.params.storeCode, match.params.orderId);

    // 현재 조회 된 상점 데이터가 없거나 (카톡 링크로 접속한 경우)
    // 조회한 주문의 상점과 현재 상점이 다른 경우
    // 매장 데이터 다시 조회
    if (
      !menuStore.storeData ||
      orderStore.order.companyId !== menuStore.storeData.companyId ||
      orderStore.order.storeCode !== menuStore.storeData.storeCode
    ) {
      commonStore.setData(orderStore.order.companyId, orderStore.order.storeCode);
    }
    this.setState(() => ({ isLiteOrder: this.props.orderStore.order.extOrderNo }));
  }

  handleClickOrderDetail = () => {
    const { history, match } = this.props;
    if (this.state.isLiteOrder) {
      // 새로고침 일때 버튼 유지를 위해 다른 URL 적용
      history.push(`/${match.params.companyId}/${match.params.storeCode}/lite-orders/${match.params.orderId}`);
    } else {
      history.push(`/${match.params.companyId}/${match.params.storeCode}/orders/${match.params.orderId}`);
    }
  };

  handleClickConfirm = () => {
    const { commonStore, history } = this.props;
    history.push(`/${commonStore.companyId}/${commonStore.storeCode}`);
  };

  render() {
    const { userStore, orderStore, menuStore, t } = this.props;

    if (!orderStore.order || !menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header iconType="CART" title={t('TITLE_ORDER_COMPLETED')} />

        <div style={{ marginTop: 120 }}>
          <Box
            style={{
              marginTop: '56px',
              padding: '35px 30px 91px',
              background: `linear-gradient(#${
                userStore.userData.baydiamondDelivery === '0' ? '0070CD' : 'c4bc7c'
              } 210px, #fff 0%)`,
            }}
          >
            <Container style={{ backgroundColor: '#fff', padding: '20px', border: '1px solid #DDD' }}>
              <div style={{ paddingBottom: '30px' }}>
                <Grid container direction="column" alignItems="center" spacing={3}>
                  <Grid item xs>
                    <CheckCircle color="primary" style={{ fontSize: '60px' }} />
                  </Grid>
                  <Grid item xs>
                    <Typography align="center" style={{ fontSize: '17pt', fontWeight: '500' }}>
                      {t('MESSAGE_PAYMENT_COMPLETE')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>

              <Divider style={{ borderTop: 'dotted 1px #D9D9D9' }} />

              <div
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                ※注文詳細ページでは、注文のステータス、受取番号、注文内容をご確認いただけます
              </div>

              <Divider style={{ borderTop: 'dotted 1px #D9D9D9' }} />

              <div style={{ paddingTop: '30px' }}>
                <Grid container>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    style={{ fontWeight: 'bold' }}
                    onClick={this.handleClickOrderDetail}
                  >
                    {t('BUTTON_PAYMENT_INFO_2')}
                  </Button>
                </Grid>
              </div>

              <Divider style={{ borderTop: 'dotted 1px #D9D9D9', marginTop: '30px' }} />

              <div style={{ padding: '45px 0' }}>
                <Grid container spacing={2}>
                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_ORDER_NUMBER_2')}</b>
                    </Grid>
                    <Grid item xs style={{ fontSize: '40px', textAlign: 'right' }}>
                      <b>{orderStore.order.waitNum}</b>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row" style={{ paddingTop: '0px' }}>
                    <Grid item>
                      <b>{t('MESSAGE_ORDER_TIME_2')}</b>
                    </Grid>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      {moment(orderStore.order.regDate).tz('Asia/tokyo').format('YYYY-MM-DD HH:mm:ss')}
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_STORE_NAME')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">{orderStore.order.storeName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_PAYMENT_AMOUNT')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right" className="price-color">
                        <b>
                          {menuStore.currency}
                          {Number(orderStore.order.price).toLocaleString()}
                          {menuStore.storeData.tax1_type === '2' ? (
                            <span style={{ color: '#8C8C8C', fontWeight: 'normal' }}>&nbsp;{t('TITLE_TAX_TYPE')}</span>
                          ) : (
                            ''
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item>
                      <b>{t('MESSAGE_PAYMENT_METHOD')}</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">{orderStore.paymentMethodName}</Typography>
                    </Grid>
                  </Grid>

                  {userStore.userData.baydiamondDelivery === '1' && (
                    <Grid item container direction="row">
                      <Grid item>
                        <b>{t('MESSAGE_SEAT_INFO')}</b>
                      </Grid>
                      <Grid item xs>
                        <Typography align="right" style={{ fontSize: '14px' }}>{`${orderStore.order.zone} SIDE （${
                          orderStore.order.zone === 'BAY' ? t('MESSAGE_ZONE_BAY') : t('MESSAGE_ZONE_STAR')
                        }） ${orderStore.order.block}段 ${orderStore.order.seat}番`}</Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item container direction="row">
                    <Grid item>
                      <b>BAYSTARS ID</b>
                    </Grid>
                    <Grid item xs>
                      <Typography align="right">{orderStore.order.userCardNumber}</Typography>
                    </Grid>
                  </Grid>
                  {orderStore.order.payments.length === 1 &&
                    orderStore.order.payments[0].methodCode === PaymentMethodEnum.CARD && (
                      <React.Fragment>
                        {orderStore.order.payments[0].cardQuota > 0 && (
                          <Grid item container direction="row">
                            <Grid item>
                              <b>{t('MESSAGE_INSTALLMENTS')}</b>
                            </Grid>
                            <Grid item xs>
                              <Typography align="right">
                                {+orderStore.order.payments[0].cardQuota === 1
                                  ? t('MESSAGE_PAY_FULL')
                                  : t('MESSAGE_MONTHS', { n: +orderStore.order.payments[0].cardQuota })}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {orderStore.order.payments[0].authCode && (
                          <Grid item container direction="row">
                            <Grid item>
                              <b>{t('MESSAGE_APPROVAL_NUMBER')}</b>
                            </Grid>
                            <Grid item xs>
                              <Typography align="right">{orderStore.order.payments[0].authCode}</Typography>
                            </Grid>
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                </Grid>
              </div>
            </Container>
          </Box>
          {!this.state.isLiteOrder && (
            // <AppBar position="fixed" color="primary" className="bottom">
            <div className="bottom" style={{ color: '#FFF' }}>
              <FooterYokohama />
            </div>
            // </AppBar>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(OrderComplete));
