import React, { Component } from 'react';
import List from '@mui/material/List';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Divider, ListItem, Typography, Box, Button, Toolbar } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Progress from '../common/Progress';

@inject('menuStore')
@observer
class OrderCompleteList extends Component {
  buttonClick = e => {
    const { history, match } = this.props;
    history.push(`/${match.params.companyId}/${match.params.storeCode}/orders/${e.target.id}`);
  };

  render() {
    const { menuStore, t } = this.props;

    if (!menuStore.storeData) return <Progress />;
    return (
      <React.Fragment>
        {this.props.orderList.map((data, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Box sx={{ flexGrow: 1 }} style={{ margin: '8px 16px' }}>
                  <Grid2 container>
                    <Grid2 item xs={12} sm={10}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: 'bold', Collapse: 'true' }}
                      >
                        {t('MESSAGE_ORDER_NUMBER_2') + ' : ' + data.waitNum}
                      </Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={10}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: 'bold', Collapse: 'true', fontSize: '15px' }}
                      >
                        {t('MESSAGE_ORDER_TIME_2') +
                          ' : ' +
                          moment(data.regDate).tz('Asia/tokyo').format('yyyy-MM-DD HH:mm:ss')}
                      </Typography>
                    </Grid2>
                  </Grid2>
                  <Grid2 container>
                    <Grid2 xs container direction="column">
                      <Typography style={{ fontWeight: 'bold', Collapse: 'true' }}>
                        {t('TITLE_ORDER_GOODS_COUNT', { n: data.menus.reduce((a, b) => a + b.qty, 0) })}
                      </Typography>
                    </Grid2>
                    <Grid2 container direction="column" justifyContent="center">
                      <Typography style={{ fontWeight: 'bold', Collapse: 'true' }}>
                        {menuStore.currency}
                        {data.price}
                        {menuStore.storeData.tax1_type === '2' ? (
                          <span style={{ color: '#8C8C8C', fontWeight: 'normal' }}>&nbsp;{t('TITLE_TAX_TYPE')}</span>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {data.menus.map((cartMenu, index) => (
                  <List key={index}>
                    <ListItem divider style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                      <Box sx={{ flexGrow: 1 }} style={{ padding: '8px 16px' }}>
                        <Grid2 container direction={'column'} spacing={2}>
                          <Grid2 container>
                            <Grid2 container xs direction="column" justifyContent="center">
                              <Typography color="textPrimary" style={{ fontWeight: 'bold', Collapse: 'true' }}>
                                {cartMenu.name}
                              </Typography>
                            </Grid2>
                            <Grid2 container xs>
                              <Grid2 container xs direction="column" justifyContent="center">
                                <Typography style={{ fontWeight: 'bold' }}>{'x' + cartMenu.qty}</Typography>
                              </Grid2>
                              <Grid2 container direction="column" justifyContent="center">
                                <Typography
                                  style={{ fontWeight: 'bold', padding: '0px', margin: '0px' }}
                                  component={'div'}
                                  align="right"
                                  flexGrow={1}
                                >
                                  {menuStore.currency}
                                  {cartMenu.menuPrice * cartMenu.qty}
                                </Typography>
                                {menuStore.storeData.tax1_type === '2' ? (
                                  <Typography
                                    style={{ color: '#8C8C8C', padding: '0px', margin: '0px' }}
                                    component={'div'}
                                    flexGrow={1}
                                    align="right"
                                  >
                                    &nbsp;{t('TITLE_TAX_TYPE')}
                                  </Typography>
                                ) : (
                                  ''
                                )}
                              </Grid2>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      </Box>
                      <Divider />
                    </ListItem>
                  </List>
                ))}
                <div className="bottom" style={{ color: '#FFF' }}>
                  <Toolbar variant="dense" style={{ padding: '0' }}>
                    <Button
                      id={data.orderDiscrimination}
                      onClick={this.buttonClick}
                      fullWidth
                      variant={'contained'}
                      size={'large'}
                      className="bottom-button"
                    >
                      {t('BUTTON_PAYMENT_INFO')}
                    </Button>
                  </Toolbar>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(OrderCompleteList));
