import { action, observable } from 'mobx';
import i18n from 'i18next';
import UserOrderStoreModel from '../models/UserOrderStoreModel';
import api from '../api';

class UserStore {
  constructor(root) {
    this.root = root;
  }

  // 스토리지에 저장되는 주문 정보
  @observable userData = {
    phoneNumber: '', // 전화번호
    zipCode: '', // 우편번호
    sido: '', // 시/도
    sigungu: '', // 시/군/구
    dong: '', // 읍/면/동
    jibunAddress: '', // 지번 주소
    roadAddress: '', // 도로명 주소
    addressDetail: '', // 주소 상세
    latitude: 0, // 위도
    longitude: 0, // 경도
    isMember: false,
    isPointAdded: true,
    email: '',
    userCardNumber: '',
    baydiamondDelivery: '',
    zone: '',
    block: '',
    seat: '',
    orderList: [],
  };
  @observable userPoint = 0;
  // 주문 매장 리스트
  @observable orderStores = [];

  // 브라우저에 저장된 고객 입력 데이터 조회
  @action
  async setUserDataFromLocalStorage() {
    if (!this.userData.phoneNumber || !this.userData.email) {
      const storage = localStorage.getItem('storage');

      if (storage) {
        this.userData = JSON.parse(storage);
      }
    }
  }

  // 주문 매장 리스트 조회
  @action
  async getUserOrderStores(encryptedId) {
    try {
      const response = await api.getOrderStoresByUser(encryptedId);

      this.orderStores = response.data.map(orderStore => new UserOrderStoreModel(orderStore));
    } catch (error) {
      this.root.commonStore.setError(i18n.t('ERROR_NOT_FOUND_ORDER_STORE'));
      throw error;
    }
  }

  @action
  setUserPoint(value) {
    this.userPoint = Number(value);
  }

  async removeExpirationOrderList() {
    this.userData.orderList = this.userData.orderList
      .filter(a => (new Date() - new Date(a.regDate).getTime()) / (1000 * 60 * 60 * 24) < 2)
      .sort(function (a, b) {
        return new Date(b.regDate).getTime() - new Date(a.regDate).getTime();
      });
  }

  saveUserData() {
    const storage = Object.assign({}, this.userData);
    localStorage.setItem('storage', JSON.stringify(storage));
  }
}

export default UserStore;
