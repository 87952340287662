import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

@inject('menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class Point extends Component {
  componentDidMount() {
    this.props.orderStore.setInputPoint('');
  }

  // 포인트 사용 금액 변경
  handleChangeUsePoint = event => {
    this.props.orderStore.setInputPoint(event.target.value);
  };

  // 전액사용
  handleClickUseFullPoint = () => {
    const { userStore, orderStore } = this.props;
    orderStore.setInputPoint(userStore.userPoint);
  };

  render() {
    const { t, menuStore, userStore, orderStore } = this.props;
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1">
            {t('MESSAGE_REDEEM_POINTS')} (
            {`${t('MESSAGE_REMAINING_POINTS')}: ${Number(userStore.userPoint).toLocaleString()}`})
          </Typography>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={8}>
            <TextField
              name="inputPoint"
              size="small"
              fullWidth
              inputProps={{ style: { textAlign: 'right' } }}
              value={orderStore.inputPoint}
              onChange={this.handleChangeUsePoint}
              helperText={t('MESSAGE_MINIMUM_REMAINING_POINTS', {
                n: Number(menuStore.storeData.useableMinimumPoint).toLocaleString(),
              })}
              error={
                Number(orderStore.usePoint) > 0 && Number(userStore.userPoint) < menuStore.storeData.useableMinimumPoint
                  ? true
                  : false
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              //style={{ height: '100%' }}
              onClick={this.handleClickUseFullPoint}
            >
              {t('BUTTON_FULL_USE')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(Point));
