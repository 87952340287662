import React, { Component } from 'react';
import List from '@mui/material/List';
import CartMenuItem from './CartMenuItem';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';
import { Divider, Grid, ListItem, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@inject('cartStore', 'menuStore')
@observer
class CartMenuList extends Component {
  render() {
    const { cartStore, menuStore, t } = this.props;
    return (
      <List style={{ padding: '0' }}>
        {cartStore.cartData.menus.map((cartMenu, index) => (
          <CartMenuItem cartMenu={cartMenu} index={index} key={index} />
        ))}
        {cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY && (
          <ListItem divider style={{ padding: '16px' }}>
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item container xs direction="column" spacing={1}>
                  <Grid item>
                    <Typography component="span" variant="subtitle1" color="textPrimary" style={{ fontWeight: 'bold' }}>
                      {t('MESSAGE_DELIVERY_FEE')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container alignItems="center" style={{ width: '110px', textAlign: 'right' }}>
                  <Grid item xs>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {menuStore.currency}
                      {Number(menuStore.storeData.deliveryFee).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withTranslation()(CartMenuList);
