import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Progress from '../common/Progress';
import Header from '../common/Header';
import TermDialog from './TermDialog';
import OrderRequest from './OrderRequest';
import UserInput from './UserInput';
import Bottom from './Bottom';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore')
@observer
class Order extends Component {
  async componentDidMount() {
    const { t, match, history, commonStore, cartStore, userStore } = this.props;

    // 매장 데이터 세팅
    await commonStore.setData(match.params.companyId.toLowerCase(), match.params.storeCode);

    // 장바구니 체크
    if (cartStore.cartData.menus.length === 0) {
      alert(t('ALERT_EMPTY_CART'));
      history.push(`/${match.params.companyId.toLowerCase()}/${match.params.storeCode}`);
      return;
    }

    // 유저데이터 세팅
    userStore.setUserDataFromLocalStorage();
  }

  render() {
    const { t, menuStore, cartStore } = this.props;
    if (!menuStore.storeData || !cartStore.cartData) return <Progress />;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header iconType="CART" title={t('TITLE_ORDER')} />

        <div className="contents">
          {/* 주문 요청사항 */}
          <OrderRequest />

          {/* 사용자 정보 입력 */}
          <UserInput />
        </div>

        {/* 푸터 버튼 */}
        <Bottom />

        {/* 개인정보 수집 및 이용 동의 다이얼로그 */}
        <TermDialog />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Order));
