import axios from 'axios';

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

// 응답 인터셉터 추가
instance.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    if (error.response && error.response.data && error.response.data.message) {
      // 서버에러가 아니고 에러 메시지가 있다면 전달 받은 에러 메시지
      throw new Error(error.response.data.message);
    }

    return Promise.reject(error);
  },
);

const api = {
  // 메인화면 이미지 url / 팝업 여부 조회
  getTopPageImage(smartOrderType) {
    return instance.get(`/MobileOrder/getTopPageImage?smartOrderType=${smartOrderType}`);
  },
  // 상점 정보 조회
  getStore(companyId, storeCode, languageCode) {
    return instance.get(`/companies/${companyId}/stores/${storeCode}?langCode=${languageCode}`);
  },
  // 주문 조회
  getSale(companyId, storeCode, orderId, languageCode) {
    return instance.get(`/companies/${companyId}/stores/${storeCode}/sales/${orderId}?langCode=${languageCode}`);
  },
  // 주문 상태 조회
  getOrderState(companyId, storeCode, orderId) {
    return instance.get(`/companies/${companyId}/stores/${storeCode}/sales/${orderId}/status`);
  },
  getLiteOrder(companyId, storeCode, orderId, languageCode) {
    return instance.get(
      `/companies/${companyId}/stores/${storeCode}/lite-orders/${encodeURIComponent(orderId)}?langCode=${languageCode}`,
    );
  },
  // 매장 상태 및 대기 시간 조회
  getSmartOrderState(companyId, storeCode) {
    return instance.get(`/DID/getSmartOrderState?CallType=2&CompanyID=${companyId}&StoreCode=${storeCode}`);
  },
  // 남은 재고 수량 확인 (처음 작성 시 판매된 재고 수량을 요청하는 api 였음)
  getSalesItemQty(companyId, storeCode, itemCode) {
    return instance.get(`/sales/getSaleItemCnt?companyId=${companyId}&storeCode=${storeCode}&itemCode=${itemCode}`);
  },

  /** 요코하마구장에선 사용안함 */
  // QR주문 임시 저장
  addQrOrder(orderData) {
    return instance.post('/qr-order', JSON.stringify(orderData));
  },
  // 주문 예약
  reserveOrder(orderData) {
    return instance.post('/sales', JSON.stringify(orderData));
  },
  // 포인트 주문 결제
  payOrderByPointOnly(orderData) {
    return instance.post('/sales/point', JSON.stringify(orderData));
  },
  // 회원 포인트 조회
  getUserPointByPhoneNumber(companyId, storeCode, phoneNumber) {
    return instance.get(`/companies/${companyId}/stores/${storeCode}/users/${phoneNumber.trim()}`);
  },
  // 주문 매장 리스트 조회
  getOrderStoresByUser(encryptedId) {
    return instance.get(`/users/${encryptedId}/order-stores`);
  },
  /** 요코하마구장에선 사용안함 */

  /** 프로세스 변경으로 사용안함 (요코하마 때문에 작성 됐던 코드) */
  // 결제 완료 후 이메일 발송
  successOrderInform(companyId, storeCode, orderId) {
    return instance.get(
      `/sales/softbank/successOrderInform?companyId=${companyId}&storeCode=${storeCode}&orderId=${orderId}`,
    );
  },
  /** 프로세스 변경으로 사용안함 (요코하마 때문에 작성 됐던 코드) */
};

export default api;
