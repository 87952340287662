import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Bottom from './Bottom';
import api from '../../api';

@inject('commonStore', 'menuStore', 'orderStore')
@observer
class PaymentNice extends Component {
  constructor(props) {
    super(props);

    window.nicepaySubmit = this.nicepaySubmit.bind(this);
    window.nicepayClose = this.nicepayClose.bind(this);
  }

  componentDidMount() {
    // PC 브라우저인 경우 스크립트 include
    if (this.checkPlatform(window.navigator.userAgent) === 'pc') {
      const script = document.createElement('script');

      script.src = 'https://web.nicepay.co.kr/v3/webstd/js/nicepay-3.0.js';
      script.async = true;

      document.body.appendChild(script);
    }
  }

  pay = async () => {
    const { commonStore, orderStore } = this.props;

    try {
      // 결제 예약 API 실행
      const response = await api.reserveOrder(orderStore.orderData);

      // 나이스페이 결제 요청 데이터 세팅
      orderStore.setPaymentData(response.data);
    } catch (error) {
      commonStore.setError(error);
      throw error;
    } finally {
      // 결제 버튼 활성화
      orderStore.setDisablePaymentButton(false);
    }

    // 나이스페이 결제 페이지 호출
    this.nicepayStart();
  };

  nicepayStart() {
    if (isMobile) {
      document.payForm.action = 'https://web.nicepay.co.kr/v3/v3Payment.jsp';
      document.payForm.acceptCharset = 'euc-kr';
      document.payForm.submit();
    } else {
      /*global goPay*/
      goPay(document.payForm);
    }
  }

  //[PC Only]When pc payment window is closed, nicepay-3.0.js call back nicepaySubmit() function <<'nicepaySubmit()' DO NOT CHANGE>>
  nicepaySubmit() {
    document.payForm.submit();
  }

  //[PC Only]payment window close function <<'nicepayClose()' DO NOT CHANGE>>
  nicepayClose() {
    const { orderStore } = this.props;
    // 나이스페이 결제 취소 시에 결제 가능 상태로 변경
    orderStore.setDisablePaymentButton(false);
  }

  //pc, mobile chack script (sample code)
  checkPlatform(ua) {
    if (ua === undefined) {
      ua = window.navigator.userAgent;
    }

    ua = ua.toLowerCase();
    var platform = {};
    var matched = {};
    var userPlatform = 'pc';
    var platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];

    matched.platform = platform_match[0] || '';

    if (matched.platform) {
      platform[matched.platform] = true;
    }

    if (
      platform.android ||
      platform.bb ||
      platform.blackberry ||
      platform.ipad ||
      platform.iphone ||
      platform.ipod ||
      platform.kindle ||
      platform.playbook ||
      platform.silk ||
      platform['windows phone']
    ) {
      userPlatform = 'mobile';
    }

    if (platform.cros || platform.mac || platform.linux || platform.win) {
      userPlatform = 'pc';
    }

    return userPlatform;
  }

  render() {
    const { menuStore, orderStore } = this.props;
    return (
      <React.Fragment>
        <form name="payForm" method="post" action={process.env.REACT_APP_API_SERVER + '/sales/nicepay'}>
          {/************************* 공통 필수 *************************/}
          {/* 상품명 */}
          <input type="hidden" name="GoodsName" value={orderStore.paymentMenuName} />
          {/* 금액 */}
          <input type="hidden" name="Amt" value={orderStore.requestAmount} />
          {/* 상점아이디 */}
          <input type="hidden" name="MID" value={menuStore.storeData ? menuStore.storeData.merchantID : ''} />
          {/* 요청시간 (YYYYMMDDHHMISS) */}
          <input type="hidden" name="EdiDate" value={orderStore.paymentData.ediDate + orderStore.paymentData.ediTime} />
          {/* 주문번호 */}
          <input type="hidden" name="Moid" value={orderStore.paymentData.orderId} />
          {/* 위변조 검증 해쉬값 */}
          <input type="hidden" name="SignData" value={orderStore.paymentData.signData} />

          {/************************* 모바일 필수 *************************/}
          {/* 요청 응답 URL */}
          <input type="hidden" name="ReturnURL" value={process.env.REACT_APP_API_SERVER + '/sales/nicepay'} />

          {/************************* 옵션 *************************/}
          {/* 결제타입, 콤마 구분자로 다중값 사용 가능 (CARD, BANK, VBANK, CELLPHONE) */}
          <input type="hidden" name="PayMethod" value="CARD" />
          {/* 구매장 이름 */}
          <input type="hidden" name="BuyerName" value="" />
          {/* 구매자 메일 */}
          <input type="hidden" name="BuyerEmail" value="" />
          {/* 구매자 연락처 */}
          <input type="hidden" name="BuyerTel" value={orderStore.phoneNumber} />
          {/* EN:English, CN:Chinese, KO:Korean */}
          <input type="hidden" name="NpLang" value="KO" />
          {/* USE escrow false(0)/true(1) */}
          <input type="hidden" name="TransType" value="0" />
          {/* Return CharSet */}
          <input type="hidden" name="CharSet" value="utf-8" />
          {/* mall custom field */}
          <input
            type="hidden"
            name="ReqReserved"
            value={`${menuStore.storeData.companyId}|${menuStore.storeData.storeCode}`}
          />

          {/************************* 결제수단별 추가 *************************/}
          {/* 가상계좌. 입금만료일 (YYYYMMDDHHMM) */}
          <input type="hidden" name="VbankExpDate" value="" />
          {/* 휴대폰 소액결제. 0:컨텐츠, 1:실물 */}
          <input type="hidden" name="GoodsCl" value="1" />
          {/* 상점에서 사용중인 사용자 아이디 (정산시에 매장을 구분하기 위해 매장 정보 입력 용도로 사용 중) */}
          <input
            type="hidden"
            name="MallUserID"
            value={`${menuStore.storeData.companyId}|${menuStore.storeData.storeCode}`}
          />
        </form>

        <Bottom pay={this.pay} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(PaymentNice);
