import { AppBar, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { ArrowBack } from '@mui/icons-material';

class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <AppBar position="fixed" color="primary" style={{ boxShadow: 'none', zIndex: 1 }}>
          <Toolbar style={{ minHeight: '60px' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.props.history.goBack()}
              size="large">
              <ArrowBack />
            </IconButton>
            <div className="title" style={{ flexGrow: 1, textAlign: 'center' }}>
              利用規約
            </div>
          </Toolbar>
        </AppBar>
        <div className="contents">
          <h1 className="terms-title">UP SMART ORDER利用規約</h1>
          <pre className="terms-contents">
            {`
この規約（以下「本規約」といいます。）は、大江電機株式会社（以下「当社」といいます。）が提供するUP SMART ORDER（第1条第①号に定義する本サービスを意味します。）の利用に関する条件を、本サービスを利用するお客様（以下「お客様」といいます。）と当社との間で定めるものです。

第1条（定義）
本規約において使用する用語の定義は、次の各号に定めるとおりとします。
①	 「本サービス」とは、利用契約者（第②号に定義します。）がアプリ（第③号に定義します。）上で販売提供するピックアップ商品（第④号に定義します。）について、お客様が注文（第⑤号に定義します。）及び事前決済を行い、受取場所として指定されたお客様のピックアップ場所（以下「ピックアップゾーン」といいます。）の店舗（以下「店舗ピックアップゾーン」といい、受取場所として指定された店舗ピックアップゾーンを「指定店舗ピックアップゾーン」といいます。）内にて受け取ることを可能にするモバイルオーダーサービスであるUP SMART ORDERをいいます。
② 「利用契約者」とは、自己の商品をお客様に対して販売する目的で、アプリ上に自己の情報及びピックアップ商品の情報を掲載し、お客様の注文を受けて、ピックアップ商品をお客様に販売提供する者をいいます。
③ 「アプリ」とは、当社が、LINE株式会社が配信するスマートフォン向けアプリ「LINE」上で稼働する「ミニアプリ」を利用しているお客様を対象に企画、配信する、ピックアップ商品の注文、事前決済、及び指定店舗ピックアップゾーンでのピックアップ商品の受取りを可能にするアプリケーションをいいます。
④ 「ピックアップ商品」とは、利用契約者がアプリ上でお客様に対して販売提供する食品、その他同種の商品であって、お客様が指定店舗ピックアップゾーン内にて受け取ることができる商品をいいます。
⑤ 「注文」とは、お客様がアプリ上で当社の指定する必要情報を入力することにより、ピックアップ商品の購入を申し込むことをいいます。

第2条（規約への同意、規約の変更）
1．お客様は、本規約に従って本サービスを利用しなければなりません。お客様は、本規約に同意しないかぎり本サービスを利用することはできません。
2．お客様が本サービスを利用することにより、本規約に同意したものとみなします。
3．当社は、当社が必要と判断する場合、本サービスの目的の範囲内で、本規約を変更することができるものとします。その場合、当社は、変更後の本規約の内容及び効力発生日を、アプリ上に表示し、又は当社が定める方法によりお客様に通知することでお客様に周知します。変更後の本規約は、効力発生日から効力を生じるものとし、効力発生日以後にお客様が本サービスの利用を継続した場合、お客様は本規約の変更に同意したものとみなされます。

第3条（利用登録）
1．お客様は、本サービスを利用するに当たっては、LINE株式会社が配信するスマートフォン向けアプリ「LINE」のアカウント（以下「LINEアカウント」といいます。）を保有していることが必要になります。LINEアカウントの管理その他LINEアカウントの利用については、LINEアカウント作成時に同意していただいたLINE利用規約が適用されますので、併せてご確認ください。
2．本サービスの利用を希望されるお客様は、本規約に同意し、かつ当社の定める方法で届出情報を提供することにより、本サービスの利用登録をすることができます。
3．お客様が利用登録を行った時点をもって、当社とお客様との間に、本規約を内容とする本サービスの利用契約（以下「本サービス利用契約」といいます。）が成立するものとします。
4．お客様は、本サービスの利用登録に当たり、以下の各号の事項を遵守するものとします。
  ① 自身の真実かつ正確な最新の情報を届出情報として当社に提供するものとします。
  ② 許可を得ることなく他人の名義（法人、組織・団体名義を含みます。）で利用登録してはなりません。
  ③ 同一人物が複数の利用登録をしてはなりません。
  ④ 過去に第14条第1項に定める利用停止等の措置を受けたお客様は再度の利用登録をしてはなりません。
  ⑤ お客様が未成年者であり、親権者等の法定代理人の同意を得ていない場合、利用登録をしてはなりません。
  ⑥ 反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ、特殊知能暴力集団等又はこれらに準じる者をいいます。以下同じです。）に該当する場合、その経営に反社会的勢力が実質的に関与していると認められる場合、反社会的勢力を利用していると認められる場合、反社会的勢力に対して資金等を提供し若しくは便宜を供与する等の関与をしていると認められる場合、又は、反社会的勢力と社会的に非難されるべき関係を有している場合には、利用登録をしてはなりません。
  ⑦ 届出情報における氏名、電話番号やLINEアカウント等は、自己の責任において厳重に管理し、セキュリティを脅かす危険のある行為を行わないものとします。権限のない者によりこれらが利用された場合又は利用された可能性がある場合には、直ちに当社に通知するものとします。
  ⑧ 他人にお客様の利用登録名義又はLINEアカウントの使用を許諾したり、譲渡しては なりません。
5．お客様は、届出情報に変更があった場合には、速やかに、当社の定める方法により当該変更事項を当社に通知するものとします。
6．当社は、届出情報中の連絡先（電話番号、LINEアカウント）への連絡の方法、又はアプリによる通知等の方法により、お客様に対して本サービスの提供に当たり必要な連絡をすることがあります。お客様は、お客様が現に使用している連絡先を当社に対して提供する（変更があった場合の変更通知を含みます。）とともに、アプリを利用するスマートフォン端末又はタブレット端末の設定によりアプリによる通知を受けられる状態でいなければなりません。これに反することにより、当社からの連絡を受けられずにお客様に損害が生じた場合でも、当社は一切の責任を負わないものとします。
7．当社は、本サービスその他の当社又はピックアップゾーンの商品やサービスに関する広告や宣伝を、LINEメッセージによる通知の方法により行うことがあります。お客様はあらかじめこれに同意の上、本サービスを利用するものとします。
8．当社は、お客様の利用登録名義又はLINEアカウントを使用して行われた一切の行為を、お客様自身の行為とみなすことができ、これによりお客様に損害が生じた場合でも一切の責任を負わないものとします。
9．当社は、お客様に対して、当社の判断により、当社所定のクーポンをアプリ上で付与することがあります。当該クーポンを付与する場合には、内容の詳細について、アプリ上でお客様に通知いたします。
10．LINEアカウントが削除されると、本サービスの利用履歴は消滅します。お客様が誤ってLINEアカウントを削除した場合であっても本サービスの利用履歴を復旧することはできませんのでご注意ください。

第4条（本サービスの利用環境の整備等）
1．本サービスの利用には、インターネットへの接続及びアプリをインストールしたスマートフォン端末又はタブレット端末が必要になります。そのために必要となるスマートフォン端末、タブレット端末、ソフトウェアその他の機器、通信回線その他の通信環境等の本サービス利用環境の整備及び維持は、お客様の費用と責任において行い、利用に当たって必要となる通信料金はお客様が負担するものとします。お客様側の本サービス利用環境に起因する本サービスの利用上の支障について、当社は一切の責任を負いません。
2．当社は、本サービス及びアプリがあらゆる機器等に適合することを保証するものではありません。

第5条（利用契約者との間の売買契約の成立）
1．お客様がピックアップ商品を購入する場合、当社所定の時間内に、アプリ上で当社所定の方法に従いピックアップ商品の注文を行うことにより、当該商品の販売主である利用契約者に対して、当該ピックアップ商品の購入に係る個別の売買契約を申し込むものとします。
2．前項に基づく注文があった場合、アプリ上で注文完了の通知（以下「注文完了通知」といいます。）及び注文番号が自動発信されるものとし、お客様に注文完了通知が到達した時点をもって、当該申込が承諾され、お客様と利用契約者との間で、当該注文の対象となるピックアップ商品に係る個別の売買契約が成立するものとします。
3．ピックアップ商品に係る売買契約は、お客様と利用契約者との間で成立するものであり、当社は当該売買契約の当事者にはなりません。
4．当社は、必要と判断する場合は、本条及び第7条に定める当社所定の時間を変更できるものとし、変更する場合には、事前にLINEメッセージにより通知した上で、アプリ上に表示するものとします。

第6条（商品代金の支払い）
お客様は、利用契約者との間でピックアップ商品に係る個別の売買契約が成立した時点で、対象となるピックアップ商品の代金を、クレジットカード決済、QRコード決済の方法により、利用契約者から商品代金の代理受領権限を与えられている当社にお支払いいただきます。

第7条（ピックアップ商品の受取り等）
1．お客様は、ピックアップ商品を注文する際に、当該商品の受取りを希望する店舗ピックアップゾーン（受取場所となる店舗ピックアップゾーン）を、当社所定の方法に従い指定するものとします。なお、アプリ上で、ピックアップ商品の受取場所となる店舗ピックアップゾーンが1店舗しか選択できない場合には、お客様は、他の店舗は選択できず、当該店舗を受取場所として指定するものとします。
2．お客様との間で売買契約が成立したピックアップ商品は、当社所定の時間（以下「指定配達時間」といいます。）までに、利用契約者により指定ピックアップゾーンに収納され、アプリ上に準備完了の通知（以下「準備完了通知」といいます。）が利用契約者より発信されるものとします。
3．アプリを通じて注文を行ったお客様は、ピックアップ準備完了時間以降、当社所定の時間（以下「お客様受取期限」といいます。）までに、指定店舗ピックアップゾーンで、アプリに表示された注文番号と同じ番号の注文番号シールが貼付されたピックアップ商品を受け取るものとします。なお、指定配達時間までにアプリ上で準備完了通知を受信しなかった場合であっても、お客様は、指定配達時間以降、お客様受取期限までの間は、本項に基づくピックアップ商品の受取りができるものとします。
4．ピックアップ商品の引渡しは、前項の商品受取りが完了した時点をもって完了するものとします。 

第8条（注文のキャンセル等）
1．お客様は、利用契約者との間でピックアップ商品に係る売買契約が成立した時点以降において、第2項及び第3項に定める場合を除き、売買契約の解除（注文のキャンセル等）をすることはできません。
2．お客様は、利用契約者に対して架電等適宜の方法で直接連絡を行うことにより、利用契約者の同意が得られた場合に限り、売買契約を解除することができます。当社は、利用契約者より売買契約が合意解除された旨の通知を受けた場合、利用契約者の指示に従って返金等必要な処理を取るものとし、この場合の取扱い及び解除の有効性等について、何ら責任を負いません。

第9条（本サービスに関する著作権その他の権利の帰属等）
1．本サービスに関する著作権、商標権その他一切の知的財産権及びその他の権利は、当社又は当社に使用を許諾する第三者に帰属するものとします。
2．本サービス利用契約は、本サービスに関する当社又は当社に使用を許諾する第三者の知的財産権及びその他の権利の使用許諾を意味するものではありません。

第10条（本サービス利用契約終了時の取扱い）
1．理由の如何を問わず本サービス利用契約が終了した場合、当社は、当該お客様に係る届出情報及び本サービスの利用データ（以下、あわせて「利用データ等」といいます。）を削除することができます。また、当社は、削除された利用データ等を復旧させる義務を負わないものとします。
2．理由の如何を問わず本サービス利用契約が終了した場合においても、第3条第8項、第9条乃至第11条、第13条第2項、第14条乃至第19条は有効に存続するものとします。

第11条（個人情報等の取扱い）
1． 当社は、本サービスを利用されるお客様に関する情報の取扱いについて当社が定めるUP SMART ORDERプライバシーポリシーに従って、お客様の届出情報その他の本サービスの利用を介して取得したお客様の情報（個人情報を含みます。）を適切に取り扱うものとし、お客様はこれに同意します。
2．お客様は、当社が、お客様の届出情報その他の本サービスの利用を介して取得したお客様の情報（個人情報を含みます。）を、当社が、本サービスの実施、改善、及び将来的に当社のグループ会社に対して本サービスを移管すること等を目的として、利用契約者、指定店舗ピックアップゾーン及び当社のグループ会社（「財務諸表等の用語、様式及び作成方法に関する規則（昭和38年大蔵省令第59号）」第8条に定める「子会社」及び「関連会社」をいう。以下同じ。）に対して提供することがあることに同意します。
3．当社は、法令上要求又は許容される場合には、お客様の同意を得ずに、お客様の届出情報その他の本サービスの利用を介して取得したお客様の情報（個人情報を含みます。）を第三者に提供することがあります。

第12条（禁止事項）
当社は、本サービスに関するお客様による以下の各号に定める行為を禁止します。
① 本規約に違反する行為
② 法令等の定めに違反する行為又はそのおそれのある行為
③ 公序良俗に反する行為
④ 当社に虚偽の事項を届け出る行為
⑤ 反社会的勢力に対する利益供与その他の協力行為
⑥ 本サービスによって取得した商品を営利目的で転売する等、当社の同意なく、本サービスを営利的に使用する行為
⑦ 当初から受け取る意思がないにもかかわらず注文をする行為
⑧ 当社、利用契約者、店舗ピックアップゾーン、他のお客様又は第三者に対し、財産権（知的財産権を含みます。）の侵害、名誉・プライバシーの侵害、誹謗中傷、その他の不利益を与える行為又はそのおそれのある行為
⑨ 他のお客様又は第三者の個人情報、登録情報、利用履歴情報などを、不正に収集、開示又は提供する行為
⑩ 当社のサービス業務（本サービスを含むがこれに限られません。）の運営・維持又は利用契約者若しくは他のお客様による本サービスの利用を妨害し、支障を与える行為又はそのおそれのある行為
⑪ 当社又はアプリや本サービスの信用を毀損し又はそのおそれのある行為
⑫ 本サービスに関して利用し得る情報を改ざんする行為
⑬ 有害なコンピュータプログラム、メール等をアプリに送信又は書き込む行為
⑭ サーバその他当社のコンピュータに不正にアクセスする行為
⑮ 第三者になりすまして本サービスを不正に利用する行為
⑯ その他当社が別途禁止行為として定める行為又は当社が不適切と判断した行為

第13条（利用停止・解除等）
1．当社は、お客様が、以下の各号のいずれかの事由に該当し、又は該当するおそれがあると判断した場合は、事前に通知又は催告することなく、本サービスの一時的若しくは恒久的な停止、又はお客様の利用登録の抹消、若しくは本サービス利用契約の解除、その他、当社が必要かつ適切と判断する措置（以下「利用停止等の措置」といいます。）をとることができるものとします（また、当社は、お客様に、架電又はLINEメッセージ等の方法で通知してこれらの措置に応じるように促すことができ、この場合、お客様はこれに応じるものとします。）。
  ① 本サービスへの利用登録後に、お客様が第3条第4項に定める遵守事項に違反していることを当社が知った場合、又は、本サービスへの利用登録後に、お客様が第3条第4項に定める遵守事項に違反していると当社が判断した場合
  ② 法令等の定め又は本規約のいずれかの条項に違反した場合
  ③ 不正又は不当な目的をもって本サービスを利用した場合
  ④ 利用契約者又は他のお客様からの苦情等又はピックアップ商品に係るトラブル等から、お客様による本サービスの利用が当社又はアプリ若しくは本サービスの信用等に影響を及ぼす可能性がある場合
  ⑤ 当社の責に帰すべき理由によらず連絡が取れない場合
  ⑥ アプリへ1年以上アクセスしていない場合
  ⑦ 差押え、仮差押え、仮処分、租税滞納処分を受け、又は、破産、民事再生、特別清算、会社更生を自ら申立て若しくは申立てを受けた場合
  ⑧ 手形・小切手の不渡処分を受け、又はその他支払い不能となった場合
  ⑨ 当社との信頼関係が失われた場合その他当社がお客様による本サービスの利用継続を適当ではないと判断した場合
2．当社は、当社が行った利用停止等の措置によりお客様に生じた損害について、当社の責に帰すべき場合を除き、一切の責任を負わないものとします。

第14条（本サービスの変更・停止・廃止）
1．当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービス又はアプリの全部又は一部の内容を変更することができるものとします。
2．当社は、以下のいずれかに該当する場合、あらかじめお客様に通知することなく、本サービスの全部又は一部の停止又は廃止をすることができるものとします。
  ① アプリや関連するシステムのメンテナンス又は修理を行う場合
  ② 火災、停電等の事故、天災、戦争、暴動、労働争議等の不可抗力により、本サービスの提供ができなくなった場合
  ③ システム障害が発生した場合又はシステムに負荷が集中した場合
  ④ お客様若しくは第三者の安全を確保する場合又は公共の利益のために緊急を有する場合
  ⑤ 決済事業者から本サービスの利用停止の要請があった場合又は決済サービスの提供停止措置が取られた場合
  ⑥ 前各号に定めるもののほか、当社が必要と判断した場合
3．前二項に起因してお客様又は第三者に何らかの損害が生じた場合であっても、当社は、当社の責めに帰すべき場合を除き、何ら責任を負わないものとします。

第15条（非保証・免責等）
1．当社は、アプリ等の本サービスに関わるウェブサイトに事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害等を含みますが、これらに限られません。）がないことを明示的にも黙示的にも保証せず、お客様に対して、かかる瑕疵等を除去して本サービスの提供をする義務を負うものではありません。
2．当社は、前項に定める瑕疵によるもののほか、アプリ等の本サービスに関わるウェブサイトの障害、不具合（アクセス過多、速度低下等を含みますがこれらに限られません。）、誤作動等が生じないことを明示的にも黙示的にも保証せず、これらによりお客様に生じた損害、不利益について、一切の責任を負わないものとします。
3．当社は、本サービスの利用、お客様と利用契約者との間で成立したピックアップ商品に係る売買契約により、又はこれらに関連して、お客様と利用契約者、他のお客様その他の第三者との間において生じた取引、連絡、紛争等については、当社の責に帰すべき場合を除き、一切の責任を負わないものとします。
4．利用契約者が注文を承諾したピックアップ商品をお客様に提供できない場合、提供したピックアップ商品について契約不適合があり、その他法令等違反その他ピックアップ商品の品質不良等があった場合は、利用契約者が自身の責任と費用負担においてお客様への対応を行うものとし、当社は責任を負いません。
5．お客様は、自身の責任において本サービスを利用し、本サービスにおいて行った一切の行為及び結果について一切の責任を負うものとし、当社は、当社の故意又は重過失に基づく場合を除き、お客様が本サービスを利用したことに起因してお客様に生じたあらゆる損害、不利益について一切の責任を負わないものとします。ただし、お客様の損害が当社の過失（重過失を除きます。）によって発生した場合、当社は、お客様に生じた通常かつ直接の損害について、損害が発生する原因となった売買契約に基づくピックアップ商品の代金額、又は売買契約が原因となっていない場合には、当該損害が発生した月にお客様から受領したピックアップ商品の代金額を上限として、これを賠償する責任を負うものとします。
6．アプリ上に表示されるピックアップ商品その他の利用契約者に係る情報は、利用契約者が提供した情報に基づくものであり、当社は、当該情報の正確性、完全性又は有用性等について保証いたしません。
7．ピックアップ商品に貼付された食品表示に係るラベル及び注文番号シールは、利用契約者が貼付したものであり、当社は、これらの内容の正確性、適法性等について保証いたしません。
8．お客様のご利用の端末の設定等により、ピックアップ商品の色合い等について、画面表示と実物が異なる場合があります。

第16条（利用契約上の地位の譲渡等）
1．お客様は、当社の書面による事前の承諾を得ることなく、本サービス利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
2．当社は、本規約に基づくお客様との間の権利義務の全部又は一部を、お客様の同意なく、当社のグループ会社に譲渡し、又は承継させることができるものとし、お客様はあらかじめ同意したものとします。

第17条（連絡方法）
1．お客様は、本サービスに関し当社に連絡をする場合、当社が別途指定しない限り、以下のお問い合わせ窓口によるものとします。
UP SMART ORDERお問い合わせ窓口
電話番号：045-271-8090
対応時間：08:30-17:30
休業日：土日および祝日
2．当社からのお客様に対する連絡は、アプリによる通知、お客様への電話その他の当社が適切と判断する方法により行います。

第18条（適用制限）
本規約の規定が本サービス利用契約に適用される法令等に反する場合、当該規定は、その限りにおいて本サービス利用契約には適用されないものとします。ただし、この場合でも、本規約の他の規定の効力には影響しないものとします。

第19条（準拠法と裁判管轄）
本規約及び本サービス利用契約の準拠法は日本法とします。本規約及び本サービス利用契約に起因し又は関連してお客様と当社との間に生じた一切の紛争については、横浜地方裁判所を第一審の専属的合意管轄裁判所とします。
以上

2021年7月13日制定
`}
          </pre>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Terms));
