import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

@inject('cartStore')
@observer
class CartClearDialog extends Component {
  handleClickCancel = () => {
    this.props.cartStore.setDialogOpen(false);
  };

  handleClickConfirm = () => {
    const { cartStore } = this.props;
    cartStore.clearCart();
    cartStore.setDialogOpen(false);
  };

  render() {
    const { cartStore, t } = this.props;
    return (
      <Dialog open={cartStore.isDialogOpen} onClose={this.handleClickCancel}>
        <DialogContent>
          <DialogContentText>
            <span style={{ color: '#000' }}>{t('ALERT_REMOVE_ALL')}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClickCancel} color="secondary">
            {t('BUTTON_NO')}
          </Button>
          <Button onClick={this.handleClickConfirm} color="secondary" autoFocus>
            {t('BUTTON_YES')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(CartClearDialog);
