import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Snackbar } from '@mui/material';

@inject('commonStore')
@observer
class CustomSnackbar extends Component {
  handleCloseSnackbar = () => {
    this.props.commonStore.setSnackbar(false);
  };

  render() {
    const { commonStore } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          open={commonStore.isOpenSnackbar}
          onClose={this.handleCloseSnackbar}
          autoHideDuration={2000}
          message={<span style={{ margin: '0 auto' }}>{commonStore.snackbarText}</span>}
          style={{ bottom: '200px' }}
        />
      </React.Fragment>
    );
  }
}

export default CustomSnackbar;
