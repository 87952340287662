import { observable } from 'mobx';

export default class StoreToppingModel {
  toppingCode;
  name;
  addCode;
  addPrice;
  addRdPrice;
  isSelect;
  isSoldOut;
  @observable isChecked;

  constructor(data) {
    Object.assign(this, data);
  }
}
