import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';

@inject('orderStore', 'userStore')
@observer
class OrderStep extends Component {
  render() {
    const { orderStore, userStore, t } = this.props;

    return (
      <Box className="step-container" bgcolor="primary.main">
        {userStore.userData.baydiamondDelivery === '0' && (
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs
              className={'step-item step-payment ' + (orderStore.order.currentStatusCode === 1 ? 'active' : '')}
            >
              <div className="step-title">{t('MESSAGE_PAYMENT_COMPLETE')}</div>
            </Grid>
            <Grid
              item
              xs
              className={'step-item step-order ' + (orderStore.order.currentStatusCode === 2 ? 'active' : '')}
            >
              <div className="step-title">{t('MESSAGE_ORDER_RECEIVE')}</div>
            </Grid>
            <Grid
              item
              xs
              className={'step-item step-ready ' + (orderStore.order.currentStatusCode === 3 ? 'active' : '')}
            >
              <div className="step-title">{t('MESSAGE_ORDER_READY')}</div>
            </Grid>
            <Grid
              item
              xs
              className={'step-item step-pickup ' + (orderStore.order.currentStatusCode === 4 ? 'active' : '')}
            >
              <div className="step-title">{t('MESSAGE_ORDER_PICKUP')}</div>
            </Grid>
          </Grid>
        )}

        {userStore.userData.baydiamondDelivery === '1' && (
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs
              className={
                'step-item step-payment-baydiamond ' + (orderStore.order.currentStatusCode === 1 ? 'active' : '')
              }
            >
              <div className="step-title">{t('MESSAGE_PAYMENT_COMPLETE')}</div>
            </Grid>
            <Grid
              item
              xs
              className={
                'step-item step-order-baydiamond ' + (orderStore.order.currentStatusCode === 2 ? 'active' : '')
              }
            >
              <div className="step-title">{t('MESSAGE_ORDER_RECEIVE')}</div>
            </Grid>
            <Grid
              item
              xs
              className={
                'step-item step-ready-baydiamond ' + (orderStore.order.currentStatusCode === 3 ? 'active' : '')
              }
            >
              <div className="step-title">{t('MESSAGE_ORDER_READY')}</div>
            </Grid>
            <Grid
              item
              xs
              className={
                'step-item step-pickup-baydiamond ' + (orderStore.order.currentStatusCode === 4 ? 'active' : '')
              }
            >
              <div className="step-title">{t('MESSAGE_ORDER_PICKUP')}</div>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

export default withRouter(withTranslation()(OrderStep));
