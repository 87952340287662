import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { AppBar, Button, Toolbar } from '@mui/material';
import ReceiveMethodEnum from '../../enums/ReceiveMethodEnum';
import api from '../../api';

@inject('commonStore', 'menuStore', 'cartStore', 'userStore', 'orderStore')
@observer
class Bottom extends Component {
  // 버튼 클릭
  confirm = async () => {
    const { t, history, match, commonStore, menuStore, cartStore, userStore, orderStore } = this.props;

    // 핸드폰 번호 검사
    var regExp = /^\d{10,11}$/g;
    if (userStore.userData.phoneNumber === '') {
      commonStore.setSnackbar(true, t('ALERT_ENTER_PHONE'));
      return;
    } else if (!regExp.test(userStore.userData.phoneNumber)) {
      commonStore.setSnackbar(true, t('ALERT_CHECK_PHONE'));
      return;
    }

    // 배달인 경우 주소 검사
    if (cartStore.cartData.receiveMethodCode === ReceiveMethodEnum.DELIVERY) {
      if (!userStore.userData.zipCode || !userStore.userData.sido) {
        commonStore.setSnackbar(true, t('ALERT_ENTER_ADDRESS'));
        return;
      }
    }

    // 약관동의 체크
    if (!orderStore.agree) {
      commonStore.setSnackbar(true, t('ALERT_AGREE_TERMS'));
      return;
    }

    // 회원 포인트 조회
    if (menuStore.storeData.isPointUseable) {
      const response = await api.getUserPointByPhoneNumber(
        match.params.companyId.toLowerCase(),
        match.params.storeCode,
        userStore.userData.phoneNumber,
      );

      if (response.data.message === 'NOT_FOUND') {
        userStore.userData.isMember = false;
        userStore.saveUserData();

        history.push(`/${commonStore.companyId}/${commonStore.storeCode}/join`);
        return;
      } else if (response.data.message === 'DUPLICATE') {
        userStore.userData.isMember = false;
        userStore.saveUserData();

        commonStore.setSnackbar(true, t('ERROR_USER_DUPLICATE'));
        return;
      } else if (response.data.message === 'SUCCESS') {
        userStore.userData.isMember = true;
        userStore.userData.isPointAdded = true;
        userStore.saveUserData();

        userStore.setUserPoint(response.data.user.Point);
        history.push({
          pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
          state: { isLiteOrder: orderStore.liteOrder ? true : false },
        });
        return;
      }
    }

    // 고객 입력 정보 브라우저에 저장
    userStore.saveUserData();

    // 결제 페이지로 이동
    history.push({
      pathname: `/${commonStore.companyId}/${commonStore.storeCode}/payment`,
      state: { isLiteOrder: orderStore.liteOrder ? true : false },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <AppBar position="fixed" color="primary" className="bottom">
          <Toolbar variant="dense" style={{ padding: '0' }}>
            <Button
              fullWidth
              color={'inherit'}
              variant={'text'}
              size={'large'}
              onClick={() => this.confirm()}
              className="bottom-button bottom-button-title"
            >
              {t('BUTTON_PAY')}
            </Button>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Bottom));
