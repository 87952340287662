import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { List, ListItem, ListItemIcon, ListItemText, Grid, Checkbox } from '@mui/material';
import i18n from 'i18next';

@inject('menuStore')
@observer
class SetMenu extends Component {
  handleClickSetMenu = (groupCode, itemCode) => {
    this.props.menuStore.setSetMenu(groupCode, itemCode);
  };

  render() {
    const { menuStore, setMenuGroups } = this.props;

    return (
      <List className="option-group-list">
        {setMenuGroups.map((setMenuGroup, setMenuGroupIndex) => (
          <ListItem disableGutters dense key={setMenuGroupIndex} className="option-group">
            <Grid container direction="column">
              <Grid item className="option-group-title">
                <span>
                  {setMenuGroup.name} <span style={{ color: 'red' }}>*</span>
                </span>
              </Grid>
              <Grid item className="option-list">
                <List>
                  {setMenuGroup.setMenus.map((setMenu, setMenuIndex) => (
                    <ListItem
                      disableGutters
                      dense
                      button
                      key={setMenuIndex}
                      onClick={() => this.handleClickSetMenu(setMenuGroup.groupCode, setMenu.itemCode)}
                    >
                      <ListItemIcon>
                        <Checkbox edge="start" checked={setMenu.isChecked} tabIndex={-1} disableRipple />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        //primary={<span style={{ fontSize: '12pt' }}>{topping.name}</span>}
                        primary={
                          <Grid container>
                            <Grid item xs>
                              <span style={{ fontSize: '12pt' }}>{setMenu.name}</span>
                            </Grid>
                            <Grid item className="option-price">
                              <span style={{ fontSize: '11pt' }}>
                                + {menuStore.currency}
                                {menuStore.calcSetMenuAddPrice(setMenu).toLocaleString()}
                                {menuStore.storeData.tax1_type === '2' ? (
                                  <span style={{ color: '#8C8C8C', fontWeight: 'normal' }}>
                                    &nbsp;{i18n.t('TITLE_TAX_TYPE')}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </Grid>
                          </Grid>
                        }
                        style={{ marginLeft: '-30px' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  }
}

export default SetMenu;
