import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Header from '../common/Header';
import QRCode from 'qrcode.react';
import { Grid } from '@mui/material';

@inject('cartStore')
@observer
class QrOrder extends Component {
  async componentDidMount() {
    const { cartStore } = this.props;
    cartStore.clearCart();
  }

  handleClickDownload = () => {
    var $svg = document.getElementById('qrCode');
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
    const $canvas = document.createElement('canvas');
    const { width, height } = $svg.getBoundingClientRect();
    $canvas.width = width;
    $canvas.height = height;
    const ctx = $canvas.getContext('2d');
    const img = new Image();
    img.onload = e => {
      ctx.drawImage(e.target, 0, 0);
      const $link = document.createElement('a');
      $link.download = 'image.png';
      $link.href = $canvas.toDataURL('image/png');
      $link.click();
    };

    img.src = URL.createObjectURL(blob);
  };

  render() {
    const { t, match } = this.props;
    return (
      <React.Fragment>
        {/* 헤더 */}
        <Header title={`QR ${t('TITLE_ORDER')}`} isGoHome={true} />

        <div className="contents" style={{ height: '100%' }}>
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{ height: '100%' }}
          >
            <Grid item>
              <QRCode
                id="qrCode"
                value={JSON.stringify({ BarcodeString: match.params.id, BarcodeType: 'SMARTORDER_QRORDER' })}
                renderAs="svg"
                includeMargin={true}
                width={300}
                height={300}
              />
            </Grid>

            <Grid item>{t('MESSAGE_QR_DESCRIPTION')}</Grid>
          </Grid>
        </div>

        {/* <AppBar position="fixed" color="primary" className="bottom">
          <Toolbar variant="dense" style={{ padding: '0' }}>
            <Button
              fullWidth
              color={'inherit'}
              variant={'text'}
              size={'large'}
              onClick={this.handleClickDownload}
              className="bottom-button"
            >
              <span className="bottom-button-title">{t('BUTTON_DOWNLOAD')}</span>
            </Button>
          </Toolbar>
        </AppBar> */}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(QrOrder));
