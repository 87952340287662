import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { AppBar, Box, Grid, Link } from '@mui/material';
import logo_only from '../../static/yokohama/logo/hamamo_only_logo.png';
import logo_only_baydiamond from '../../static/yokohama/logo/hamamo_only_logo_baydiamond.png';
import api from '../../api';

import HeaderYokohama from '../common/HeaderYokohama';
import FooterYokohama from '../common/FooterYokohama';

@inject('userStore')
class Waiting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkWaiting: '2',
    };
  }

  async componentDidMount() {
    const { history, match } = this.props;
    this.intervalId = setInterval(async () => {
      const status = await api.getSmartOrderState(match.params.companyId.toLowerCase(), match.params.storeCode);
      this.checkWaiting = status.data.CLOSE_TYPE;
      this.setState(() => {
        return {
          checkWaiting: status.data.CLOSE_TYPE,
        };
      });

      if (status.data.CLOSE_TYPE === '2' || status.data.CLOSE_TYPE === '3') {
      } else {
        history.push(`/`);
      }
    }, 30000);

    const status = await api.getSmartOrderState(match.params.companyId.toLowerCase(), match.params.storeCode);
    this.setState(() => {
      return {
        checkWaiting: status.data.CLOSE_TYPE,
      };
    });

    if (status.data.CLOSE_TYPE === '2' || status.data.CLOSE_TYPE === '3') {
    } else {
      clearInterval(this.intervalId);
      history.push(`/`);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { userStore } = this.props;
    return (
      <React.Fragment>
        <AppBar position="fixed" color="header" style={{ boxShadow: 'none' }}>
          <HeaderYokohama />
        </AppBar>

        <Box style={{ marginTop: '63px', backgroundColor: '#F2F2F2', fontFamily: 'Noto Sans JP' }}>
          <Grid container>
            <Grid style={{ padding: '40px', textAlign: 'center' }}>
              <div style={{ display: 'block' }}>
                <img
                  src={userStore.userData.baydiamondDelivery === '0' ? logo_only : logo_only_baydiamond}
                  style={{ width: '120px' }}
                  alt=""
                />
              </div>

              {this.state.checkWaiting === '2' && (
                <>
                  <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px' }}>モバイルオーダー準備中</div>

                  <div style={{ fontSize: '16px', marginTop: '30px' }}>
                    ただいま、モバイルオーダーは準備中です。
                    <br />
                    しばらくお待ちいただいてからアクセスしてください。
                  </div>
                </>
              )}
              {this.state.checkWaiting === '3' && (
                <>
                  <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px' }}>
                    モバイルオーダー受付停止中
                  </div>
                  <div style={{ fontSize: '16px', marginTop: '30px' }}>
                    ただいま、モバイルオーダーは受付しておりません。他の営業店舗をご利用ください。
                  </div>
                  <div style={{ fontSize: '16px', marginTop: '30px' }}>
                    <Link underline="none" href="https://www.yokohama-stadium.co.jp/events" target="about:blank">
                      スケジュールはこちら
                    </Link>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Box>

        <FooterYokohama />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Waiting));
